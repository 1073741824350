import { RoutingMethod, RoutingMethodTranslation } from '../../../../shared/utils/enums';

export { RoutingMethod };
export const routingMethodsNames: {
  text: string;
  value: RoutingMethod;
}[] = [
  {
    value: RoutingMethod.ECONOMY,
    text: RoutingMethodTranslation.ECONOMY
  },
  {
    value: RoutingMethod.FAST,
    text: RoutingMethodTranslation.FAST
  },
  {
    value: RoutingMethod.SHORT,
    text: RoutingMethodTranslation.SHORT
  }
];
