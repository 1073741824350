import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { RootState } from '.';
import { normalizeAddressString } from '../helpers/normalizeAddress';
import { AddressAutoCompleteEntry, Waypoint } from '../helpers/types';
import ApiService from '../services/ApiService';

export type State = {
  editedFreightId?: string;
  editedWaypoints?: Waypoint[];
  orderWaypoints: Waypoint[];
  originalWaypoints: Waypoint[];
};

const state: State = {
  editedFreightId: undefined,
  editedWaypoints: undefined,
  orderWaypoints: [],
  originalWaypoints: []
};

const actions: ActionTree<State, RootState> = {
  async getWaypoints({ commit }) {
    const freightWaypoints = await ApiService.getFreightWaypoints(state.editedFreightId);
    const orderWaypoints = await ApiService.getOriginalWaypoints(state.editedFreightId);
    commit('loadWaypoints', {
      freightWaypoints: freightWaypoints.data,
      orderWaypoints: orderWaypoints.data
    });
  },

  editFreightWaypoints({ commit }, freightId: string) {
    commit('setEditedFreightId', freightId);
  },

  closeFreightWaypointEditDialog({ commit }) {
    commit('resetState');
  },

  updateWaypoint({ commit }, { idx, input }: { idx: number; input: AddressAutoCompleteEntry }) {
    if (input) {
      commit('setWaypoint', { idx, input });
    }
  },

  async saveWaypoints({ commit }) {
    await ApiService.saveFreightWaypoints(state.editedFreightId, state.editedWaypoints);
    commit('resetState');
  },

  resetWaypoint({ commit, state }, orderId: string) {
    const orderWaypoint = state.orderWaypoints.find((waypoint) => waypoint.orderId === orderId);

    commit('setWaypointByOrderId', JSON.parse(JSON.stringify(orderWaypoint)));
  }
};

const mutations: MutationTree<State> = {
  loadWaypoints(
    state,
    {
      freightWaypoints,
      orderWaypoints
    }: { freightWaypoints: Waypoint[]; orderWaypoints: Waypoint[] }
  ) {
    state.originalWaypoints = JSON.parse(JSON.stringify(freightWaypoints));
    state.editedWaypoints = JSON.parse(JSON.stringify(freightWaypoints));
    state.orderWaypoints = JSON.parse(JSON.stringify(orderWaypoints));
  },

  setEditedFreightId(state, freightId: string) {
    state.editedFreightId = freightId;
  },

  setWaypoint(state, { idx, input }: { idx: number; input: AddressAutoCompleteEntry }) {
    if (state.editedWaypoints) {
      state.editedWaypoints[idx].address = input.address!;
      state.editedWaypoints[idx].point = input.geometry!;
      state.editedWaypoints[idx].city = input.city!;
      state.editedWaypoints[idx].county = input.county!;
      state.editedWaypoints[idx].zip = input.zip!;
    }
  },

  resetState() {
    state.editedFreightId = undefined;
    state.editedWaypoints = undefined;
    state.originalWaypoints = [];
    state.orderWaypoints = [];
  },

  setWaypointByOrderId(state, waypoint: Waypoint) {
    if (state.editedWaypoints) {
      const waypointIdx = state.editedWaypoints
        .map((editedWaypoint) => editedWaypoint.orderId)
        .indexOf(waypoint.orderId);

      state.editedWaypoints.splice(waypointIdx, 1, waypoint);
    }
  },

  updateField
};

const getters: GetterTree<State, RootState> = {
  getOriginalAddress:
    (state) =>
    (index: number): string => {
      return state.originalWaypoints[index].address;
    },

  getAddressSearchResult:
    () =>
    async (value: string): Promise<AddressAutoCompleteEntry[]> => {
      const result: AddressAutoCompleteEntry[] = [];
      if (!value) {
        return result;
      }
      const response = await ApiService.locationSearch(value);

      response.data.result.features.forEach((item: any) => {
        result.push({
          address: normalizeAddressString(value, item.properties),
          geometry: item.geometry,
          city: item.properties.addressparts.city,
          county: item.properties.addressparts.county,
          zip: item.properties.addressparts.zip
        });
      });

      return result;
    },

  getField
};

export const freightWaypoints: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
