export enum ExcelImportValidation {
  IS_DATE = 'isDate',
  IS_PRESENT_OR_FUTURE = 'isPresentOrFuture',
  IS_POSITIVE = 'isPositive',
  IS_POSITIVE_INTEGER = 'isPositiveInteger',
  IS_WORKDAY = 'isWorkday',
  UNIQUE = 'unique',
  HAS_ONLY_ONE_VALUE = 'hasOnlyOneValue',
  IS_VALID_VEHICLE = 'isValidVehicle'
}
export const defaultTruckCategoryTypes = {
  crane: 'crane',
  notCrane: 'notCrane'
};

export enum ModuleModes {
  ALL = 'all',
  NONE = 'none',
  CONFIG = 'config'
}

export enum ProcessingStatus {
  IDLE = 'idle',
  NEW = 'new',
  NO_OVERLAPPING_DELIVERY_DATES = 'noOverlappingDeliveryDates',
  PROCESSING = 'processing',
  DONE = 'done',
  DOES_NOT_FIT = 'doesNotFit',
  ERROR = 'error',
  ORDER_COVERAGE_ERROR = 'orderCoverageError',
  ROUTE_NOT_FOUND = 'routeNotFound',
  TOO_MANY_WAYPOINTS = 'tooManyWaypoints',
  NO_VEHICLE = 'noVehicle'
}

export enum LogisticsPlannerType {
  LOGISTICS = 'logistics',
  LOOM = 'loom'
}

export enum ProductUploadProcessingStatus {
  PROCESSING_STARTED = 'processing started',
  WORK_IN_PROGRESS = 'work in progress',
  READY = 'ready',
  CANCELLED = 'cancelled'
}

export enum LogisticsEntity {
  ORDER = 'order',
  FREIGHT = 'freight',
  PICKUP = 'pickup'
}

export enum SendEmailMode {
  EMAIL_API = 'emailApi',
  SMTP = 'smtp'
}

export enum RoutingMethod {
  ECONOMY = 'ECONOMY',
  FAST = 'FAST',
  SHORT = 'SHORT'
}
export enum RoutingMethodTranslation {
  ECONOMY = 'Gazdaságos útvonal',
  FAST = 'Leggyorsabb útvonal',
  SHORT = 'Legrövidebb útvonal'
}

export default {
  defaultTruckCategoryTypes: defaultTruckCategoryTypes,
  ModuleModes: ModuleModes,
  ProcessingStatus: ProcessingStatus,
  LogisticsPlannerType: LogisticsPlannerType,
  ProductUploadProcessingStatus: ProductUploadProcessingStatus,

  orderStatus: {
    new: 'new',
    invalid: 'invalid',
    waitingForProcessing: 'waitingForProcessing',
    processing: 'processing',
    processed: 'processed',
    shipped: 'shipped',
    partiallyShipped: 'partiallyShipped',
    partial: 'partial',
    closed: 'closed' //orderClosureModule
  },
  freightStatus: {
    // the order of the statuses is important!
    new: 'new',
    scheduled: 'scheduled',
    published: 'published', //freightOfferModule
    accepted: 'accepted', //freightOfferModule (freight has a transporter)
    reserved: 'reserved', //timeSlotModule (freight has a timeSlot)
    finalized: 'finalized', //finalizeFreightModule
    arrived: 'arrived', //timeSlotModule
    delivery: 'delivery', // transporterModule
    shipped: 'shipped',
    billed: 'billed', //billingModule
    finished: 'finished', //billingModule
    cancelled: 'cancelled' //orderClosureModule
  },
  pickupStatus: {
    // the order of the statuses is important!
    new: 'new',
    scheduled: 'scheduled',
    reserved: 'reserved', //timeSlotModule (pickup has a timeSlot)
    arrived: 'arrived', //timeSlotModule
    shipped: 'shipped',
    cancelled: 'cancelled' //orderClosureModule
  },
  transporterStatus: {
    active: 'active',
    inactive: 'inactive'
  },
  offerStatus: {
    sent: 'sent',
    received: 'received',
    rejectedByTransporter: 'rejectedByTransporter',
    accepted: 'accepted',
    rejected: 'rejected'
  },
  truckCategoryTypes: defaultTruckCategoryTypes,

  truckEuroCategoryTypes: [
    { value: 'EURO0', text: 'EURO0' },
    { value: 'EURO1', text: 'EURO1' },
    { value: 'EURO2', text: 'EURO2' },
    { value: 'EURO3', text: 'EURO3' },
    { value: 'EURO4', text: 'EURO4' },
    { value: 'EURO5', text: 'EURO5' },
    { value: 'EURO6', text: 'EURO6' },
    { value: 'LOW_EMISSION', text: 'Alacsony kibocsátás' },
    { value: 'NO_EMISSION', text: 'Kibocsátásmentes' }
  ],
  defaultTruckTypes: ['D22S', 'D22L', 'T24S', 'T24L'],

  freightTransportStatus: {
    unknown: 'unknown',
    stockShortage: 'stockShortage',
    damagedGoods: 'damagedGoods',
    damageDuringLoading: 'damageDuringLoading',
    damageDuringTransport: 'damageDuringTransport',
    handoverFailed: 'handoverFailed',
    // technical status which should be used only when
    // split cargo is triggered by product update
    productUpdate: 'productUpdate',
    truckFull: 'truckFull',
    replan: 'replan'
  },
  pickupTransportStatus: {
    unknown: 'unknown',
    stockShortage: 'stockShortage',
    damagedGoods: 'damagedGoods',
    damageDuringLoading: 'damageDuringLoading',
    pickupTiming: 'pickupTiming',
    replan: 'replan'
  },
  cargoSplitType: {
    byPallet: 'byPallet',
    byQuantity: 'byQuantity'
  },
  allTransportStatus: {
    unknown: 'unknown',
    stockShortage: 'stockShortage',
    damagedGoods: 'damagedGoods',
    damageDuringLoading: 'damageDuringLoading',
    damageDuringTransport: 'damageDuringTransport',
    handoverFailed: 'handoverFailed',
    pickupTiming: 'pickupTiming',
    truckFull: 'truckFull',
    replan: 'replan'
  },

  vehicleStatus: {
    active: 'active',
    inactive: 'inactive'
  },

  errorCodes: {
    cannotBeGeocoded: 'CANNOT_BE_GEOCODED',
    productNotFound: 'PRODUCT_NOT_FOUND',
    processingCancelled: 'PROCESSING_CANCELLED'
  },

  inputFieldTypes: ['boolean'],

  webhookNotificationTypes: {
    orderItemsShipped: 'orderItemsShipped'
  }
};
