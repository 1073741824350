import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';
import ApiService from '../services/ApiService';

export interface Vehicle {
  stackingLevels: number;
  id: string;
  vehicleId: string;
  name: string;
  kgCapacity: number;
  palletCapacity: number;
  weight: number;
  axleCount: number;
  axleWeight: number;
  deliveryLimit: number;
  cargoSpaceLeft: number;
  cargoSpaceRight: number;
  truckCategoryType: string;
  active: boolean;
  isDefault: boolean;
}

type VehicleState = {
  vehicles: Vehicle[];
  inactiveVehicles: Vehicle[];
};

const state: VehicleState = {
  vehicles: [],
  inactiveVehicles: []
};

const getters: GetterTree<VehicleState, RootState> = {
  vehicles: (state) => state.vehicles,
  vehicleIds: (state) => state.vehicles.map((vehicle) => vehicle.vehicleId),
  getActiveVehicleIds: (state) => {
    return state.vehicles
      .filter((vehicle) => vehicle.active === true)
      .map((vehicle: Vehicle) => vehicle.vehicleId);
  },
  getVehicle:
    (state) =>
    (vehicleId: string, inactiveVehiclesInclude: boolean = false) => {
      if (inactiveVehiclesInclude === false) {
        return state.vehicles.find((vehicle) => vehicle.vehicleId === vehicleId);
      } else {
        const allVehicles = state.vehicles.concat(state.inactiveVehicles);
        return allVehicles.find((vehicle) => vehicle.vehicleId === vehicleId);
      }
    },
  getVehiclesByCapacity:
    (state) =>
    ({ palletCapacity, kgCapacity }: { palletCapacity: number; kgCapacity: number }): string[] => {
      return state.vehicles
        .filter(
          (vehicle) => vehicle.palletCapacity >= palletCapacity && vehicle.kgCapacity >= kgCapacity
        )
        .sort((vehicle, otherVehicle) => vehicle.kgCapacity - otherVehicle.kgCapacity)
        .map((vehicle) => vehicle.vehicleId);
    }
};

const mutations: MutationTree<VehicleState> = {
  refresh(state, payload: Vehicle[]) {
    state.vehicles = payload;
  },
  refreshInactiveVehicles(state, payload: Vehicle[]) {
    state.inactiveVehicles = payload;
  }
};

const actions: ActionTree<VehicleState, RootState> = {
  async get({ commit }) {
    const result: Vehicle[] = (await ApiService.getVehicles()).data;
    commit('refresh', result);
  },

  async getInactiveVehicles({ commit }) {
    const result: Vehicle[] = (await ApiService.getInactiveVehicles()).data;
    commit('refreshInactiveVehicles', result);
  },

  async save({ dispatch }, vehicle) {
    await ApiService.saveVehicle(vehicle);
    dispatch('get');
  }
};

export const vehicles = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
