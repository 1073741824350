import { store } from '.';
import { router } from '../router';
import AuthService from '../services/AuthService';
import { defineRulesFor } from '../services/ability';

const user = window.$cookies.get('user');
const state = user
  ? { authDisabled: false, status: { loggedIn: true, registering: false, updating: false }, user }
  : { authDisabled: false, status: { loggedIn: false }, user: null };

const actions = {
  login({ dispatch, commit }, { username, password, vm }) {
    commit('loginRequest', { username });

    AuthService.login(username, password).then(
      async (user) => {
        await store.dispatch('module/getActiveModules');

        commit('loginSuccess', user);

        if (store.getters['account/isTransporter']) {
          router.push('/freights');
        } else if (store.getters['account/isEntranceSupervisor']) {
          router.push('/timeslots');
        } else {
          router.push('/');
        }
        vm.$ability.update(defineRulesFor(store.getters['account/role']));
      },
      (error) => {
        commit('loginFailure', error);
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  logout({ commit }) {
    AuthService.logout();
    commit('logout');

    // with reloading, we can empty the whole vuex state
    // 401 results call this logout method too
    window.location.reload();
  },
  setCanEditByConfigException({ commit }, canEditByException) {
    commit('setCanEditByConfigException', canEditByException);
  }
};

const getters = {
  isUserLoggedIn: (state) => {
    return state.status && state.status.loggedIn;
  },
  isSuperAdmin: (state) => {
    return (
      state.status && state.status.loggedIn && state.user && state.user._doc.role === 'superadmin'
    );
  },
  isAdmin: (state) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      (state.user._doc.role === 'admin' || state.user._doc.role === 'superadmin')
    );
  },
  isLogisticsManager: (state, getters) =>
    getters.isUserLoggedIn && state.user && state.user._doc.role === 'logistics manager',
  isTransporter: (state, getters, rootState) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      (state.user._doc.role === rootState.app.constants.ROLES.TRANSPORTER_MANAGER ||
        state.user._doc.role === rootState.app.constants.ROLES.TRANSPORTER_VIEWER)
    );
  },
  isProductionManager: (state, getters, rootState) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      state.user._doc.role === rootState.app.constants.ROLES.PRODUCTION_MANAGER
    );
  },

  isEntranceSupervisor: (state) => {
    return (
      state.status &&
      state.status.loggedIn &&
      state.user &&
      state.user._doc.role === 'entrance supervisor'
    );
  },

  authDisabled: (state) => state.authDisabled,

  canEdit: (state, getters) =>
    getters.isAdmin || getters.isLogisticsManager || state.authDisabled || state.canEditByException,

  displayName: (state) => {
    if (!state.status.loggedIn) {
      return undefined;
    }
    const user = state.user?._doc;
    return user.username + ' (' + user.role + ')';
  },

  role: (state) => {
    return state.user?._doc.role;
  },

  user: (state) => state.user?._doc,

  userId: (state) => state.user?._doc._id
};

const mutations = {
  disableAuth(state) {
    state.authDisabled = true;
  },
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true, registering: false, updating: false };
    state.user = user;
  },
  loginFailure(state) {
    state.status = { isLoggedIn: false };
    state.user = null;
  },
  logout(state) {
    state.status = { isLoggedIn: false };
    state.user = null;
  },
  setCanEditByConfigException(state, canEditByException) {
    state.canEditByException = canEditByException;
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
