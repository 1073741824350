import * as XLSX from 'xlsx';
import ApiService from '../services/ApiService';
import { store } from '../store';
import { parseNumber } from './number';
import { roundByMagicLimit } from './rounding';
import { ExcelConfigHeader } from './types';
import {
  ImportedOrder,
  ImportedOrderErrorMessage,
  ImportedOrderErrorTypes
} from '../../../shared/types/importedOrder';
import { PalletType } from '../../../shared/types/palletType';
import { isPresentOrFutureDate, isValidDate } from '../../../shared/utils/date';
import { ExcelImportValidation } from '../../../shared/utils/enums';
import { checkCalendar } from './rules';

function getMappedRows(
  data: Array<Record<string, any>>,
  headers: ExcelConfigHeader[]
): Array<Record<string, any>> {
  const headerKeys = headers.map((header) => header.excelHeader);
  const headerValues = headers.map((header) => header.logisticsField);

  const result = data
    .map((row: Record<string, any>) => {
      const resultValue: Record<string, any> = {};
      for (let headerKeyIdx = 0; headerKeyIdx < headerKeys.length; headerKeyIdx++) {
        const headerKey = headerKeys[headerKeyIdx];
        const headerVal = headerValues[headerKeyIdx];

        resultValue[headerVal] = row[headerKey];
      }

      return resultValue;
    })
    .filter((row: Record<string, any>) => JSON.stringify(row) !== JSON.stringify({}));

  return result;
}

export async function createUploadedOrdersFromMappedRows(
  mappedRows: Array<Record<string, any>>,
  requiredHeaders: ExcelConfigHeader[],
  optionalHeaders: ExcelConfigHeader[]
): Promise<Array<ImportedOrder>> {
  const orders: Array<ImportedOrder> = [];

  const headersToValidate = [
    ...requiredHeaders.filter((header) => header.validations),
    ...optionalHeaders.filter((header) => header.validations)
  ];
  const uniqueColumns = headersToValidate.filter((header) =>
    header.validations?.includes(ExcelImportValidation.UNIQUE)
  );

  const dateLogisticsFieldsInExcel = headersToValidate
    .filter((header) => header.validations?.includes(ExcelImportValidation.IS_DATE))
    .map((dateHeader) => dateHeader.logisticsField);

  // currently Márka gives one date at a time, so we only accept uploads with one date
  const hasOnlyOneValueColumns = headersToValidate.filter((header) =>
    header.validations?.includes(ExcelImportValidation.HAS_ONLY_ONE_VALUE)
  );

  const addresses: Array<Record<string, number | string>> = [];
  let temporaryCustomerId = 0;
  for (const mappedRow of mappedRows) {
    const errorMessages: Array<ImportedOrderErrorMessage> = [];
    for (const { excelHeader: key, logisticsField: value } of requiredHeaders) {
      if (!mappedRow[value]) {
        errorMessages.push({
          type: ImportedOrderErrorTypes.MISSING_REQUIRED_FIELD,
          message: `A "${key}" mező kitöltése kötelező!`,
          field: value
        });
      }
    }

    for (const header of headersToValidate) {
      if (header.validations && header.validations.length > 0) {
        const data = mappedRow[header.logisticsField];
        if (data) {
          const errors = await validateRow({ data, header });
          errorMessages.push(...errors);
        }
      }
    }

    if (uniqueColumns.length > 0) {
      for (const header of uniqueColumns) {
        const currentValue = mappedRow[header.logisticsField];
        const otherValues = mappedRows
          .map((row) => row[header.logisticsField])
          .filter((value) => value === currentValue);
        if (otherValues.length > 1) {
          errorMessages.push({
            type: ImportedOrderErrorTypes.DUPLICATED_VALUE,
            message: `A "${header.excelHeader}" mező értékeinek egyedinek kell lennie!`,
            field: header.logisticsField
          });
        }
        const existsInDatabase = (await ApiService.checkOrderId(currentValue)).data.exists;
        if (existsInDatabase) {
          errorMessages.push({
            type: ImportedOrderErrorTypes.ALREADY_EXISTS_IN_DATABASE,
            message:
              `A(z) "${header.excelHeader}" mező ` +
              `ezzel az értékkel már létezik az alkalmazásban!`,
            field: header.logisticsField
          });
        }
      }
    }

    if (hasOnlyOneValueColumns.length > 0) {
      for (const header of hasOnlyOneValueColumns) {
        const valuesInColumn = new Set(
          mappedRows.map((row) => {
            const currentField = header.logisticsField;
            if (
              dateLogisticsFieldsInExcel.includes(currentField) &&
              typeof row[currentField] !== 'string'
            ) {
              return row[currentField].toISOString();
            }
            return row[currentField];
          })
        );

        if (valuesInColumn.size > 1) {
          errorMessages.push({
            type: ImportedOrderErrorTypes.MORE_THAN_ONE_VALUE,
            message: `A "${header.excelHeader}" mezőben minden értéknek egyezőnek kell lennie!`,
            field: header.logisticsField
          });
        }
      }
    }

    const originalAddress = mappedRow.addressPostalCode
      ? `${mappedRow.addressPostalCode} ${mappedRow.originalAddress}`
      : mappedRow.originalAddress;

    const palletTypes: Record<string, PalletType> = store.getters['app/palletTypes'];
    let palletType: string = '';
    if (mappedRow.palletType && Object.keys(palletTypes).includes(mappedRow.palletType)) {
      palletType = mappedRow.palletType;
    } else if (!errorMessages.find((msg) => msg.field === `palletType`)) {
      let fieldName: string | undefined = '';
      const requiredHeaders: ExcelConfigHeader[] | undefined =
        store.state.app.config?.xlsxHeaders?.ordersImport?.requiredHeaders;

      if (requiredHeaders) {
        fieldName = requiredHeaders.find(
          (header) => header.logisticsField === 'palletType'
        )?.excelHeader;
      }
      // show invalid value error only if there's a value
      if (mappedRow.palletType) {
        errorMessages.push({
          type: ImportedOrderErrorTypes.INVALID_VALUE,
          message: `A(z) "${fieldName}" mező helytelen adatot tartalmaz!`,
          field: `palletType`
        });
      }
    }

    const mappedSumWeight = mappedRow.sumWeight ? parseNumber(mappedRow.sumWeight) : undefined;
    const sumWeight =
      mappedSumWeight && mappedSumWeight !== 0 ? Number(mappedSumWeight) : undefined;
    const palletRatio = palletTypes[mappedRow.palletType]?.ratio;
    const palletsCount = roundByMagicLimit(
      mappedRow.palletsCount,
      store.state.app.config?.excelImportPartialPalletRoundingLimit
    );
    let vehicle;

    // If vehicle is not valid then do not check for errors about the vehicle
    if (
      !errorMessages.find(
        (message) => message.type === ImportedOrderErrorTypes.IS_NOT_VALID_VEHICLE
      )
    ) {
      // Add these errors only if weight and pallets count are set
      if (sumWeight && palletsCount && palletRatio !== undefined) {
        const vehicles: Array<string> = store.getters['vehicles/getVehiclesByCapacity']({
          palletCapacity: palletsCount * +palletRatio,
          kgCapacity: sumWeight
        });

        const vehicleId: string = mappedRow.vehicle ?? store.state.app.config.mainVehicle ?? '';
        vehicle = vehicles.find((_vehicleId) => _vehicleId === vehicleId);

        if (vehicles.length === 0) {
          errorMessages.push({
            type: ImportedOrderErrorTypes.CAPACITY,
            message: 'A megrendelés nem fér fel egy járműre sem!',
            field: `vehicle`
          });
        } else if (!vehicle) {
          errorMessages.push({
            type: ImportedOrderErrorTypes.CAPACITY,
            message:
              `A megrendelés nem fér fel a ${vehicleId} járműre! ` +
              `Lehetséges járművek: ${vehicles.join(', ')}`,
            field: `vehicle`
          });
        }
      }
    }

    const order: ImportedOrder = {
      temporaryCustomerId,
      customerNumber: mappedRow.customerNumber,
      idNumber: mappedRow.idNumber,
      customerName: mappedRow.customerName,
      deliveryDate: mappedRow.deliveryDate ? new Date(mappedRow.deliveryDate) : undefined,
      originalAddressPostalCode: mappedRow.addressPostalCode,
      originalAddress: mappedRow.originalAddress,
      geocodedAddress: '',
      palletsCount,
      sumWeight,
      vehicle: mappedRow.vehicle ?? store.state.app.config.mainVehicle,
      comment: mappedRow.comment,
      palletType,
      errors: errorMessages
    };

    orders.push(order);
    addresses.push({ temporaryCustomerId, address: originalAddress });
    temporaryCustomerId++;
  }

  const { data } = await ApiService.geocodeAPs(addresses);
  data.geocodedAPs.forEach((geocodedAPData: any) => {
    const orderIdx = orders.findIndex(
      (order) => order.temporaryCustomerId === geocodedAPData.temporaryCustomerId
    );

    orders[orderIdx].geocodedAddress =
      geocodedAPData.geocodedAP ?? orders[orderIdx].originalAddress;
    if (!geocodedAPData.geocodedAP) {
      // add this error message only if address data exists in the Excel
      if (orders[orderIdx].originalAddress) {
        orders[orderIdx].errors.push({
          type: ImportedOrderErrorTypes.CANNOT_BE_GEOCODED,
          message: `A címpont nem geokódolható!`,
          field: `geocodedAddress`
        });
      }
    }
  });
  return orders;
}

export async function importOrdersFromXlsx(file: File): Promise<Array<ImportedOrder>> {
  const fileBuffer = await file.arrayBuffer();
  const xlsxData = XLSX.read(fileBuffer);

  let orders: Array<ImportedOrder> = [];
  if (xlsxData && xlsxData.Workbook) {
    const ordersImportHeaders = store.state.app.config?.xlsxHeaders?.ordersImport;
    const requiredHeaders: ExcelConfigHeader[] = ordersImportHeaders?.requiredHeaders || [];
    const optionalHeaders: ExcelConfigHeader[] = ordersImportHeaders?.optionalHeaders || [];
    const deliveryDateHeader = requiredHeaders.find(
      (header) => header.logisticsField === 'deliveryDate'
    )?.excelHeader;

    if (!deliveryDateHeader) {
      throw 'Nincs megadva szállítási dátum mező a konfigurációban!';
    }

    const workSheet = xlsxData.Sheets[xlsxData.SheetNames[0]];
    // number data is much better in raw data
    const raw_data: Array<Record<string, any>> = XLSX.utils.sheet_to_json(workSheet, { raw: true });
    // date data is much better in formatted data
    const formattedData: Array<Record<string, any>> = XLSX.utils.sheet_to_json(workSheet, {
      raw: false
    });
    // we mainly use raw data, but we need to replace deliveryDate with formatted data
    const data = raw_data.map((row, idx) => ({
      ...row,
      [deliveryDateHeader]: formattedData[idx][deliveryDateHeader]
    }));

    const mappedRows = getMappedRows(data, [...requiredHeaders, ...optionalHeaders]);
    orders = await createUploadedOrdersFromMappedRows(mappedRows, requiredHeaders, optionalHeaders);
  }

  return orders;
}

/**
 * Checks if given data value has any error compared to defined validations in header
 */
async function validateRow({
  data,
  header
}: {
  data: any;
  header: ExcelConfigHeader;
}): Promise<ImportedOrderErrorMessage[]> {
  const errors: ImportedOrderErrorMessage[] = [];

  if (!header.validations) {
    return errors;
  }

  for (const validation of header.validations) {
    switch (validation) {
      case ExcelImportValidation.IS_DATE:
        if (!isValidDate(data)) {
          errors.push({
            type: ImportedOrderErrorTypes.INVALID_DATE,
            message: `A(z) "${header.excelHeader}" mezőben érvénytelen dátum érték szerepel!`,
            field: header.logisticsField
          });
        }
        break;
      case ExcelImportValidation.IS_PRESENT_OR_FUTURE:
        if (!isPresentOrFutureDate(data)) {
          errors.push({
            type: ImportedOrderErrorTypes.DATE_IN_PAST,
            message: `A(z) "${header.excelHeader}" múltbeli időpontra esik!`,
            field: header.logisticsField
          });
        }
        break;
      case ExcelImportValidation.IS_POSITIVE: {
        const asNumber = parseNumber(data);
        if (isNaN(asNumber) || asNumber <= 0) {
          errors.push({
            type: ImportedOrderErrorTypes.IS_NOT_POSITIVE,
            message: `A(z) "${header.excelHeader}" nem pozitív szám!`,
            field: header.logisticsField
          });
        }
        break;
      }
      case ExcelImportValidation.IS_POSITIVE_INTEGER: {
        const asNumber = parseNumber(data);
        if (isNaN(asNumber) || !Number.isInteger(asNumber) || asNumber <= 0) {
          errors.push({
            type: ImportedOrderErrorTypes.IS_NOT_POSITIVE_INTEGER,
            message: `A(z) "${header.excelHeader}" nem pozitív egész szám!`,
            field: header.logisticsField
          });
        }
        break;
      }
      case ExcelImportValidation.IS_VALID_VEHICLE: {
        const activeVehicleIds: Array<string> = store.getters['vehicles/getActiveVehicleIds'];
        if (!activeVehicleIds.includes(data)) {
          errors.push({
            type: ImportedOrderErrorTypes.IS_NOT_VALID_VEHICLE,
            message: `A "Jármű" mező értéke nem szerepel az aktív járművek között!`,
            field: header.logisticsField
          });
        }
        break;
      }
      case ExcelImportValidation.IS_WORKDAY: {
        if (checkCalendar(data) !== true) {
          errors.push({
            type: ImportedOrderErrorTypes.IS_NOT_WORKDAY,
            message: `A(z) "${header.excelHeader}" nem munkanap!`,
            field: header.logisticsField
          });
        }
        break;
      }
    }
  }

  return errors;
}
