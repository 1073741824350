<template>
  <v-menu
    v-model="showDatePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <gli-text-field
        ref="input"
        :disabled="disabled"
        :label="label"
        append-icon="date_range"
        :hint="'Formátum: ' + sample"
        v-model="valueText"
        @blur="validateAndRefresh"
        @keyup="keyup"
        :rules="[
          isValid || 'A megadott formátum nem megfelelő! Formátum: ' + sample,
          allowedDates(value) || 'A megadott dátum nem munkanap!',
          ...(rules || [])
        ]"
        :clearable="clearable"
      >
        <template v-slot:append v-if="!disabled">
          <div v-if="steppers" class="d-flex flex-column">
            <v-icon style="height: 12px" @click="up"> mdi-chevron-up </v-icon>
            <v-icon style="height: 12px" @click="down"> mdi-chevron-down </v-icon>
          </div>

          <v-icon v-on="on">date_range</v-icon>
        </template>
      </gli-text-field>
    </template>
    <v-date-picker
      :max="formatDate(maxDate)"
      :min="formatDate(minDate)"
      :allowed-dates="allowedDates"
      no-title
      close
      first-day-of-week="1"
      v-model="valueDatePicker"
      @input="datePickerChange"
      :picker-date.sync="pickerDate"
    >
      <template v-if="showWeek" v-slot:default>
        <v-col cols="12">
          <v-row>
            <v-divider></v-divider>
          </v-row>
          <v-row>
            <span class="mt-4">Hét kiválasztása</span>
          </v-row>
          <v-row>
            <v-chip-group active-class="primary--text" v-model="selectedWeek">
              <v-chip v-for="week in weeks" :key="week" :value="week">
                {{ week }}
              </v-chip>
            </v-chip-group>
          </v-row>
        </v-col>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<style scoped>
.v-chip.v-size--default {
  font-size: 12px;
}
</style>

<script>
import { isWorkday } from '../../../../shared/utils/workday';
import moment, { ISO_8601 } from 'moment-mini';
import * as dateFormats from '../../../../shared/utils/date';
import { mapActions, mapGetters } from 'vuex';

const DATEPICKER_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'DateField',
  props: {
    value: String,
    label: String,
    maxDate: String,
    minDate: String,
    showWeek: Boolean,
    rules: Array,
    disabled: Boolean,
    onlyWorkdays: Boolean,
    skipRefreshOnMount: Boolean,
    steppers: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      dateFormats,
      showDatePicker: false,
      isValid: true,
      valueISO: this.value,
      valueText: null,
      valueDatePicker: '',
      pickerDate: undefined,
      weeks: [],
      selectedWeek: undefined
    };
  },

  mounted() {
    if (this.valueISO) {
      this.refreshPickerValue();
      this.refreshDisplayText();
    }
    if (!this.skipRefreshOnMount) {
      this.refreshCalendar();
    }
  },

  computed: {
    ...mapGetters('calendar', ['calendar']),
    momentFromText() {
      return moment(this.valueText, dateFormats.formatDate);
    },
    momentFromDatePicker() {
      return moment(this.valueDatePicker, DATEPICKER_FORMAT);
    },
    momentFromISO() {
      return moment(this.valueISO, ISO_8601);
    },
    sample() {
      return moment('2020-08-31', 'YYYY-MM-DD').format(dateFormats.formatDate);
    }
  },
  watch: {
    valueDatePicker() {
      this.refreshPickerDate();
    },
    valueISO() {
      this.$emit('input', this.valueISO);
    },

    valueText() {
      if (this.momentFromText.isValid()) {
        this.valueISO = this.momentFromText.toISOString();
        this.refreshPickerValue();
      } else {
        this.valueISO = null;
      }
    },

    pickerDate(value) {
      if (!value) {
        return;
      }
      const to = moment(value + '-' + moment(value).daysInMonth()).toDate();
      let dateIter = moment(value).startOf('isoWeek').toDate();
      this.weeks = [];
      while (dateIter <= to) {
        this.weeks.push(this.getWeek(dateIter));
        dateIter = moment(dateIter).add(7, 'days').toDate();
      }
    },

    selectedWeek(value) {
      if (value === undefined) {
        this.$emit('weekSelected', undefined);
        return;
      }
      let year = Number(this.pickerDate.toString().split('-')[0]);
      const month = Number(this.pickerDate.toString().split('-')[1]);
      if (value > 50 && month === 1) {
        year--;
      } else if (value < 3 && month === 12) {
        year++;
      }
      this.valueISO = this.getDateFromWeek(value, year);
      this.refreshDisplayText();
      this.refreshPickerValue();
      this.$emit('weekSelected', this.valueISO);
    }
  },

  methods: {
    ...mapActions('calendar', { refreshCalendar: 'refresh' }),

    keyup() {
      this.isValid = true;

      if (
        !this.momentFromText.isValid() ||
        !this.valueText.match(dateFormats.datePartialMatchingRegExp)
      ) {
        this.isValid = false;
      }

      // if empty or contains a full date, then validate and emit
      if (!this.valueText || this.valueText.replace(/\D+/g, '').length === 8) {
        this.validateAndRefresh();
      }
    },

    getWeek(date) {
      // https://weeknumber.net/how-to/javascript
      let dateCopy = new Date(date.getTime());
      dateCopy.setHours(0, 0, 0, 0);
      dateCopy.setDate(dateCopy.getDate() + 3 - ((dateCopy.getDay() + 6) % 7));
      let week1 = new Date(dateCopy.getFullYear(), 0, 4);
      return (
        1 +
        Math.round(
          ((dateCopy.getTime() - week1.getTime()) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
        )
      );
    },
    getDateFromWeek(week, year) {
      // https://stackoverflow.com/questions/16590500/javascript-calculate-date-from-week-number
      let date = new Date(year, 0, 1 + (week - 1) * 7);
      const dow = date.getDay();
      if (dow <= 4) {
        date.setDate(date.getDate() - date.getDay() + 1);
      } else {
        date.setDate(date.getDate() + 8 - date.getDay());
      }
      return moment(date).toISOString();
    },
    datePickerChange() {
      this.valueISO = moment(this.valueDatePicker, DATEPICKER_FORMAT).toISOString();
      this.refreshDisplayText();
      this.showDatePicker = false;
    },
    validateAndRefresh() {
      if (!this.valueText) {
        this.valueISO = null;
        this.valueDatePicker = '';
        this.isValid = true;
        this.selectedWeek = undefined;
        return;
      }

      if (this.momentFromText.isValid()) {
        this.valueISO = this.momentFromText.toISOString();
        this.refreshDisplayText();
        this.refreshPickerValue();
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },
    refreshDisplayText() {
      if (this.valueISO === null || this.valueISO === undefined) {
        this.valueText = '';
        return;
      }

      this.valueText = this.momentFromISO.format(dateFormats.formatDate);
    },
    refreshPickerValue() {
      if (this.valueISO === null || this.valueISO === undefined) {
        this.valueDatePicker = '';
        return;
      }

      this.valueDatePicker = this.momentFromISO.format(DATEPICKER_FORMAT);
    },
    refreshPickerDate() {
      if (!this.valueDatePicker) {
        return;
      }
      this.pickerDate = this.valueDatePicker.split('-').slice(0, 2).join('-');
    },
    refresh(value) {
      this.valueISO = value;
      this.refreshDisplayText();
      this.refreshPickerValue();
    },

    formatDate(date) {
      return date ? moment(new Date(date)).format(DATEPICKER_FORMAT) : '';
    },

    allowedDates(value) {
      if (value) {
        if (!this.onlyWorkdays) {
          return true;
        }
        return isWorkday(value, this.calendar);
      }
      return true;
    },

    up() {
      this.valueISO = moment(this.valueISO).add(1, 'days').toISOString();
      this.refreshDisplayText();
    },

    down() {
      this.valueISO = moment(this.valueISO).add(-1, 'days').toISOString();
      this.refreshDisplayText();
    }
  }
};
</script>
