import { authHeader } from '../helpers';
import Api from '../services/Api';

export default {
  login(username, password) {
    return Api()
      .post('users/authenticate', { username, password })
      .catch(handleError)
      .then(handleResponse)
      .then((user) => {
        // login successful if there's a jwt token in the response
        if (user.token) {
          // store user details and jwt token in local storage
          // to keep user logged in between page refreshes
          window.$cookies.set('token', user.token);
          window.$cookies.set('user', user);
        }

        return user;
      });
  },

  logout() {
    // remove user from local storage to log user out
    window.$cookies.remove('user');
    window.$cookies.remove('token');
  },

  register(user) {
    let headers = authHeader();
    return Api()
      .post('users/register', user, { headers: headers })
      .catch(handleError)
      .then(handleResponse);
  },

  getAll() {
    return Api().get('users', { headers: authHeader() }).catch(handleError).then(handleResponse);
  },

  getById(id) {
    const requestOptions = {
      headers: authHeader()
    };

    return Api()
      .get('users/' + id, requestOptions)
      .catch(handleError)
      .then(handleResponse);
  },

  update(user) {
    return Api()
      .put('users/' + user.id, user, { headers: authHeader() })
      .catch(handleError)
      .then(handleResponse);
  },

  delete(id) {
    const requestOptions = {
      headers: authHeader()
    };

    return Api()
      .delete('users/' + id, requestOptions)
      .catch(handleError)
      .then(handleResponse);
  }
};

function handleResponse(response) {
  if (response.status === 401) {
    // auto logout if 401 response returned from api
    exports.logout();
    location.reload(true);
  }

  return response.data;
}

function handleError(error) {
  return Promise.reject(error.response.data.message || error.response.statusText);
}
