import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { getEditableObject } from '../helpers';
import {
  DELIVERY_INTERVAL_TYPES,
  OpeningHours,
  PackingAlgorithm,
  RoutingMethod,
  ShippingNotificationConfig
} from '../helpers/types/index';
import { ProxyConfig } from '../helpers/types/proxyConfig';
import { FieldsEditRestriction } from '../../../shared/utils/fieldEditRestriction';
import ApiService from '../services/ApiService';
import { RootState } from './';

type BaseConfig = {
  id?: string;
  deliveryInterval?: number;
  deliveryIntervalType?: DELIVERY_INTERVAL_TYPES;
  freightDeadlineWarningInterval?: number;
  transporterReplyDeadline?: number;
  unloadTimePerPallet?: number;
  openingHours?: OpeningHours;
  lengthOfTimeSlot?: number;
  packingAlgorithm?: PackingAlgorithm;
  shippingNotificationConfig?: ShippingNotificationConfig;
  proxyConfig?: ProxyConfig;
  routingMethod?: (typeof RoutingMethod)[keyof typeof RoutingMethod];
  fuzzySearch: boolean;
};

type State = {
  deliveryInterval?: number;
  deliveryIntervalType?: DELIVERY_INTERVAL_TYPES;
  freightDeadlineWarningInterval?: number;
  transporterReplyDeadline?: number;
  unloadTimePerPallet?: number;
  openingHours?: OpeningHours;
  lengthOfTimeSlot?: number;
  packingAlgorithm?: PackingAlgorithm;
  shippingNotificationConfig?: ShippingNotificationConfig;
  shippingNotificationUrlReachable?: boolean;
  shippingNotificationUrlReachableLoading?: boolean;
  proxyUrlReachable?: boolean;
  proxyUrlReachableLoading?: boolean;
  proxyConfig?: ProxyConfig;
  routingMethod?: (typeof RoutingMethod)[keyof typeof RoutingMethod];
  fuzzySearch: boolean;
  original: BaseConfig;
};

const state: State = {
  deliveryInterval: undefined,
  deliveryIntervalType: undefined,
  freightDeadlineWarningInterval: undefined,
  transporterReplyDeadline: undefined,
  unloadTimePerPallet: undefined,
  openingHours: undefined,
  lengthOfTimeSlot: undefined,
  packingAlgorithm: undefined,
  shippingNotificationConfig: undefined,
  proxyConfig: { sendMessage: false, url: '', xApiKey: '' },
  proxyUrlReachable: undefined,
  proxyUrlReachableLoading: false,
  shippingNotificationUrlReachable: undefined,
  shippingNotificationUrlReachableLoading: false,
  routingMethod: undefined,
  fuzzySearch: false,
  original: {
    id: undefined,
    deliveryInterval: undefined,
    deliveryIntervalType: undefined,
    freightDeadlineWarningInterval: undefined,
    transporterReplyDeadline: undefined,
    unloadTimePerPallet: undefined,
    openingHours: undefined,
    lengthOfTimeSlot: undefined,
    packingAlgorithm: undefined,
    routingMethod: undefined,
    fuzzySearch: false
  }
};

const actions: ActionTree<State, RootState> = {
  async save({ commit, dispatch, rootGetters, rootState }) {
    const config = rootState.app.config;
    const fieldEditRestrictions = FieldsEditRestriction.init(config);
    const data = await getEditableObject(
      {
        id: state.original?.id,
        freightDeadlineWarningInterval: state.freightDeadlineWarningInterval,
        deliveryInterval: state.deliveryInterval,
        deliveryIntervalType: state.deliveryIntervalType,
        unloadTimePerPallet: state.unloadTimePerPallet,
        transporterReplyDeadline: state.transporterReplyDeadline,
        openingHours: state.openingHours,
        lengthOfTimeSlot: state.lengthOfTimeSlot,
        packingAlgorithm: state.packingAlgorithm,
        routingMethod: state.routingMethod,
        shippingNotificationConfig: state.shippingNotificationConfig,
        proxyConfig: rootGetters['module/isShippingNotificationModuleActive']
          ? state.proxyConfig
          : undefined,
        fuzzySearch: state.fuzzySearch
      },
      rootGetters['account/role'],
      fieldEditRestrictions.BASE_CONFIG_MODULE_DEPENDENCIES,
      fieldEditRestrictions.BASE_CONFIG_EDIT_RESTRICTIONS
    );
    await ApiService.setBaseConfig(data);
    commit('refresh', data);
    dispatch('alert/success', 'A mentés sikeresen megtörtént!', { root: true });
  },
  async get({ commit }) {
    const { data } = await ApiService.getBaseConfig();
    commit('refresh', data);
  },
  setOpeningHours({ commit }, { openingHours }) {
    commit('setOpeningHours', openingHours);
  },
  setRoutingMethod({ commit }, routingMethod) {
    commit('setRoutingMethod', routingMethod);
  },
  async cancel({ dispatch }) {
    await dispatch('get');
  },
  setShippingNotificationUrl({ commit }, url: string) {
    commit('setShippingNotificationUrl', url);
  },
  setShippingNotificationXApiKey({ commit }, xApiKey: string) {
    commit('setShippingNotificationXApiKey', xApiKey);
  },
  setShippingNotificationSendMessage({ commit }, sendMessage: boolean) {
    commit('setShippingNotificationSendMessage', sendMessage);
  },

  async pingShippingNotificationUrl({ commit }) {
    let result = false;
    commit('setShippingNotificationUrlReachableLoading', true);
    if (state.proxyConfig?.sendMessage) {
      result = await ApiService.ping(state.proxyConfig?.url, state.shippingNotificationConfig?.url);
    } else {
      result = await ApiService.ping(state.shippingNotificationConfig?.url);
    }
    commit('setShippingNotificationUrlReachable', result);
  },
  async pingProxyUrl({ commit }) {
    commit('setProxyUrlReachableLoading', true);
    const result = await ApiService.ping(state.proxyConfig?.url);
    commit('setProxyUrlReachable', result);
  },
  setProxyUrl({ commit }, url: string) {
    commit('setProxyUrl', url);
  },
  setProxyXApiKey({ commit }, xApiKey: string) {
    commit('setProxyXApiKey', xApiKey);
  },
  setProxySendMessage({ commit }, sendMessage: boolean) {
    commit('setProxySendMessage', sendMessage);
  },
  setFuzzySearch({ commit }, fuzzySearch: boolean) {
    commit('setFuzzySearch', fuzzySearch);
  }
};

const mutations: MutationTree<State> = {
  async refresh(state, payload: BaseConfig) {
    state.deliveryInterval = payload.deliveryInterval;
    state.deliveryIntervalType = payload.deliveryIntervalType;
    state.freightDeadlineWarningInterval = payload.freightDeadlineWarningInterval;
    state.transporterReplyDeadline = payload.transporterReplyDeadline;
    state.unloadTimePerPallet = payload.unloadTimePerPallet;
    state.openingHours = payload.openingHours;
    state.lengthOfTimeSlot = payload.lengthOfTimeSlot;
    state.original = JSON.parse(JSON.stringify(payload));
    state.packingAlgorithm = payload.packingAlgorithm;
    state.shippingNotificationConfig = payload.shippingNotificationConfig;
    state.routingMethod = payload.routingMethod;
    if (payload.proxyConfig) {
      state.proxyConfig = payload.proxyConfig;
    } else {
      state.original.proxyConfig = JSON.parse(JSON.stringify(state.proxyConfig));
    }
    state.fuzzySearch = payload.fuzzySearch;
  },
  setOpeningHours(state, openingHours) {
    state.openingHours = openingHours;
  },
  setRoutingMethod(state, routingMethod) {
    state.routingMethod = routingMethod;
  },
  setShippingNotificationUrl(state, url: string) {
    if (!state.shippingNotificationConfig) {
      return;
    }
    state.shippingNotificationConfig.url = url;
    state.shippingNotificationUrlReachable = undefined;
  },
  setShippingNotificationXApiKey(state, xApiKey: string) {
    if (!state.shippingNotificationConfig) {
      return;
    }
    state.shippingNotificationConfig.xApiKey = xApiKey;
  },
  setShippingNotificationSendMessage(state, sendMessage: boolean) {
    if (!state.shippingNotificationConfig) {
      return;
    }
    state.shippingNotificationConfig.sendMessage = sendMessage;
  },
  setShippingNotificationUrlReachable(state, success: boolean) {
    state.shippingNotificationUrlReachable = success;
    state.shippingNotificationUrlReachableLoading = false;
  },
  setProxyUrlReachable(state, success: boolean) {
    state.proxyUrlReachable = success;
    state.proxyUrlReachableLoading = false;
  },
  setShippingNotificationUrlReachableLoading(state, loading: boolean) {
    state.shippingNotificationUrlReachableLoading = loading;
  },
  setProxyUrlReachableLoading(state, loading: boolean) {
    state.proxyUrlReachableLoading = loading;
  },
  setProxyUrl(state, url: string) {
    if (!state.proxyConfig) {
      return;
    }
    state.proxyConfig.url = url;
    state.proxyUrlReachable = undefined;
  },
  setProxyXApiKey(state, xApiKey: string) {
    if (!state.proxyConfig) {
      return;
    }
    state.proxyConfig.xApiKey = xApiKey;
  },
  setProxySendMessage(state, sendMessage: boolean) {
    if (!state.proxyConfig) {
      return;
    }
    state.proxyConfig.sendMessage = sendMessage;
  },
  setFuzzySearch(state, fuzzySearch: boolean) {
    state.fuzzySearch = fuzzySearch;
  },
  updateField
};

const getters: GetterTree<State, RootState> = {
  deliveryInterval: (state) => state.deliveryInterval,
  deliveryIntervalType: (state) => state.deliveryIntervalType,
  freightDeadlineWarningInterval: (state) => state.freightDeadlineWarningInterval,
  transporterReplyDeadline: (state) => state.transporterReplyDeadline,
  openingHours: (state) => state.openingHours,
  lengthOfTimeSlot: (state) => state.lengthOfTimeSlot,
  dirty() {
    return (
      state.original &&
      (state.original.deliveryInterval !== state.deliveryInterval ||
        state.original.deliveryIntervalType !== state.deliveryIntervalType ||
        state.original.freightDeadlineWarningInterval !== state.freightDeadlineWarningInterval ||
        state.original.unloadTimePerPallet !== state.unloadTimePerPallet ||
        state.original.transporterReplyDeadline !== state.transporterReplyDeadline ||
        JSON.stringify(state.original.openingHours) !== JSON.stringify(state.openingHours) ||
        state.original.lengthOfTimeSlot !== state.lengthOfTimeSlot ||
        state.original.packingAlgorithm !== state.packingAlgorithm ||
        state.original.routingMethod !== state.routingMethod ||
        JSON.stringify(state.original.shippingNotificationConfig) !==
          JSON.stringify(state.shippingNotificationConfig) ||
        JSON.stringify(state.original.proxyConfig) !== JSON.stringify(state.proxyConfig) ||
        state.original.fuzzySearch !== state.fuzzySearch)
    );
  },
  packingAlgorithm: (state) => state.packingAlgorithm,
  getField
};

export const basicSettings = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
