export type PointGeometry = {
  coordinates: [number, number];
  type: 'Point';
};

export type LineStringGeometry = {
  coordinates: [number, number][];
  type: 'LineString';
};

export enum RoutingMethod {
  ECONOMY = 'ECONOMY',
  FAST = 'FAST',
  SHORT = 'SHORT',
  DIRECT = 'DIRECT',
  PRIORITY = 'PRIORITY'
}

export type Tariff = {
  external: number;
  infrastructure: number;
  netTotal: number;
  total: number;
  vat: number;
};
