import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

import { RootState } from '.';
import {
  CheckChangesType,
  CommissionedUnit,
  CommissionedUnitType,
  Dock,
  Freight,
  Order,
  Pickup
} from '../helpers/types/index';
import ApiService from '../services/ApiService';

export type State = {
  depotShortName: string;
  date: string; // ISO date
  docks: Dock[];
  timeSlots: string[];
  timeSlotsLookup: any;
  freeDocks?: string[];
  freeTimeSlots?: string[];
  lengthOfTimeSlot: number;
  freights: Freight[];
  pickups: Pickup[];
  hasChanges: boolean;
  lastRefresh: {
    date?: Date;
    freightCount?: number;
    pickupCount?: number;
    changedSince: boolean;
  };
};

const state: State = {
  depotShortName: '',
  date: new Date().toISOString(),
  docks: [],
  timeSlots: [],
  timeSlotsLookup: {},
  freeDocks: undefined,
  freeTimeSlots: undefined,
  lengthOfTimeSlot: 0,
  freights: [],
  pickups: [],
  hasChanges: false,
  lastRefresh: {
    date: undefined,
    freightCount: undefined,
    pickupCount: undefined,
    changedSince: false
  }
};

const actions: ActionTree<State, RootState> = {
  setDepotShortName({ commit }, data) {
    commit('setDepotShortName', data);
  },

  setDate({ commit }, data) {
    commit('setDate', data);
  },

  async getTimeSlots({ commit, dispatch, state }) {
    const { data } = await ApiService.getTimeSlots(state.depotShortName, state.date);

    commit('setTimeSlots', data);
    dispatch('computeTimeSlotsLookup');
  },

  async checkChanges({ commit, rootGetters }) {
    const params = {
      date: state.lastRefresh.date,
      count: undefined,
      deliveryDate: state.date,
      depotShortName: state.depotShortName
    };

    const { data: freightChagnes } = await ApiService.checkFreightChanges(
      CheckChangesType.timeSlot,
      {
        ...params,
        ...{ count: state.lastRefresh.freightCount }
      }
    );

    const pickupModuleIsActive = rootGetters['module/isPickupModuleActive'];
    let pickupChagnes = false;
    if (pickupModuleIsActive) {
      const { data } = await ApiService.checkPickupChanges(CheckChangesType.timeSlot, {
        ...params,
        ...{ count: state.lastRefresh.pickupCount }
      });

      pickupChagnes = data.hasChanges;
    }

    const hasChanges = freightChagnes.hasChanges || pickupChagnes;
    commit('setHasChanges', hasChanges);
  },

  setLastRefresh(
    { commit },
    lastRefresh: {
      date?: Date;
      freightCount?: number;
      pickupCount?: number;
      changedSince: boolean;
    }
  ) {
    commit('setLastRefresh', lastRefresh);
  },

  computeTimeSlotsLookup({ commit, rootGetters }) {
    const timeSlotsLookup: any = {};
    // collect reserved timeSlots for each dock
    const commissionedUnits = [...(state.freights || []), ...(state.pickups || [])];
    commissionedUnits.forEach((commissionedUnit) => {
      if (commissionedUnit.timeSlot) {
        timeSlotsLookup[
          commissionedUnit.timeSlot.dockId ? commissionedUnit.timeSlot.dockId.toString() : 'no-dock'
        ]
          ? timeSlotsLookup[
              commissionedUnit.timeSlot.dockId
                ? commissionedUnit.timeSlot.dockId.toString()
                : 'no-dock'
            ].push(commissionedUnit.timeSlot.time)
          : (timeSlotsLookup[
              commissionedUnit.timeSlot.dockId
                ? commissionedUnit.timeSlot.dockId.toString()
                : 'no-dock'
            ] = [commissionedUnit.timeSlot.time]);

        if (
          rootGetters['module/isTimeSlotWidthActive'] &&
          commissionedUnit.timeSlot.timeSlotWidth &&
          commissionedUnit.timeSlot.dockId
        ) {
          for (let i = 1; i < commissionedUnit.timeSlot.timeSlotWidth; i++) {
            if (commissionedUnit.timeSlot.time) {
              timeSlotsLookup[commissionedUnit.timeSlot.dockId.toString()].push(
                state.timeSlots[state.timeSlots.indexOf(commissionedUnit.timeSlot.time) + i]
              );
            }
          }
        }
      }
    });

    // compute reserved timeSlots for no-dock column
    state.timeSlots.forEach((timeSlot) => {
      if (
        state.timeSlotsLookup &&
        state.docks?.length > 0 &&
        Object.values(timeSlotsLookup)
          .flat(1)
          .filter((reservedTimeSlot) => reservedTimeSlot === timeSlot)?.length ===
          state.docks.length &&
        state.freights.length > 0
      ) {
        timeSlotsLookup['no-dock']
          ? timeSlotsLookup['no-dock'].push(timeSlot)
          : (timeSlotsLookup['no-dock'] = [timeSlot]);
      }
    });

    commit('setTimeSlotsLookup', timeSlotsLookup);
  },

  async getFreeSlots(
    { commit, rootGetters },
    {
      commissionedUnitId,
      commissionedUnitType,
      commissionedUnitTimeSlotWidth,
      newTime,
      newDockId
    }: {
      commissionedUnitId: string;
      commissionedUnitType: CommissionedUnitType;
      commissionedUnitTimeSlotWidth: number;
      newTime: string;
      newDockId: string;
    }
  ) {
    const { data } = await ApiService.getFreeTimeSlotsAndDocks({
      commissionedUnitId,
      commissionedUnitType,
      date: state.date,
      depotShortName: state.depotShortName,
      dockId: newDockId === 'no-dock' ? undefined : newDockId,
      timeSlot: newTime,
      timeSlotWidth: rootGetters['module/isTimeSlotWidthActive']
        ? commissionedUnitTimeSlotWidth
        : undefined
    });

    commit('setFreeTimeSlots', data.freeTimeSlots);
    commit('setFreeDocks', data.freeDocks);
  },

  async getAllFreeSlots(
    { commit, rootGetters },
    {
      commissionedUnitId,
      commissionedUnitType,
      timeSlotWidth
    }: {
      commissionedUnitId: string;
      commissionedUnitType: CommissionedUnitType;
      timeSlotWidth: number;
    }
  ) {
    const { data } = await ApiService.getAllFreeTimeSlotsAndDocks({
      commissionedUnitId,
      commissionedUnitType,
      date: state.date,
      depotShortName: state.depotShortName,
      timeSlotWidth: rootGetters['module/isTimeSlotWidthActive'] ? timeSlotWidth : undefined
    });

    commit('setTimeSlotsLookup', data);
  },

  async getCommissionedUnits({ commit, rootGetters, rootState, state }) {
    const { data: freights }: { data: (Freight & { joinedOrderIds: string })[] } =
      await ApiService.getFreightsForTimeSlotsView({
        deliveryDateFrom: state.date,
        deliveryDateTo: state.date,
        depotShortName: state.depotShortName
      });

    for (const freight of freights) {
      const uniqueOrderIds = [...new Set(freight.cargo?.map((cargoItem) => cargoItem.orderId))];
      freight.joinedOrderIds = uniqueOrderIds.join(', ');
    }
    commit('setFreights', freights);

    const pickupModuleIsActive = rootGetters['module/isPickupModuleActive'];
    const userRole = rootGetters['account/role'];
    const constants = rootState.app.constants;
    const allowedToSeePickups =
      userRole &&
      [
        ...constants.ADMIN_ROLES,
        ...constants.LOGISTICS_ROLES,
        ...constants.PRODUCTION_ROLES,
        constants.ROLES.ENTRANCE_SUPERVISOR
      ].includes(userRole);

    if (pickupModuleIsActive && allowedToSeePickups) {
      const { data: pickups }: { data: Pickup[] } = await ApiService.getPickupsForTimeSlotsView({
        deliveryDateFrom: state.date,
        deliveryDateTo: state.date,
        depotShortName: state.depotShortName
      });
      commit('setPickups', pickups);
    }
  },

  async addDetailsToCommissionedUnit({ commit }, commissionedUnit: CommissionedUnit) {
    if (commissionedUnit.orders) {
      commit('setCommissionedUnitDetails', { ...commissionedUnit, orders: undefined });
    } else {
      let orders: Order[] = [];
      if (commissionedUnit.type === CommissionedUnitType.Freight) {
        const { data } = await ApiService.getFreightDetails(
          (commissionedUnit as Freight).freightId
        );
        orders = data;
      } else if (commissionedUnit.type === CommissionedUnitType.Pickup) {
        const { data } = await ApiService.getPickupDetails((commissionedUnit as Pickup).pickupId);
        orders = data;
      }

      for (const order of orders) {
        const { data } = await ApiService.getOrderByOrderId(order.orderId);
        Object.assign(order, data);
      }

      commit('setCommissionedUnitDetails', { ...commissionedUnit, orders: orders });
    }
  },

  async setDockAndTimeSlot(
    { commit },
    {
      commissionedUnit,
      dockId,
      timeSlot
    }: { commissionedUnit: CommissionedUnit; dockId: string; timeSlot: string }
  ) {
    if (commissionedUnit.type === CommissionedUnitType.Freight) {
      commit('setFreightDockAndTimeSlot', {
        freight: commissionedUnit as Freight,
        dockId,
        timeSlot
      });

      await ApiService.setFreightDockAndTimeSlot(
        (commissionedUnit as Freight).id,
        (commissionedUnit as Freight).__v,
        dockId,
        timeSlot
      );
    } else if (commissionedUnit.type === CommissionedUnitType.Pickup) {
      commit('setPickupDockAndTimeSlot', {
        pickup: commissionedUnit as Pickup,
        dockId,
        timeSlot
      });

      await ApiService.setPickupDockAndTimeSlot(
        (commissionedUnit as Pickup).id,
        (commissionedUnit as Pickup).__v,
        dockId,
        timeSlot
      );
    }
  },

  async setNextCommissionedUnitStatus({ state }, commissionedUnit: CommissionedUnit) {
    const isFreight = state.freights.find((fr) => fr.id === commissionedUnit.id);
    const isPickup = state.pickups.find((pu) => pu.id === commissionedUnit.id);
    if (isFreight) {
      await ApiService.setNextFreightStatus(commissionedUnit.id, commissionedUnit.__v);
    } else if (isPickup) {
      await ApiService.setNextPickupStatus(commissionedUnit.id, commissionedUnit.__v);
    }
  }
};

const mutations: MutationTree<State> = {
  setDepotShortName(state, depotShortName) {
    state.depotShortName = depotShortName;
  },

  setDate(state, date) {
    state.date = date;
  },

  setTimeSlots(state, { lengthOfTimeSlot, timeSlots, docks }) {
    state.lengthOfTimeSlot = lengthOfTimeSlot;
    state.timeSlots = timeSlots;
    state.docks = docks;
  },

  setFreights(state, freights: Freight[]) {
    freights.map((freight) => (freight.type = CommissionedUnitType.Freight));
    state.freights = freights;
  },

  setPickups(state, pickups: Pickup[]) {
    pickups.map((pickup) => (pickup.type = CommissionedUnitType.Pickup));
    state.pickups = pickups;
  },

  setCommissionedUnitDetails(state, commissionedUnit: CommissionedUnit) {
    if (commissionedUnit.type === CommissionedUnitType.Freight && state.freights) {
      const selectedFreight =
        state.freights.find((fr) => fr.freightId === (commissionedUnit as Freight).freightId) || {};

      Object.assign(selectedFreight, commissionedUnit);
      state.freights = [...(state.freights as any[])];
    } else if (commissionedUnit.type === CommissionedUnitType.Pickup && state.pickups) {
      const selectedPickup =
        state.pickups.find((pu) => pu.pickupId === (commissionedUnit as Pickup).pickupId) || {};

      Object.assign(selectedPickup, commissionedUnit);
      state.pickups = [...(state.pickups as Pickup[])];
    }
  },

  setFreightDockAndTimeSlot(
    state,
    { freight, dockId, timeSlot }: { freight: Freight; dockId: string; timeSlot: string }
  ) {
    state.freights = [
      ...state.freights.map((fr) => {
        if (fr.id === freight.id) {
          fr.timeSlot = { dockId, time: timeSlot, timeSlotWidth: fr.timeSlot?.timeSlotWidth ?? 1 };
        }

        return fr;
      })
    ];
  },

  setPickupDockAndTimeSlot(
    state,
    { pickup, dockId, timeSlot }: { pickup: Pickup; dockId: string; timeSlot: string }
  ) {
    state.pickups = [
      ...state.pickups.map((pu) => {
        if (pu.id === pickup.id) {
          pu.timeSlot = { dockId, time: timeSlot, timeSlotWidth: pu.timeSlot?.timeSlotWidth ?? 1 };
        }

        return pu;
      })
    ];
  },

  setTimeSlotsLookup(state, timeSlotsLookup) {
    state.timeSlotsLookup = timeSlotsLookup;
  },

  setFreeTimeSlots(state, freeTimeSlots) {
    state.freeTimeSlots = freeTimeSlots;
  },

  setFreeDocks(state, freeDocks) {
    state.freeDocks = freeDocks.map((dock: any) => dock.id);
  },

  setHasChanges(state, hasChanges: boolean) {
    state.hasChanges = hasChanges;
  },

  setLastRefresh(state) {
    state.lastRefresh = {
      date: new Date(),
      freightCount: state.freights.length,
      pickupCount: state.pickups.length,
      changedSince: false
    };
  },

  updateField
};

const getters: GetterTree<State, RootState> = {
  commissionedUnits: (state) => {
    const freights = state.freights ?? [];
    const pickups = state.pickups ?? [];
    const commissionedUnits = [...freights, ...pickups];

    return commissionedUnits;
  },
  getField
};

export const timeSlotView: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
