import debounce from 'lodash.debounce';
import { Commit, Dispatch, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

import ClickableMarker from './ClickableMarker';
import { LineStringGeometry, PointGeometry, RoutingMethod, Tariff } from './types';

import { getVehicleCategory } from './helpers/vehicleCategory';
import { RoutePlannerApiService } from './interface/routePlannerApiService';

type Vehicle = {
  id?: string;
  vehicleId?: string;
  weight?: number;
  axleCount?: number;
  axleWeight?: number;
};

type Address = {
  address: string;
  geometry: PointGeometry;
  isDepot?: boolean;
  id: number;
};

type RouteResult = {
  distanceMeter?: number;
  durationSecond?: number;
  geometry?: LineStringGeometry;
  tariff?: Tariff;
  legality?: [object];
  cO2Emission?: number;
  traversedZones?: [string];
};

export type State = {
  inited: boolean;
  apiService?: RoutePlannerApiService;

  activeVehicles?: Vehicle[];
  selectedVehicle?: Vehicle;
  selectedTruckEuroCategoryType?: string;
  maxWaypointsLength: number;
  waypointsList: Address[];
  newAddress?: Address;
  isAddressAutoCompleteLoading: boolean;
  addressAutoCompleteEntries?: any[];
  addressAutoCompleteEntry?: Address;
  routePlanWithWeights: boolean;
  keepWaypointsOrder: boolean;
  pinFirstWaypoint: boolean;
  pinLastWaypoint: boolean;
  routeResult?: RouteResult;
  routeNotFound?: boolean;
  waypointMarkers: ClickableMarker[];
  waypointId: number;
  waypointSearchResultMarker?: ClickableMarker;
  planningRouteIsInProgress: boolean;
  clickedMarker?: ClickableMarker;
  routingMethod: (typeof RoutingMethod)[keyof typeof RoutingMethod];
  useFerry: boolean;
  useMotorway: boolean;
  doCO2Calc: boolean;
  zoneCode: string;
  zoneList: string[];
  hasAllZones: boolean;
  isNewZoneCode: boolean;
};

const state: State = {
  inited: false,
  apiService: undefined,

  activeVehicles: [],
  selectedVehicle: undefined,
  selectedTruckEuroCategoryType: undefined,
  maxWaypointsLength: 10,
  waypointsList: [],
  newAddress: undefined,
  isAddressAutoCompleteLoading: false,
  addressAutoCompleteEntries: [],
  addressAutoCompleteEntry: undefined,
  routePlanWithWeights: true,
  keepWaypointsOrder: true,
  pinFirstWaypoint: false,
  pinLastWaypoint: false,
  routeResult: undefined,
  routeNotFound: undefined,
  waypointMarkers: [],
  waypointId: 0,
  waypointSearchResultMarker: undefined,
  planningRouteIsInProgress: false,
  clickedMarker: undefined,
  routingMethod: RoutingMethod.FAST,
  useFerry: false,
  useMotorway: true,
  doCO2Calc: false,
  hasAllZones: false,
  zoneList: [],
  zoneCode: '',
  isNewZoneCode: false
};

const actions = {
  async init({
    dispatch,
    commit,
    state
  }: {
    dispatch: Dispatch;
    commit: Commit;
    state: State;
  }): Promise<void> {
    if (state.inited) {
      return;
    }
    const apiService = await dispatch('app/getApiService', null, { root: true });
    commit('setApiService', apiService);
    commit('setInited', true);
  },

  async getActiveVehicles({ commit, state }: { commit: any; state: any }) {
    const { data } = await state.apiService.getActiveVehicles();
    commit('setActiveVehicles', data);
  },

  refreshAddressEntries: debounce(async function (
    { commit, rootGetters, state }: any,
    value: string
  ) {
    commit('setIsAddressAutoCompleteLoading', true);
    try {
      value = value.trim();
      let result: any = [];
      if (value.length >= 2) {
        const response = await state.apiService.locationSearch(value);
        response.data.result.features.forEach((item: any) => {
          result.push({
            address: item.properties.address,
            geometry: item.geometry
          });
        });
      }

      const depots: Array<any> = rootGetters['depots/depots'];
      const regex = new RegExp('.*' + value + '.*', 'i');
      const filteredDepots = depots
        .filter((depot) => regex.test(depot.address))
        .map((depot) => ({
          address: depot.address,
          geometry: depot.geometry,
          isDepot: true
        }));

      result = [...filteredDepots, ...result];

      commit('setAddressAutoCompleteEntries', result);
    } catch (ex) {
      commit('setAddressAutoCompleteEntries', []);
    } finally {
      commit('setIsAddressAutoCompleteLoading', false);
    }
  }, 300),

  async planRoute({
    commit,
    dispatch,
    rootGetters,
    state
  }: {
    commit: any;
    dispatch: any;
    rootGetters: any;
    state: any;
  }) {
    state.planningRouteIsInProgress = true;

    const routeParams = {
      vehicleCategory: getVehicleCategory(state.selectedVehicle?.axleCount),
      euroCategory: state.selectedTruckEuroCategoryType,
      weight: state.selectedVehicle?.weight,
      axleWeight: state.selectedVehicle?.axleWeight,
      useFallback: !state.routePlanWithWeights,
      method: state.routingMethod,
      waypoints: state.waypointsList.map(
        (waypoint: { geometry: { coordinates: any } }) => waypoint.geometry?.coordinates
      ),
      ferry: state.useFerry,
      motorway: state.useMotorway,
      startAt: !state.keepWaypointsOrder && state.pinFirstWaypoint ? 0 : undefined,
      endAt:
        !state.keepWaypointsOrder && state.pinLastWaypoint
          ? state.waypointsList.length - 1
          : undefined,
      disregardedZones: undefined
    };

    if (state.hasAllZones === false) {
      routeParams.disregardedZones = state.zoneList;
    }

    try {
      const { data } = await state.apiService.routePlan(routeParams, state.keepWaypointsOrder);
      if (data) {
        if (state.doCO2Calc && data.distanceMeter) {
          try {
            const vehicle = rootGetters['cO2/getSelected'];
            if (vehicle) {
              const estimateReq = {
                vehicle,
                distance: data.distanceMeter / 1000
              };
              const result = await dispatch('cO2/doEstimate', estimateReq, { root: true });
              data.cO2Emission = result.cO2Emission;
            }
          } catch (error) {
            // no-op
          }
        }
        commit('setRouteResult', data);
      }
    } catch (err) {
      // no-op
    } finally {
      state.planningRouteIsInProgress = false;
    }
  },

  addToWaypointsList(
    { commit, dispatch, state, rootState }: any,
    payload: { waypoint: { id: any }; center: any; map: any }
  ) {
    if (state.waypointsList.length < state.maxWaypointsLength) {
      commit('addWaypointToWaypointsList', payload.waypoint);

      const newWaypointMarker = new ClickableMarker(
        { color: rootState.app.config.view.theme.waypoint, scale: 0.9, draggable: true },
        payload.waypoint.id
      )
        .setLngLat(payload.center)
        .addTo(payload.map);

      dispatch('addContextmenuToMarker', {
        marker: newWaypointMarker,
        map: payload.map
      });

      newWaypointMarker.getElement().addEventListener('mousedown', (e: any) => {
        // enable dragging marker only with left click
        if (e.button === 0) {
          newWaypointMarker.setDraggable(true);
        } else {
          newWaypointMarker.setDraggable(false);
        }
      });

      dispatch('addDragendToMarker', {
        marker: newWaypointMarker,
        map: payload.map,
        waypoint: payload.waypoint
      });

      commit('addMarkerToWaypointMarkers', newWaypointMarker);
    }
  },

  checkIfNewZoneCode({ commit }: any, zoneCode: string) {
    if (state.zoneList.includes(zoneCode)) {
      commit('setIsNewZoneCode', false);
    } else {
      commit('setIsNewZoneCode', true);
    }
  },

  addToZonesList({ commit, state }: any, zoneCode: string) {
    if (!state.zoneList.includes(zoneCode)) commit('addZoneToZonesList', zoneCode);
  },

  addDragendToMarker(
    { commit, rootGetters, state }: any,
    payload: {
      marker: {
        on: (arg0: string, arg1: (e: any) => Promise<void>) => void;
        setLngLat: (arg0: any) => void;
        getLngLat: () => { (): any; new (): any; lng: number; lat: number };
      };
      map: { queryRenderedFeatures: (arg0: any[][]) => any };
      waypoint: { id: any };
    }
  ) {
    payload.marker.on('dragend', async (e: any) => {
      try {
        let newWaypoint = {};

        const bbox = [
          [e.target._pos.x - 10, e.target._pos.y - 10],
          [e.target._pos.x + 10, e.target._pos.y + 10]
        ];
        const renderedFeatures = payload.map.queryRenderedFeatures(bbox);
        const depot = renderedFeatures.find((feature: any) => feature.properties.isDepot);

        if (depot) {
          const depotGeometry = rootGetters['depots/depots'].find(
            (storedDepot: any) => storedDepot.shortName === depot.properties.shortName
          ).geometry;
          const waypoint: Address = {
            id: payload.waypoint.id,
            address: depot.properties.address,
            geometry: depotGeometry,
            isDepot: true
          };
          newWaypoint = waypoint;

          payload.marker.setLngLat(depotGeometry.coordinates);
        } else {
          const reverseGeocodeRes = await state.apiService.reverseGeocode(e.target._lngLat);
          if (
            !reverseGeocodeRes.data.result.features ||
            reverseGeocodeRes.data.result.features.length === 0
          ) {
            throw new Error();
          }

          // update waypoint with newly geocoded result
          const waypoint: Address = {
            id: payload.waypoint.id,
            address: reverseGeocodeRes.data.result.features[0].properties.address,
            geometry: {
              type: 'Point',
              coordinates: [payload.marker.getLngLat().lng, payload.marker.getLngLat().lat]
            },
            isDepot: false
          };

          newWaypoint = waypoint;
        }

        const index = state.waypointsList.findIndex(
          (waypoint: { id: any }) => waypoint.id === payload.waypoint.id
        );
        commit('updateWaypoint', { index, newWaypoint });
      } catch (err) {
        // set marker back to original coordinates at error
        const waypoint = state.waypointsList.find(
          (waypoint: { id: any }) => waypoint.id === payload.waypoint.id
        );
        payload.marker.setLngLat(waypoint?.geometry.coordinates);
      }
    });
  },

  addContextmenuToMarker(
    { commit }: any,
    payload: {
      marker: {
        getElement: () => {
          (): any;
          new (): any;
          addEventListener: {
            (arg0: string, arg1: (e: any) => void): void;
            new (): any;
          };
        };
      };
      map: { dragRotate: { disable: () => void; enable: () => void } };
    }
  ) {
    payload.marker.getElement().addEventListener('contextmenu', (e: any) => {
      payload.map.dragRotate.disable();
      commit('setClickedMarker', payload.marker);

      e.preventDefault();
      e.stopPropagation();
      payload.map.dragRotate.enable();
    });
  },

  setRoutingMethod({ commit }: any, routingMethod: RoutingMethod) {
    commit('setRoutingMethod', routingMethod);
    commit('setRouteResult', undefined);
  },

  setFerry({ commit }: any, ferry: boolean) {
    commit('setUseFerry', ferry);
    commit('setRouteResult', undefined);
  },

  setMotorway({ commit }: any, motorway: boolean) {
    commit('setUseMotorway', motorway);
    commit('setRouteResult', undefined);
  },

  setAllZones({ commit }: any, hasAllZones: boolean) {
    commit('setHasAllZones', hasAllZones);
    commit('setRouteResult', undefined);
  }
};

const mutations: MutationTree<State> = {
  setInited(state: State, payload: boolean): void {
    state.inited = payload;
  },

  setApiService(state: State, payload: any): void {
    state.apiService = payload;
  },

  setActiveVehicles(state, payload: object[]) {
    state.activeVehicles = payload;
  },

  setIsAddressAutoCompleteLoading(state: { isAddressAutoCompleteLoading: any }, payload: any) {
    state.isAddressAutoCompleteLoading = payload;
  },

  setAddressAutoCompleteEntries(state, payload: any) {
    state.addressAutoCompleteEntries = payload;
  },

  addWaypointToWaypointsList(state, payload) {
    payload.id = state.waypointId;
    state.waypointId++;

    state.waypointsList.push(payload);
  },
  addZoneToZonesList(state, payload: string) {
    state.zoneList.push(payload);
  },

  updateWaypoint(state, payload: { index: any; newWaypoint: Address }) {
    state.waypointsList.splice(payload.index, 1, payload.newWaypoint as Address);
  },

  addMarkerToWaypointMarkers(state, payload: any) {
    state.waypointMarkers.push(payload);
  },

  setClickedMarker(state, payload: any) {
    state.clickedMarker = payload;
  },

  deleteFromWaypointsList(state, id: any) {
    const index = state.waypointsList.findIndex((waypoint: { id: any }) => waypoint.id === id);
    const marker = state.waypointMarkers.find((marker: { id: any }) => marker.id === id);
    if (marker) {
      state.waypointMarkers = state.waypointMarkers.filter(
        (marker: { id: any }) => marker.id !== id
      );
      marker.remove();
    }

    state.waypointsList.splice(index, 1);
  },

  setRouteResult(
    state,
    payload: {
      error: any;
      distanceMeter: any;
      durationSecond: any;
      geometry: any;
      tariff: Tariff;
      legality: any;
      cO2Emission?: any;
      traversedZones?: any;
    }
  ) {
    if (!payload) {
      // if route deleted
      state.routeResult = undefined;
      state.routeNotFound = undefined;
    } else if (!payload.error) {
      // if route found
      state.routeResult = {};
      state.routeResult.distanceMeter = payload.distanceMeter;
      state.routeResult.durationSecond = payload.durationSecond;
      state.routeResult.geometry = payload.geometry;
      state.routeResult.tariff = payload.tariff;
      state.routeResult.legality = payload.legality;
      state.routeResult.cO2Emission = payload.cO2Emission;
      state.routeResult.traversedZones = payload.traversedZones;

      state.routeNotFound = false;
    } else {
      // if no route found
      state.routeResult = undefined;
      state.routeNotFound = true;
    }
  },

  clearClickedMarker(state) {
    state.clickedMarker = undefined;
  },

  setRoutingMethod(state, routingMethod: RoutingMethod) {
    state.routingMethod = routingMethod;
  },

  setUseFerry(state, useFerry: boolean) {
    state.useFerry = useFerry;
  },

  setUseMotorway(state, useMotorway: boolean) {
    state.useMotorway = useMotorway;
  },

  setHasAllZones(state, hasAllZones: boolean) {
    state.hasAllZones = hasAllZones;
  },
  setIsNewZoneCode(state, isNewZoneCode: boolean) {
    state.isNewZoneCode = isNewZoneCode;
  },

  updateField
};

const getters = {
  clickedMarker: (state: State) => state.clickedMarker,
  isAddressAutoCompleteLoading: (state: State) => state.isAddressAutoCompleteLoading,
  newAddress: (state: State) => state.newAddress,
  planningRouteIsInProgress: (state: State) => state.planningRouteIsInProgress,
  routeNotFound: (state: State) => state.routeNotFound,
  routeResult: (state: State) => state.routeResult,
  activeVehicles: (state: State) => state.activeVehicles,
  waypointsList: (state: State) => state.waypointsList,
  waypointMarkers: (state: State) => state.waypointMarkers,
  waypointListNotFull: (state: State) => state.waypointsList.length < state.maxWaypointsLength,
  getMapcatJson: async (state: State) => {
    const { data: mapcatJson } = await state.apiService!.getStyle();
    return mapcatJson;
  },
  getWeightApiRestrictions: (state: State) => async (coordinates: number[]) => {
    const { data: result } = await state.apiService!.getWeightApiRestrictions(coordinates);
    return result;
  },
  reverseGeocode: (state: State) => async (lngLat: any) => {
    const reverseGeocodeRes = await state.apiService!.reverseGeocode(lngLat);
    return reverseGeocodeRes;
  },

  getField,
  zoneList: (state: State) => state.zoneList,
  isNewZoneCode: (state: State) => state.isNewZoneCode
};

export const routePlanner = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
