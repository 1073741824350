import moment from 'moment-mini';
import { Calendar } from '../types/calendar';
import { isSameDate } from '../utils/date';

export function isWorkday(day: Date, calendar: Calendar[]): boolean {
  const calendarItem = calendar.find((item) => isSameDate(new Date(item.date), day));
  if (calendarItem) {
    return calendarItem.workday;
  } else {
    const dayOfWeek = new Date(day).getDay();
    return dayOfWeek > 0 && dayOfWeek < 6;
  }
}

/**
 * Find workday before or after the given date + {shiftDays}
 * Example1: last workday before today: dateString={today as parsable string}, shiftDays=-1
 * Example2: next workday after today: dateString={today as parsable string}, shiftDays=1
 * @param dateString data in string
 * @param shiftWorkdays shifted day number - if negative search before else search after
 * @param calendar Array<Calendar>
 * @returns moment.Moment
 */
export function findWorkday(
  dateString: string,
  shiftWorkdays: number,
  calendar: Calendar[]
): moment.Moment {
  // value can come from the date picker and the text field in different formats
  const step = shiftWorkdays < 0 ? -1 : 1;
  const workdayCount = Math.abs(shiftWorkdays);
  let counter = 0;
  let date = moment(dateString);

  let isThisWorkday = isWorkday(date.toDate(), calendar);
  while (!isThisWorkday || counter < workdayCount) {
    date = date.add(step, 'days');
    isThisWorkday = isWorkday(date.toDate(), calendar);
    if (isThisWorkday) {
      counter++;
    }
  }
  return date;
}
