import VueMask from 'v-mask';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueSweetalert2 from 'vue-sweetalert2';
import vuetify from './plugins/vuetify';
// Ensure you are using css-loader
import { abilitiesPlugin, Can } from '@casl/vue';
import 'vuetify/dist/vuetify.min.css';
import App from './App.vue';
import i18n from './locales/i18n';
import { router } from './router';
import { buildAbilityFor } from './services/ability';
import { store } from './store';

// load client config before initiating Vue
store.dispatch('app/fetchConfig').then(async () => {
  const ability = buildAbilityFor();

  Vue.use(VueSweetalert2);
  Vue.use(VueCookies);
  Vue.use(VueMask);
  Vue.use(abilitiesPlugin, ability, {
    useGlobalProperties: true
  });
  Vue.component('Can', Can);

  // set default config: restrict to the base path
  (VueCookies as any).config('1d', (process.env.BASE_URL || '/').replace(/\/+$/, ''));
  Vue.config.productionTip = false;

  // register base components globally
  const requireComponent = require.context('./components/base', false, /\.(vue|js)$/);
  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    const componentName = fileName
      .split('/')
      .pop()!
      .replace(/\.\w+$/, '');

    Vue.component(componentName, componentConfig.default || componentConfig);
  });
  // get active modules if the user has already logged in but refreshing the screen
  if (store.getters['account/isUserLoggedIn']) {
    await store.dispatch('module/getActiveModules');
  }
  // set theme colors from viewConfig
  vuetify.framework.theme.themes.light = store.getters['app/viewConfig'].theme;

  new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: (h) => h(App) // eslint-disable-line id-length
  }).$mount('#app');
});
