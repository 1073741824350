import Vue from 'vue';

import debounce from 'lodash.debounce';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

import confirm from '../helpers/confirm';
import { normalizeAddressString } from '../helpers/normalizeAddress';
import { Dock, OpeningHours, PointGeometry } from '../helpers/types/index';
import ApiService from '../services/ApiService';
import { RootState } from './';

type AddressAutoCompleteEntry = {
  address?: string;
  geometry?: PointGeometry;
};

type Depot = {
  id?: string;
  shortName?: string;
  name?: string;
  address?: string;
  capacity?: number;
  geometry?: PointGeometry;
  isActive?: Boolean;
  openingHours?: OpeningHours;
  lengthOfTimeSlot?: number;
  timeSlotReservationLimit?: number;
  docks?: Dock[];
  useDefaultOpeningHours?: boolean;
};

type State = {
  depots?: Depot[];
  depotNamesWithShortNames?: { value: string; text: string }[];
  isNew?: boolean;
  edited?: Depot;
  isAddressAutoCompleteLoading?: boolean;
  addressAutoCompleteEntries?: AddressAutoCompleteEntry[];
  addressAutoCompleteEntry?: AddressAutoCompleteEntry;
};

const state: State = {
  depots: undefined,
  depotNamesWithShortNames: [],
  isNew: undefined,
  edited: undefined,
  isAddressAutoCompleteLoading: false,
  addressAutoCompleteEntries: [],
  addressAutoCompleteEntry: undefined
};

const actionTypes = {
  EDIT: 'edit'
};

const actions: ActionTree<State, RootState> = {
  async get({ commit }) {
    const { data } = await ApiService.getDepots();
    commit('refresh', data);
  },
  addNew({ commit }) {
    commit('addNewDepot');
  },
  cancel({ commit }) {
    commit('cancel');
  },
  async save({ commit, dispatch }, value) {
    if (state.edited?.lengthOfTimeSlot && value) {
      state.edited.lengthOfTimeSlot = undefined;
    }
    if (state.edited?.openingHours && value) {
      state.edited.openingHours = undefined;
    }
    const oldDepot = state.depots?.find((depot) => depot.id === state.edited?.id);
    let answer: boolean | undefined = true;
    if (state.edited?.isActive === false && state.edited?.isActive !== oldDepot?.isActive) {
      answer = await confirm(
        'A depó inaktiválásával a hozzá kapcsolt gyárak is inaktiválódnak, ' +
          'a továbbiakban ezek nem fognak tudni új rendeléseket fogadni.<br/><br/>' +
          'Biztosan menti a változtatásokat?',
        {
          title: 'Depó inaktiválása',
          color: 'warning',
          width: 450,
          buttonTrueText: 'Mentés',
          buttonFalseText: 'Mégse'
        }
      );
    } else if (state.edited?.isActive === true && state.edited?.isActive !== oldDepot?.isActive) {
      answer = await confirm(
        'Figyelem!<br/>' +
          'A depó újraaktiválásával a hozzá kapcsolt gyárak ' +
          'nem aktiválódnak vissza automatikusan.',
        {
          title: 'Depó újraaktiválása',
          color: 'info',
          width: 450,
          buttonTrueText: 'Folytatás',
          buttonFalseText: ''
        }
      );
    }
    if (answer) {
      await ApiService.saveDepot(state.edited);
      dispatch('get');
      await dispatch('factories/get', null, { root: true });
      commit('cancel');
    }
  },
  edit({ commit }, id: string) {
    commit('setEdited', id);
  },
  selectAction({ dispatch }, { actionType, item }) {
    switch (actionType) {
      case actionTypes.EDIT: {
        dispatch('edit', item.id);
        break;
      }
    }
  },
  updateAddress({ commit }, value) {
    if (value) {
      commit('setEditedAddress', value);
    }
  },
  refreshAddressEntries: debounce(async function ({ commit }, value) {
    commit('setIsAddressAutoCompleteLoading', true);
    try {
      const response = await ApiService.locationSearch(value);
      const result: any = [];
      response.data.result.features.forEach((item: any) => {
        result.push({
          address: normalizeAddressString(value, item.properties),
          geometry: item.geometry
        });
      });
      commit('setAddressAutoCompleteEntries', result);
    } catch (ex) {
      commit('setAddressAutoCompleteEntries', []);
    } finally {
      commit('setIsAddressAutoCompleteLoading', false);
    }
  }, 300),

  setOpeningHours({ commit }, { openingHours }) {
    commit('setOpeningHours', openingHours);
  },

  setlengthOfTimeSlot({ commit }, value: number) {
    commit('setlengthOfTimeSlot', value);
  },

  setTimeSlotReservationLimit({ commit }, value: number) {
    commit('setTimeSlotReservationLimit', value);
  },

  addNewDock({ commit }) {
    commit('addNewDock');
  },

  async removeDock({ commit }, value: Dock) {
    const answer = await confirm('Biztosan törli a dokkot?', {
      title: 'Dokk törlése',
      width: 375
    });
    if (answer) {
      commit('removeDock', value);
    }
  },

  loadDefaults({ commit, rootGetters }) {
    commit('loadDefaults', {
      openingHours: rootGetters['basicSettings/openingHours'],
      lengthOfTimeSlot: rootGetters['basicSettings/lengthOfTimeSlot']
    });
  }
};

const mutations: MutationTree<State> = {
  refresh(state, depots: Depot[]) {
    state.depots = depots;
    for (const depot of depots) {
      if (depot.shortName) {
        state.depotNamesWithShortNames?.push({
          value: depot.shortName,
          text: `${depot.name} (${depot.shortName})`
        });
      }
    }
  },
  addNewDepot(state) {
    state.isNew = true;
    state.edited = {};
  },
  cancel(state) {
    state.isNew = undefined;
    state.edited = undefined;
  },
  setEdited(state, id: string) {
    state.isNew = false;
    state.edited = JSON.parse(JSON.stringify(state.depots?.filter((depot) => depot.id === id)[0]));
  },
  setEditedAddress(state, payload) {
    if (state.edited) {
      state.edited.address = payload.address;
      state.edited.geometry = payload.geometry;
    }
  },
  setAddressAutoCompleteEntries(state, addressAutoCompleteEntries: AddressAutoCompleteEntry[]) {
    state.addressAutoCompleteEntries = addressAutoCompleteEntries;
  },
  setIsAddressAutoCompleteLoading(state, isAddressAutoCompleteLoading: boolean) {
    state.isAddressAutoCompleteLoading = isAddressAutoCompleteLoading;
  },
  setAddressAutoCompleteEntry(state, addressAutoCompleteEntry: AddressAutoCompleteEntry) {
    state.addressAutoCompleteEntry = addressAutoCompleteEntry;
  },
  setOpeningHours(state, openingHours: OpeningHours) {
    if (state.edited) {
      state.edited.openingHours = openingHours;
    }
  },
  setlengthOfTimeSlot(state, lengthOfTimeSlot: number) {
    if (state.edited) {
      state.edited = { ...state.edited, lengthOfTimeSlot };
    }
  },

  setTimeSlotReservationLimit(state, timeSlotReservationLimit: number) {
    if (state.edited) {
      state.edited = { ...state.edited, timeSlotReservationLimit };
    }
  },

  addNewDock(state) {
    if (state.edited) {
      // adding the docks array if it doesn't exist and make it reactive
      Vue.set(state.edited, 'docks', state.edited.docks || []);
      state.edited.docks!.push({ name: '' });
    }
  },

  removeDock(state, dock: Dock) {
    if (state.edited?.docks) {
      const index = state.edited.docks.indexOf(dock, 0);
      if (index > -1) {
        state.edited.docks.splice(index, 1);
      }
    }
  },
  loadDefaults(state, payload) {
    if (state.edited) {
      state.edited.openingHours = payload.openingHours;
      state.edited.lengthOfTimeSlot = payload.lengthOfTimeSlot;

      state.edited = { ...state.edited };
    }
  },
  updateField
};

const getters: GetterTree<State, RootState> = {
  depots: (state) => state.depots,
  isNew: (state) => state.isNew,
  edited: (state) => state.edited,
  addressAutoCompleteEntries: (state) => state.addressAutoCompleteEntries,
  isAddressAutoCompleteLoading: (state) => state.isAddressAutoCompleteLoading,
  addressAutoCompleteEntry: (state) => state.addressAutoCompleteEntry,
  getShortNames: (state) => state.depots?.map((depot) => depot.shortName),
  getDocksWithDepotShortNames: (state) => {
    const docksWithShortNames: any[] = [];
    state.depots?.forEach((depot) => {
      depot.docks?.forEach((dock) => {
        docksWithShortNames.push({
          depotShortName: depot.shortName,
          id: dock.id,
          name: dock.name
        });
      });
    });
    return docksWithShortNames;
  },
  getField,
  sortedDepotNamesWithShortNames: (state) =>
    state.depotNamesWithShortNames?.sort((i, j) => (i.text < j.text ? -1 : i.text > j.text ? 1 : 0))
};

export const depots = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
