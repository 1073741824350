<template>
  <gli-text-field :disabled="!canEdit" v-bind="{ ...$attrs }" v-on="$listeners">
    <template>
      <slot name="default" />
    </template>
  </gli-text-field>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'GliEditorTextField',

  computed: {
    ...mapGetters('account', ['canEdit'])
  }
};
</script>
