import debounce from 'lodash.debounce';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { RootState, store } from '.';
import ApiService from '../services/ApiService';
import { PalletType } from '../../../shared/types/palletType';
import {
  ImportedOrder,
  ImportedOrderErrorMessage,
  ImportedOrderErrorTypes
} from '../../../shared/types/importedOrder';
import { createUploadedOrdersFromMappedRows, importOrdersFromXlsx } from '../helpers/xlsxImport';

export type addressResultEntry = {
  address: string;
};

type State = {
  file: File | null; // uploaded excel file's blob-type
  importedOrders: Array<ImportedOrder>;
  editedOrder?: ImportedOrder;
  addressAutoCompleteEntry?: addressResultEntry;
  addressAutoCompleteEntries: Array<addressResultEntry>;
  isAddressAutoCompleteLoading: boolean;
};

const state: State = {
  importedOrders: [],
  file: null,
  editedOrder: undefined,
  addressAutoCompleteEntry: undefined,
  addressAutoCompleteEntries: [],
  isAddressAutoCompleteLoading: false
};

const actions: ActionTree<State, RootState> = {
  updateExcelFile({ commit }, uploadedFile: File | null) {
    commit('updateFile', uploadedFile);
  },

  async importOrders({ commit, state }) {
    if (state.file) {
      const orders: Array<ImportedOrder> = await importOrdersFromXlsx(state.file);

      commit('setOrders', orders);
    }
  },

  cancelImporting({ commit }) {
    commit('resetImport');
  },

  deleteOrder({ commit }, temporaryCustomerId: number) {
    commit('deleteOne', temporaryCustomerId);
  },

  async saveOrders({ state, commit }) {
    const hasOrdersWithErrors = state.importedOrders.some((order) => order.errors.length > 0);
    if (!hasOrdersWithErrors) {
      await ApiService.saveImportedOrders(state.importedOrders);
      commit('resetImport');
    }
  },

  setEditedOrder({ commit }, order) {
    commit('setEditedOrder', JSON.parse(JSON.stringify(order)));
  },

  async saveEditedOrder({ commit, state, rootState }) {
    const index = state.importedOrders.findIndex(
      (order) => order.temporaryCustomerId === state.editedOrder?.temporaryCustomerId
    );

    commit('updateOrder', index);
    commit('clearEditedOrder');
    commit('updateTable');

    const orders = await createUploadedOrdersFromMappedRows(
      state.importedOrders,
      rootState.app.config?.xlsxHeaders?.ordersImport.requiredHeaders || [],
      rootState.app.config?.xlsxHeaders?.ordersImport.optionalHeaders || []
    );
    commit('setOrders', orders);
  },

  cancelEditingOrder({ commit }) {
    commit('clearEditedOrder');
  },

  refreshAddressEntries: debounce(async function ({ commit }, address) {
    commit('setIsAddressAutoCompleteLoading', true);
    try {
      const response = await ApiService.locationSearch(address);
      const result: Array<addressResultEntry> = [];
      response.data.result.features.forEach((item: Record<string, any>) => {
        result.push({
          address: item.properties.address
        });
      });
      commit('setAddressAutoCompleteEntries', result);
    } catch (ex) {
      commit('setAddressAutoCompleteEntries', []);
    } finally {
      commit('setIsAddressAutoCompleteLoading', false);
    }
  }, 300),

  clearAddressEntries({ commit }) {
    commit('setAddressAutoCompleteEntries', []);
  },

  updateAddress({ commit }, address) {
    if (address) {
      commit('setAddress', address);
    }
  },

  updateEditedOrderVehicle({ commit }, vehicleId: string) {
    commit('setEditedOrderVehicle', vehicleId);
  },

  setAddressAutoCompleteEntry({ commit }, properties) {
    commit('setAddressAutoCompleteEntry', properties);
  }
};

const mutations: MutationTree<State> = {
  resetImport(state) {
    state.importedOrders = [];
    state.file = null;
  },

  updateFile(state, file: File | null) {
    state.file = file;
  },

  setOrders(state, orders: Array<ImportedOrder>) {
    state.importedOrders = orders;
  },

  deleteOne(state, id: number) {
    state.importedOrders = state.importedOrders.filter((order) => order.temporaryCustomerId !== id);
  },

  setEditedOrder(state, order: ImportedOrder) {
    state.editedOrder = order;
  },

  updateOrder(state, index) {
    if (state.editedOrder && index !== undefined) {
      let newOriginalAddress: string | undefined = state.editedOrder.originalAddress;
      let newOriginalAddressPostalCode: string | undefined =
        state.editedOrder.originalAddressPostalCode;
      let newErrorsList: Array<ImportedOrderErrorMessage> = state.editedOrder.errors;
      if (
        state.editedOrder.geocodedAddress &&
        state.importedOrders[index].geocodedAddress !== state.editedOrder.geocodedAddress
      ) {
        newOriginalAddress = state.editedOrder.geocodedAddress;
        newOriginalAddressPostalCode = undefined;
        newErrorsList = newErrorsList.filter(
          (error) =>
            error.type !== ImportedOrderErrorTypes.CANNOT_BE_GEOCODED &&
            !(
              error.type === ImportedOrderErrorTypes.MISSING_REQUIRED_FIELD &&
              error.field === `address`
            )
        );
      }

      const palletTypes: Record<string, PalletType> = store.getters['app/palletTypes'];
      if (
        state.editedOrder.palletType &&
        Object.keys(palletTypes).includes(state.editedOrder.palletType)
      ) {
        newErrorsList = newErrorsList.filter(
          (error) =>
            !(
              error.type === ImportedOrderErrorTypes.INVALID_VALUE && error.field === `palletType`
            ) &&
            !(
              error.type === ImportedOrderErrorTypes.MISSING_REQUIRED_FIELD &&
              error.field === `palletType`
            )
        );
      }

      state.importedOrders[index] = {
        ...state.editedOrder,
        ...{
          originalAddress: newOriginalAddress,
          originalAddressPostalCode: newOriginalAddressPostalCode,
          errors: newErrorsList
        }
      };
    }
  },

  clearEditedOrder(state) {
    state.editedOrder = undefined;
  },

  updateTable(state) {
    state.importedOrders = [...state.importedOrders];
  },

  setAddressAutoCompleteEntries(state, addressEntries: Array<addressResultEntry>) {
    state.addressAutoCompleteEntries = addressEntries;
  },

  setIsAddressAutoCompleteLoading(state, isLoading: boolean) {
    state.isAddressAutoCompleteLoading = isLoading;
  },

  setAddress(state, payload: { address: string }) {
    if (state.editedOrder) {
      state.editedOrder.geocodedAddress = payload.address;
    }
  },

  setEditedOrderVehicle(state, vehicleId: string) {
    if (!state.editedOrder) {
      return;
    }

    //we can only choose a valid vehicle, so we can delete that kind of errors
    state.editedOrder.errors = state.editedOrder.errors.filter(
      (error) => error.type !== ImportedOrderErrorTypes.CAPACITY
    );
    state.editedOrder.vehicle = vehicleId;
  },

  setAddressAutoCompleteEntry(state, properties) {
    state.addressAutoCompleteEntry = properties;
  }
};

const getters: GetterTree<State, RootState> = {
  hasUploadedFileAcceptedFormat: (state): boolean | null =>
    // check if uploaded file is an Excel or open document file
    state.file &&
    [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'application/vnd.oasis.opendocument.spreadsheet'
    ].includes(state.file.type),

  addressIsNotValid: (state) => {
    const addressEntries = state.addressAutoCompleteEntries.map((entry) => entry.address);
    const addressIsInGeocodedList =
      state.editedOrder?.geocodedAddress &&
      addressEntries.includes(state.editedOrder?.geocodedAddress);

    return !addressIsInGeocodedList;
  }
};

export const ordersImport: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
