import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';

import ApiService from '../services/ApiService';
import { Freight, Pickup } from '../helpers/types/index';

type ExternalIdUpdate = {
  entity: string;
  externalIds: { [key: string]: string };
  orderId?: string;
  commissionedUnitId?: string;
};

export type ExternalId = {
  name: string;
  main: boolean;
  displayName: string;
  rolesAllowedToEdit: string[];
  freightFilter?: boolean;
};

type ExternalIdNames = {
  cargoExternalIds?: ExternalId[];
  freightExternalIds?: ExternalId[];
  orderExternalIds?: ExternalId[];
  orderItemExternalIds?: ExternalId[];
  pickupExternalIds?: ExternalId[];
};

export type State = {
  id?: string; // id for mongoId
  cargoExternalIds?: ExternalId[];
  freightExternalIds?: ExternalId[];
  orderExternalIds?: ExternalId[];
  orderItemExternalIds?: ExternalId[];
  pickupExternalIds?: ExternalId[];
  editedCargoExternalIds?: { [key: string]: string };
  original: ExternalIdNames;
};

const state: State = {
  cargoExternalIds: undefined,
  freightExternalIds: undefined,
  orderExternalIds: undefined,
  orderItemExternalIds: undefined,
  pickupExternalIds: undefined,
  editedCargoExternalIds: undefined,
  original: {
    cargoExternalIds: undefined,
    freightExternalIds: undefined,
    orderExternalIds: undefined,
    orderItemExternalIds: undefined,
    pickupExternalIds: undefined
  }
};

function getExternalIdsByEntity(entity: string): ExternalId[] | undefined {
  if (entity === 'cargo') {
    return state.cargoExternalIds;
  } else if (entity === 'freight') {
    return state.freightExternalIds;
  } else if (entity === 'order') {
    return state.orderExternalIds;
  } else if (entity === 'orderItem') {
    return state.orderItemExternalIds;
  } else if (entity === 'pickup') {
    return state.pickupExternalIds;
  }
}

const actions: ActionTree<State, RootState> = {
  async loadExternalIds({ commit }) {
    const { data }: { data: State } = await ApiService.getAllExternalIdNames();
    commit('refresh', data);
  },
  async newId({ commit }, payload: { entity: string }) {
    commit('addId', payload);
  },
  async removeId({ commit }, payload: { indexToRemove: number; entity: string }) {
    commit('removeId', payload);
  },
  async save({ dispatch }) {
    await ApiService.saveExternalIdNames(state);
    dispatch('loadExternalIds');
    dispatch('alert/success', 'A mentés sikeresen megtörtént!', { root: true });
  },
  async setOtherMainToFalse({ commit }, payload: { trueIndex: number; entity: string }) {
    commit('setOtherMainToFalse', payload);
  },
  async saveExternalIds({ dispatch }, newExternalIdData: ExternalIdUpdate) {
    if (newExternalIdData.entity === 'freight-cargo') {
      await ApiService.saveFreightCargoExternalIds(
        newExternalIdData.commissionedUnitId,
        newExternalIdData.orderId,
        newExternalIdData.externalIds
      );
    } else if (newExternalIdData.entity === 'pickup-cargo') {
      await ApiService.savePickupCargoExternalIds(
        newExternalIdData.commissionedUnitId,
        newExternalIdData.externalIds
      );
    }
    dispatch('loadExternalIds');
  },
  async setEditedCargoExternalIds(
    { commit },
    {
      orderId,
      commissionedUnitId,
      entity
    }: { orderId: string; commissionedUnitId: string; entity: string }
  ) {
    const result: Freight[] | Pickup[] = (
      entity === 'freight'
        ? await ApiService.getFreights({
            pagination: -1,
            filter: { freightId: commissionedUnitId },
            statuses: undefined
          })
        : await ApiService.getPickups({
            pagination: -1,
            filter: { pickupId: commissionedUnitId },
            statuses: undefined
          })
    ).data?.result;

    const cargoExternalIds =
      result[0]?.cargo?.find((cargoItem) => cargoItem.orderId === orderId)?.externalIds ?? {};

    commit('setEditedCargoExternalIds', cargoExternalIds);
  }
};

const mutations: MutationTree<State> = {
  refresh(state, payload: State) {
    Object.assign(state.original, JSON.parse(JSON.stringify(payload)));
    Object.assign(state, payload);
  },
  addId(state, payload: { entity: string }) {
    let isMain = false;
    if (getExternalIdsByEntity(payload.entity)?.length === 0) {
      isMain = true;
    }
    getExternalIdsByEntity(payload.entity)?.push({
      name: '',
      displayName: '',
      main: isMain,
      rolesAllowedToEdit: []
    });
  },
  removeId(state, payload: { indexToRemove: number; entity: string }) {
    const entityExternalIds = getExternalIdsByEntity(payload.entity);
    const deletedItem: ExternalId[] | undefined = entityExternalIds?.splice(
      payload.indexToRemove,
      1
    );
    if (deletedItem?.[0].main) {
      if (entityExternalIds?.length) {
        entityExternalIds[0].main = true;
      }
    }
  },
  setOtherMainToFalse(state, payload: { trueIndex: number; entity: string }) {
    getExternalIdsByEntity(payload.entity)?.forEach((externalId, index) => {
      if (index !== payload.trueIndex) {
        externalId.main = false;
      }
    });
  },
  setEditedCargoExternalIds(state, externalIds: { [key: string]: string }) {
    state.editedCargoExternalIds = externalIds;
  }
};

const getters: GetterTree<State, RootState> = {
  externalIds: () => (entity: string) => {
    return getExternalIdsByEntity(entity);
  },
  allExternalIds: (state) => state,
  dirty(state) {
    return (
      (state.original &&
        JSON.stringify(state.cargoExternalIds) !==
          JSON.stringify(state.original.cargoExternalIds)) ||
      JSON.stringify(state.freightExternalIds) !==
        JSON.stringify(state.original.freightExternalIds) ||
      JSON.stringify(state.orderExternalIds) !== JSON.stringify(state.original.orderExternalIds) ||
      JSON.stringify(state.orderItemExternalIds) !==
        JSON.stringify(state.original.orderItemExternalIds) ||
      JSON.stringify(state.pickupExternalIds) !== JSON.stringify(state.original.pickupExternalIds)
    );
  },
  getMainExternalId: () => (entity: string) => {
    return getExternalIdsByEntity(entity)?.find((externalId) => externalId.main === true) || {};
  },
  existsExternalId: () => (entity: string) => {
    return getExternalIdsByEntity(entity)?.length === 0 ? false : true;
  },
  getExternalIdsWithValues: () => (entity: string, externalIds: { [key: string]: string }) => {
    return getExternalIdsByEntity(entity)?.map((externalId) => {
      return {
        name: externalId.name,
        displayName: externalId.displayName,
        value: externalIds?.[externalId.name],
        rolesAllowedToEdit: externalId.rolesAllowedToEdit
      };
    });
  },
  getOrderExternalIdsWithFreightFilter: (state) =>
    state.orderExternalIds?.filter((orderExternalId) => orderExternalId.freightFilter)
};

export const externalIdNames = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
