import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';
import ApiService from '../services/ApiService';

export type Product = {
  id?: string;
  itemId?: string;
  name?: string;
  unit?: string;
  kgPerUnit?: number;
  kgPerPallet?: number;
  unitPerPallet?: number;
  palletType?: string;
};

type State = {
  editedProduct?: Product;
  products: Product[];
  isSaving: boolean;
};

const state: State = {
  editedProduct: undefined,
  products: [],
  isSaving: false
};

const actions: ActionTree<State, RootState> = {
  async loadProducts({ commit }) {
    const products: Product[] = (await ApiService.getProducts()).data;
    commit('refresh', products);
  },

  editProduct({ commit }, product: Product) {
    commit('edit', product);
  },

  async saveEditedProduct({ commit, dispatch }) {
    commit('isSaving', true);
    await ApiService.updateProduct(state.editedProduct);
    dispatch('loadProducts');
    commit('clear');
  },

  cancel({ commit }) {
    commit('clear');
  }
};

const mutations: MutationTree<State> = {
  refresh(state, products: Product[]) {
    state.products = products;
  },

  edit(state, product: Product) {
    state.editedProduct = product;
  },

  clear(state) {
    state.editedProduct = undefined;
    state.isSaving = false;
  },

  isSaving(state, isSaving: boolean) {
    state.isSaving = isSaving;
  }
};

const getters: GetterTree<State, RootState> = {};

export const products = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
