export enum DELIVERY_INTERVAL_TYPES {
  //  needed because it doesn't know that it is used
  // eslint-disable-next-line no-unused-vars
  CALENDAR_DAY = 'calendarDay',
  // eslint-disable-next-line no-unused-vars
  WORKDAY = 'workday'
}
export const deliveryIntervalTypes: {
  text: string;
  value: DELIVERY_INTERVAL_TYPES;
}[] = [
  {
    value: DELIVERY_INTERVAL_TYPES.CALENDAR_DAY,
    text: 'Naptári nap'
  },
  {
    value: DELIVERY_INTERVAL_TYPES.WORKDAY,
    text: 'Munkanap'
  }
];
