import axios from 'axios';

import config from '../helpers/configProvider';
import Api from './Api';

const pdfConfig = {
  headers: {
    Accept: 'application/pdf',
    'Content-Type': 'application/json'
  },
  responseType: 'arraybuffer'
};

const orderHeader = {
  headers: {
    'x-api-key': config.getEnv(config.enum.VUE_APP_X_API_KEY)
  }
};

let locationSearchCancelToken = undefined;
let wbStatisticsCancelToken = undefined;

export default {
  checkOrderId(orderId) {
    return Api().get('/api/orders/frontend/exists/' + encodeURIComponent(orderId));
  },

  getVersion() {
    return Api().get('/api/version');
  },

  getStyle() {
    return Api().get('/style', { headers: { 'Cache-Control': 'No-Cache' } });
  },

  getConfig() {
    return Api().get('/api/config');
  },

  // #region of baseConfig
  getBaseConfig() {
    return Api().get('/api/baseConfig');
  },

  setBaseConfig(cfg) {
    return Api().put('/api/baseConfig/' + cfg.id, cfg);
  },

  async ping(url, forwardTo) {
    let pingUrl = url + '/ping';
    if (forwardTo) {
      pingUrl += '?forwardTo=' + forwardTo + '/ping';
    }
    try {
      return (
        (await Api({ useInterceptors: false }).get('/api/baseConfig/ping?forwardTo=' + pingUrl))
          .status === 200
      );
    } catch (error) {
      return false;
    }
  },
  // #endregion of baseConfig

  // #region of products
  getProducts() {
    return Api().get('/api/products');
  },

  getProductsLoadStatus(jobId) {
    return Api().get(`/api/products/load-client-status/${jobId}`);
  },

  loadProducts(products) {
    return Api().post('/api/products/load-client', products);
  },

  updateProduct(product) {
    return Api().put('/api/products', product);
  },
  // #endregion of products

  getFactories() {
    return Api().get('/api/factories');
  },

  getDepots() {
    return Api().get('/api/depots');
  },

  saveFactory(factory) {
    if (factory.id) {
      return Api().put('/api/factories/' + factory.id, factory);
    } else {
      return Api().post('/api/factories', factory);
    }
  },

  saveDepot(depot) {
    if (depot.id) {
      return Api().put('/api/depots/' + depot.id, depot);
    } else {
      return Api().post('/api/depots', depot);
    }
  },

  getDepotGeometry(shortName) {
    return Api().get('/api/depots/geometry/' + encodeURIComponent(shortName));
  },

  locationSearch(query) {
    // cancel the previous pending search
    if (locationSearchCancelToken) {
      locationSearchCancelToken.cancel();
    }

    locationSearchCancelToken = axios.CancelToken.source();
    return Api({ useInterceptors: false }).get('/location/search', {
      params: { query, types: 'hnum,road,cos,admin,poi' },
      cancelToken: locationSearchCancelToken.token
    });
  },

  routePlan(query, keepWaypointsOrder) {
    if (keepWaypointsOrder) {
      return Api().post('/route-planner/route', query);
    } else {
      return Api().post('/route-planner/logistics', query);
    }
  },

  reverseGeocode(point) {
    return Api().get(`location/reversegeocode/${point.lng}/${point.lat}`);
  },

  getWeightApiRestrictions(coordinates) {
    return Api().post('/weight', { coordinates });
  },

  getVehicles(includeInactives) {
    if (includeInactives) {
      return Api().get('/api/vehicles', { params: { includeInactives: includeInactives } });
    } else {
      return Api().get('/api/vehicles');
    }
  },

  getActiveVehicles() {
    return Api().get('/api/vehicles/active');
  },

  getInactiveVehicles() {
    return Api().get('/api/vehicles/inactive');
  },

  getDefaultVehiclesByDeliveryToSite(deliveryToSite) {
    return Api().get('/api/vehicles/defaults', { params: { deliveryToSite } });
  },

  saveVehicle(vehicle) {
    if (vehicle.id) {
      return Api().put('/api/vehicles/' + vehicle.id, vehicle);
    } else {
      return Api().put('/api/vehicles', vehicle);
    }
  },

  getTransporters(params) {
    return Api().get('/api/transporters', { params });
  },

  saveTransporter(transporter) {
    if (transporter.id) {
      return Api().put('/api/transporters/' + transporter.id, transporter);
    } else {
      return Api().post('/api/transporters', transporter);
    }
  },

  sendOffersToTransporters(transporterIds, freightId, replyDeadlineDate, commentFromLogistics) {
    return Api().post('/api/transporters/offers', {
      transporterIds,
      freightId,
      replyDeadlineDate,
      commentFromLogistics
    });
  },

  saveOrder(newOrder, options) {
    return Api().post('/api/orders', newOrder, { ...orderHeader, ...options });
  },

  updateOrder(order, orderId) {
    return Api().put('/api/orders/frontend/' + encodeURIComponent(orderId), order);
  },

  getOrderByOrderId(orderId) {
    return Api().get('/api/orders/frontend/' + encodeURIComponent(orderId));
  },

  getOrders(pagination, filter) {
    const params = { ...pagination, ...filter };
    return Api().get('/api/orders/frontend', { params });
  },

  generateUniqueIds(options) {
    const params = { ...options };
    return Api().get('/api/orders/frontend/generate/ids', { params });
  },

  getOrdersByOrderIds(params) {
    return Api().get(`/api/orders/frontend/order-ids`, { params });
  },

  getOrdersByDateAndOrderType(date, orderType) {
    return Api().get(`/api/orders/frontend/date/${date}/${orderType}`);
  },

  checkOrderChanges(params) {
    return Api().get('/api/orders/frontend/check-changes/', { params });
  },

  getOrderDetails(orderId) {
    return Api().get('/api/orders/frontend/order-details/' + encodeURIComponent(orderId));
  },

  checkOrdersProcessing(jobId) {
    return Api().get('/api/orders/frontend/check-processing', { params: { jobId } });
  },

  autoOrdersProcessing(on) {
    return Api().put('/api/orders/frontend/auto-processing', { on });
  },

  getAutoOrdersProcessing() {
    return Api().get('/api/orders/frontend/auto-processing');
  },

  deleteOrder(orderId) {
    return Api().delete('/api/orders/frontend/' + encodeURIComponent(orderId));
  },

  getAllCustomerNames() {
    return Api().get('/api/orders/frontend/customers');
  },

  closeOrder(orderId) {
    return Api().put('/api/orders/frontend/close/' + encodeURIComponent(orderId));
  },

  reopenOrder(orderId) {
    return Api().put('/api/orders/frontend/reopen/' + encodeURIComponent(orderId));
  },

  geocodeAPs(addresses) {
    return Api().post(`/api/orders/frontend/geocode`, { addresses });
  },

  saveImportedOrders(orders) {
    return Api().post('/api/orders/frontend/excelimport', { orders });
  },

  getFreights({ pagination, filter, statuses }) {
    const params = { ...pagination, ...filter, statuses };
    return Api().get('/api/freights', { params });
  },

  getFreightsForOrganizer(query) {
    return Api().get('/api/freights/query-organizer', { params: query });
  },

  getFreightsForTimeSlotsView(query) {
    return Api().get('/api/freights/query-time-slots', { params: query });
  },

  getFreightsByDate(date) {
    return Api().get('/api/freights/date/' + date);
  },

  getFreightGeometry(id) {
    return Api().get('/api/freights/geometry/' + id);
  },

  getFreightWaypoints(freightId) {
    return Api().get('/api/freights/waypoints/' + freightId);
  },

  saveFreightWaypoints(freightId, waypoints) {
    return Api().patch('/api/freights/waypoints/' + freightId, { waypoints });
  },

  getOriginalWaypoints(freightId) {
    return Api().get(`/api/freights/waypoints/original/${freightId}`);
  },

  getFreightStatistics(params) {
    return Api().get('/api/statistics/freights', { params });
  },

  getFreightStatisticsDetails(params) {
    return Api().get('/api/statistics/freights/details', { params });
  },

  // TODO WB
  getWBStatistics(table, params, useCancelToken = true) {
    if (wbStatisticsCancelToken && useCancelToken) {
      wbStatisticsCancelToken.cancel();
    }
    wbStatisticsCancelToken = axios.CancelToken.source();

    return Api().get(`/api/statistics/wienerberger/${table}`, {
      params,
      cancelToken: wbStatisticsCancelToken.token
    });
  },

  cancelFreight(freightId) {
    return Api().put('/api/freights/cancel/', { freightIds: [freightId] });
  },

  uncancelFreight(freightId) {
    return Api().put('/api/freights/uncancel/', { freightIds: [freightId] });
  },

  checkFreightChanges(type, params) {
    return Api().get(`/api/freights/check-changes/${type}`, { params });
  },

  saveFreight(id, version, freight) {
    return Api().put(`/api/freights/${id}/${version}`, freight);
  },

  setFreightStatus(id, version, status) {
    return Api().put(`/api/freights/${id}/${version}/status`, status);
  },

  getFreightStatuses() {
    return Api().get('/api/freights/statuses');
  },

  getFreightBillStatuses() {
    return Api().get('/api/freight-bill/statuses');
  },

  setNextFreightStatus(id, version) {
    return Api().put(`/api/freights/${id}/${version}/next-status`);
  },

  setFreightDockAndTimeSlot(id, version, dock, timeSlot) {
    return Api().put(`/api/freights/${id}/${version}/timeSlot`, { dock, timeSlot });
  },

  separateFreight(freightId, orderId) {
    return Api().post('/api/freights/separate-freight', { freightId, orderId });
  },

  splitFreightCargo(freightId, version, cargo, reason, cargoSplitType) {
    return Api().post(`/api/freights/split-cargo/${freightId}`, {
      cargo,
      version,
      reason,
      cargoSplitType
    });
  },

  downloadFreightPdf(id) {
    return Api().get('/api/freights/pdf/' + id, pdfConfig);
  },

  downloadPackingPlanPdf(id) {
    return Api().get('/api/freights/packing-plan-pdf/' + id, pdfConfig);
  },

  downloadAssignmentPdf(assignment) {
    return Api().get('/api/freights/assignment-pdf/' + assignment.id, {
      params: assignment,
      ...pdfConfig
    });
  },

  getFreightDetails(freightId) {
    return Api().get('/api/freights/freight-details/' + freightId);
  },

  calculateNewFreights(postData) {
    return Api().post('/api/freights/process-orders', postData);
  },

  cancelFreightsCalculation(jobId) {
    return Api().post('/api/freights/cancel-processing', { jobId });
  },

  setFreightBillingData(freightId, billData) {
    return Api().put(`/api/freight-bill/${freightId}`, billData);
  },

  setFreightComment(freightId, comment) {
    return Api().put(`/api/freights/comment/${freightId}`, { comment });
  },

  acceptBill(freightId) {
    return Api().put(`/api/freight-bill/${freightId}/accept`);
  },

  acceptMultipleBills(freightIds) {
    return Api().put('/api/freight-bill/batch/accept', { freightIds });
  },

  declineBill(freightId) {
    return Api().put(`/api/freight-bill/${freightId}/decline`);
  },

  declineMultipleBills(freightIds) {
    return Api().put('/api/freight-bill/batch/decline', { freightIds });
  },

  undoBill(freightId) {
    return Api().put(`/api/freight-bill/${freightId}/undo`);
  },

  setCostCentre(freightId, costCentre) {
    return Api().put(`/api/freight-bill/${freightId}/cost-centre`, costCentre);
  },

  getCostCentreDescriptions(statuses) {
    return Api().get('/api/freight-bill/cost-centre-descriptions', { params: { statuses } });
  },

  saveBatchBill(billData, freightIds) {
    return Api().put('/api/freight-bill/batch', { billData, freightIds });
  },

  getCalendar() {
    return Api().get('/api/calendar');
  },

  queryCalendar(query) {
    return Api().get('/api/calendar/query', { params: query });
  },

  saveCalendar(calendar) {
    if (calendar.id) {
      return Api().put('/api/calendar/' + calendar.id, calendar);
    } else {
      return Api().post('/api/calendar', calendar);
    }
  },

  deleteCalendar(id) {
    return Api().delete('/api/calendar/' + id);
  },

  deleteFreightCostsByFactoryId(id) {
    return Api().delete('/api/freight-costs/factory/' + id);
  },

  deleteFreightCost(id) {
    return Api().delete('/api/freight-costs/' + id);
  },

  getFreightCostsByFactoryId(factoryId) {
    if (typeof factoryId !== 'string') {
      return Api().get('/api/freight-costs');
    } else {
      return Api().get('/api/freight-costs', { params: { factoryId } });
    }
  },

  saveFreightCost(costData) {
    if (costData.id) {
      return Api().put('/api/freight-costs/' + costData.id, costData);
    } else {
      return Api().post('/api/freight-costs', costData);
    }
  },

  getPalletFees() {
    return Api().get('/api/pallet-fees');
  },

  savePalletFee(palletFee) {
    if (palletFee.id) {
      return Api().put('/api/pallet-fees/' + palletFee.id, palletFee);
    } else {
      return Api().post('/api/pallet-fees', palletFee);
    }
  },

  deletePalletFee(id) {
    return Api().delete('/api/pallet-fees/' + id);
  },

  getWaypointCosts() {
    return Api().get('/api/waypoint-costs');
  },

  saveWaypointCost(costData) {
    if (costData.id) {
      return Api().put('/api/waypoint-costs/' + costData.id, costData);
    } else {
      return Api().post('/api/waypoint-costs', costData);
    }
  },

  deleteWaypointCost(id) {
    return Api().delete('/api/waypoint-costs/' + id);
  },

  getAdminAreas({ adminType }) {
    if (adminType === 'city') {
      return Api().get('api/adminareamap/cities.geojson');
    } else {
      return Api().get('api/adminareamap/counties.geojson');
    }
  },

  getOffers() {
    return Api().get('api/offers');
  },

  getOfferDetails(id) {
    return Api().get('api/offers/details/' + id);
  },

  acceptOffer(offer) {
    return Api().post('api/offers/accept/' + offer.id, { offer });
  },

  rejectOffer(offer) {
    return Api().post('api/offers/reject/' + offer.id, { offer });
  },

  selectTransporter({ freightId, transporterId }) {
    return Api().post(`api/freights/${freightId}/set-transporter`, { transporterId });
  },

  // module apis
  getActiveModules() {
    return Api().get('api/modules/active');
  },

  getModules() {
    return Api().get('api/modules');
  },

  saveModule(module) {
    return Api().put('/api/modules/' + module.id, module);
  },

  getEvents(filter) {
    return Api().get('api/events', { params: { ...filter } });
  },

  // cargo space display endpoints
  /**
   * @param {string} freightId The freight ID to fetch images for.
   */
  getCargoSpaceImages(freightId) {
    return Api().get('api/cargoplan/getsvg', { params: { freightId } });
  },

  // timeSlot apis
  getTimeSlots(depotShortName, date) {
    return Api().get('/api/timeSlot', { params: { depotShortName: depotShortName, date: date } });
  },

  getFreeTimeSlots({
    commissionedUnitId,
    commissionedUnitType,
    depotShortName,
    date,
    dockId,
    timeSlotWidth
  }) {
    return Api().get('/api/timeSlot/free/time', {
      params: {
        commissionedUnitId,
        commissionedUnitType,
        depotShortName,
        date,
        dockId,
        timeSlotWidth
      }
    });
  },

  getFreeDocks({
    commissionedUnitId,
    commissionedUnitType,
    depotShortName,
    date,
    timeSlot,
    timeSlotWidth
  }) {
    return Api().get('/api/timeSlot/free/dock', {
      params: {
        commissionedUnitId,
        commissionedUnitType,
        depotShortName,
        date,
        timeSlot,
        timeSlotWidth
      }
    });
  },

  getFreeTimeSlotsAndDocks({
    commissionedUnitId,
    commissionedUnitType,
    depotShortName,
    date,
    timeSlot,
    dockId,
    timeSlotWidth
  }) {
    return Api().get('/api/timeSlot/free/time-dock', {
      params: {
        commissionedUnitId,
        commissionedUnitType,
        depotShortName,
        date,
        dockId,
        timeSlot,
        timeSlotWidth
      }
    });
  },

  getAllFreeTimeSlotsAndDocks({
    commissionedUnitId,
    commissionedUnitType,
    depotShortName,
    date,
    timeSlotWidth
  }) {
    return Api().get('/api/timeSlot/free/all-time-dock', {
      params: { commissionedUnitId, commissionedUnitType, depotShortName, date, timeSlotWidth }
    });
  },

  getAllDocks() {
    return Api().get('/api/depots/docks');
  },

  // #region externalIds
  getAllExternalIdNames() {
    return Api().get('/api/external-id-names');
  },

  saveExternalIdNames(externalIdNames) {
    return Api().put('/api/external-id-names', externalIdNames);
  },

  saveFreightCargoExternalIds(freightId, orderId, externalIds) {
    return Api().put(`api/freights/cargo-external-id/${freightId}/${orderId}`, externalIds);
  },

  savePickupCargoExternalIds(pickupId, externalIds) {
    return Api().put(`api/pickups/cargo-external-id/${pickupId}`, externalIds);
  },
  // #endregion of externalIds

  // #region of fleet

  createFleetVehicle(fleetVehicle) {
    return Api().post('api/fleet', fleetVehicle);
  },

  getFleet(transporterId) {
    return Api().get('api/fleet', { params: { transporterId } });
  },

  deleteFleetVehicle(fleetVehicleId) {
    return Api().delete(`/api/fleet/${fleetVehicleId}`);
  },

  updateFleetVehicle(fleetVehicle) {
    return Api().put(`/api/fleet`, fleetVehicle);
  },

  // #endregion of fleet

  // #region of pickups

  calculateNewPickups(postData) {
    return Api().post('/api/pickups/process-orders', postData);
  },

  getPickups({ pagination, filter, statuses }) {
    const params = { ...pagination, ...filter, statuses };
    return Api().get('/api/pickups', { params });
  },

  checkPickupChanges(type, params) {
    return Api().get(`/api/pickups/check-changes/${type}`, { params });
  },

  splitPickupCargo(pickupId, cargo, reason, cargoSplitType) {
    return Api().post(`/api/pickups/split-cargo/${pickupId}`, { cargo, reason, cargoSplitType });
  },

  savePickup(id, version, pickup) {
    return Api().put(`/api/pickups/${id}/${version}`, pickup);
  },

  getPickupStatuses() {
    return Api().get('/api/pickups/statuses');
  },

  setPickupStatus(id, version, status) {
    return Api().put(`/api/pickups/${id}/${version}/status`, status);
  },

  getPickupDetails(pickupId) {
    return Api().get('/api/pickups/pickup-details/' + pickupId);
  },

  getPickupsForOrganizer(query) {
    return Api().get('/api/pickups/query-organizer', { params: query });
  },

  getPickupsForTimeSlotsView(query) {
    return Api().get('/api/pickups/query-time-slots', { params: query });
  },

  setPickupDockAndTimeSlot(id, version, dock, timeSlot) {
    return Api().put(`/api/pickups/${id}/${version}/timeSlot`, { dock, timeSlot });
  },

  setNextPickupStatus(id, version) {
    return Api().put(`/api/pickups/${id}/${version}/next-status`);
  },

  cancelPickup(pickupId) {
    return Api().put('/api/pickups/cancel/', { pickupIds: [pickupId] });
  },

  uncancelPickup(pickupId) {
    return Api().put('/api/pickups/uncancel/', { pickupIds: [pickupId] });
  },

  // #endregion of pickups

  // #region capacityEstimate
  getCapacityEstimates({ pagination, filter }) {
    const params = { ...pagination, ...filter };
    return Api().get('/api/capacity-estimate', { params });
  },

  createCapacityEstimate(capacityEstimate) {
    return Api().post('/api/capacity-estimate', capacityEstimate);
  },

  updateCapacityEstimate(capacityEstimate) {
    return Api().put('/api/capacity-estimate', capacityEstimate);
  },

  deleteCapacityEstimate(id) {
    return Api().delete(`/api/capacity-estimate/${id}`);
  },

  checkCapacityEstimateChanges(params) {
    return Api().get('/api/capacity-estimate/check-changes', { params });
  }

  // #endregion capacityEstimate
};
