export enum ImportedOrderErrorTypes {
  ALREADY_EXISTS_IN_DATABASE,
  CANNOT_BE_GEOCODED,
  CAPACITY,
  DATE_IN_PAST,
  DUPLICATED_VALUE,
  INVALID_DATE,
  INVALID_VALUE,
  IS_NOT_POSITIVE,
  IS_NOT_POSITIVE_INTEGER,
  IS_NOT_WORKDAY,
  MISSING_REQUIRED_FIELD,
  MORE_THAN_ONE_VALUE,
  IS_NOT_VALID_VEHICLE
}
export type ImportedOrderErrorMessage = {
  type: (typeof ImportedOrderErrorTypes)[keyof typeof ImportedOrderErrorTypes];
  message: string;
  field: string;
};

export type ImportedOrder = {
  temporaryCustomerId: number;
  customerNumber?: number;
  idNumber?: string;
  customerName?: string;
  deliveryDate?: Date;
  originalAddressPostalCode?: string;
  originalAddress?: string;
  geocodedAddress?: string;
  palletsCount?: number;
  sumWeight?: number;
  comment?: string;
  palletType?: string;
  vehicle?: string;
  errors: Array<ImportedOrderErrorMessage>;
};
