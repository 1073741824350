import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

import { RootState, store } from '.';
import { Freight, Pallet, Pickup } from '../helpers/types/index';
import ApiService from '../services/ApiService';

type transportStatus = {
  description?: string;
  text?: string;
  value?: string;
};

type PackedDownOrderItem = {
  itemId: string;
  orderId: string;
  orderItemId: string;
  weight: number;
  quantity: number;
  packedDownQuantity: number;
  transportStatus?: string;
};

export type State = {
  splitCargo?: Freight | Pickup;
  selectedStatus?: transportStatus;
  // eslint-disable-next-line max-len
  selectedCargoSplitType: (typeof store.state.app.enums.cargoSplitType)[keyof typeof store.state.app.enums.cargoSplitType];
  // table content, depending on selected split type
  cargoSplitListItems: Pallet[] | PackedDownOrderItem[];
};

const state: State = {
  splitCargo: undefined,
  selectedStatus: undefined,
  selectedCargoSplitType: undefined,
  cargoSplitListItems: []
};

const actions: ActionTree<State, RootState> = {
  editCargo({ commit, dispatch }, splitCargo) {
    commit('edit', splitCargo);
    dispatch('setCargoSplitListItems');
  },

  cancelCargoSplit({ commit }) {
    commit('resetForm');
    commit('cancel');
  },

  saveSplitCargo({ dispatch, rootState, state }) {
    if (state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byPallet) {
      (state.cargoSplitListItems as Pallet[]).forEach((_pallet: Pallet) => {
        _pallet.transportStatus = _pallet.selected
          ? state.selectedStatus?.value
          : rootState.app.enums.allTransportStatus.unknown;
      });
    } else if (state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byQuantity) {
      (state.cargoSplitListItems as PackedDownOrderItem[]).forEach(
        (_packedDownOrderItem: PackedDownOrderItem) => {
          _packedDownOrderItem.transportStatus =
            _packedDownOrderItem.packedDownQuantity > 0
              ? state.selectedStatus?.value
              : rootState.app.enums.allTransportStatus.unknown;
        }
      );
    }

    let response = {};
    if ((state.splitCargo as Freight).freightId) {
      response = ApiService.splitFreightCargo(
        (state.splitCargo as Freight).freightId,
        (state.splitCargo as Freight).__v,
        state.cargoSplitListItems,
        state.selectedStatus?.text,
        state.selectedCargoSplitType
      );
    } else if ((state.splitCargo as Pickup).pickupId) {
      response = ApiService.splitPickupCargo(
        (state.splitCargo as Pickup).pickupId,
        state.cargoSplitListItems,
        state.selectedStatus?.text,
        state.selectedCargoSplitType
      );
    }

    dispatch('cancelCargoSplit');
    return response;
  },

  addCargoSplitPalletToSelected(
    { commit },
    { palletId, selectValue }: { palletId: number; selectValue: boolean }
  ) {
    commit('setPalletSelection', { palletId, selectValue });
  },

  addCargoSplitPackedDownUnitQuantity(
    { commit },
    { index, quantity }: { index: number; quantity: number }
  ) {
    commit('setPackedDownQuantity', { index, quantity });
  },

  setCargoSplitListItems: ({ commit, rootState, state }) => {
    if (state.splitCargo && state.splitCargo.cargo) {
      if (state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byPallet) {
        commit('setListItems', {
          listItems: JSON.parse(JSON.stringify(state.splitCargo.cargo)),
          cargoSplitTypes: rootState.app.enums.cargoSplitType
        });
      } else if (state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byQuantity) {
        const itemsByQuantity: Record<string, string>[] = [];
        state.splitCargo.cargo.forEach((_pallet) => {
          const pallet = JSON.parse(JSON.stringify(_pallet));

          if (
            itemsByQuantity.every(
              (item) =>
                !(
                  item.orderId === pallet.orderId &&
                  item.orderItemId === pallet.orderItemId &&
                  item.orderItemMainExternalId === pallet.orderItemMainExternalId
                )
            )
          ) {
            pallet.cost = undefined;
            pallet.palletId = undefined;
            itemsByQuantity.push(pallet);
          } else {
            const index = itemsByQuantity.findIndex(
              (item) =>
                item.orderId === pallet.orderId &&
                item.orderItemId === pallet.orderItemId &&
                item.orderItemMainExternalId === pallet.orderItemMainExternalId
            );

            itemsByQuantity[index].kg += pallet.kg;
            itemsByQuantity[index].quantity += pallet.quantity;
          }
        });

        commit('setListItems', {
          listItems: itemsByQuantity,
          cargoSplitTypes: rootState.app.enums.cargoSplitType
        });
      }
    } else {
      commit('setListItems', {
        listItems: [],
        cargoSplitTypes: rootState.app.enums.cargoSplitType
      });
    }
  }
};

const mutations: MutationTree<State> = {
  edit(state, payload) {
    state.splitCargo = payload;
    state.selectedCargoSplitType = store.state.app.enums.cargoSplitType.byPallet;
  },

  setListItems(
    state,
    {
      listItems,
      cargoSplitTypes
    }: { listItems: Pallet[] | PackedDownOrderItem[]; cargoSplitTypes: Record<string, string> }
  ) {
    listItems.forEach((listItem: Pallet | PackedDownOrderItem) => {
      if (state.selectedCargoSplitType === cargoSplitTypes.byPallet) {
        (listItem as Pallet).selected = false;
        // Using this to prevent warnings in browsers console, because Vue/Vuex
        // doesn't really change the array at the end of the function.
        // It rather merges the existing object with the new one at the same index position.
        // The result of this behaviour is packedDownQuantity = NaN when splitting by pallet
        // and undefined cost, palletId, etc. when splitting by quantity
        // This phenomenon doesn't cause any errors but is very annoying
        (listItem as PackedDownOrderItem).packedDownQuantity = 0;
      } else if (state.selectedCargoSplitType === cargoSplitTypes.byQuantity) {
        (listItem as PackedDownOrderItem).packedDownQuantity = 0;
      }
    });
    state.cargoSplitListItems = listItems;
  },

  setPalletSelection(state, { palletId, selectValue }: { palletId: number; selectValue: boolean }) {
    const cargoSplitListItem = (state.cargoSplitListItems as Array<Pallet>).find(
      (cargoSplitListItem) => cargoSplitListItem.palletId === palletId
    );
    if (cargoSplitListItem) {
      cargoSplitListItem.selected = selectValue;
    }
  },

  setPackedDownQuantity(state, { index, quantity }: { index: number; quantity: number }) {
    if (quantity > (state.cargoSplitListItems[index] as PackedDownOrderItem).quantity) {
      // if passed quantity is greater than max --> set to max
      (state.cargoSplitListItems[index] as PackedDownOrderItem).packedDownQuantity = (
        state.cargoSplitListItems[index] as PackedDownOrderItem
      ).quantity;
    } else if (!quantity || quantity < 0) {
      // if passed quantity is less than 0, or not set (deleted from input field) --> set to 0
      (state.cargoSplitListItems[index] as PackedDownOrderItem).packedDownQuantity = 0;
    } else {
      // if passed quantity is in valid range (from 0 to max order item quantity)
      (state.cargoSplitListItems[index] as PackedDownOrderItem).packedDownQuantity = quantity;
    }
  },

  cancel(state) {
    state.splitCargo = undefined;
    state.cargoSplitListItems = [];
  },

  resetForm(state) {
    state.selectedStatus = undefined;
  },

  updateField
};

const getters: GetterTree<State, RootState> = {
  getField
};

export const splitCargo: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
