<template>
  <v-text-field
    v-bind="{ maxlength: DEFAULT_MAX_LENGTH, ...$attrs, value, step: setStep }"
    v-on="$listeners"
    @keypress="numFormatter"
  >
    <template>
      <slot name="default" />
    </template>

    <!-- Pass on all named slots -->
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

    <!-- Pass on all scoped slots -->
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-text-field>
</template>

<script>
export default {
  name: 'GliTextField',
  props: ['value'],
  data() {
    return {
      DEFAULT_MAX_LENGTH: 200,
      numberFormatterRegex: undefined
    };
  },
  mounted() {
    if (this.$attrs.numberType?.toLowerCase() === 'integer') {
      if (this.$attrs.min < 0) {
        this.numberFormatterRegex = new RegExp('^-?[0-9]', 'i');
      } else {
        this.numberFormatterRegex = new RegExp('[0-9]', 'i');
      }
    } else {
      if (this.$attrs.min < 0) {
        this.numberFormatterRegex = new RegExp('^-?[0-9,]', 'i');
      } else {
        this.numberFormatterRegex = new RegExp('[0-9,]', 'i');
      }
    }
  },
  methods: {
    numFormatter(event) {
      if (this.$attrs.type?.toLowerCase() === 'number') {
        const keyPressed = event.key;
        if (!keyPressed.match(this.numberFormatterRegex)) {
          event.preventDefault();
        }
      }
    },
    setStep() {
      if (this.$attrs.step && this.$attrs.numberType?.toLowerCase() === 'integer') {
        return Math.ceil(this.$attrs.step);
      }
      return undefined;
    }
  },
  watch: {
    value() {
      if (this.$attrs.type?.toLowerCase() === 'number') {
        this.$emit('input', Number(this.value));
      } else {
        this.$emit('input', this.value);
      }
    }
  }
};
</script>
