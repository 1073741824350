import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import { store } from '../store';

//#region helper functions
function isSuperAdmin(role: string): boolean {
  return role === store.state.app.constants.ROLES.SUPER_ADMIN;
}

function isAdmin(role: string): boolean {
  return store.state.app.constants.ADMIN_ROLES.includes(role);
}

function isViewer(role: string): boolean {
  return store.state.app.constants.VIEWER_ROLES.includes(role);
}

function isEditor(role: string): boolean {
  return store.state.app.constants.EDITOR_ROLES.includes(role);
}

function isExtendedEditor(role: string): boolean {
  return store.state.app.constants.EXTENDED_EDITOR_ROLES.includes(role);
}

function isLogisticsUser(role: string): boolean {
  return store.state.app.constants.LOGISTICS_ROLES.includes(role);
}

function isProductionUser(role: string): boolean {
  return store.state.app.constants.PRODUCTION_ROLES.includes(role);
}

function isTransporterUser(role: string): boolean {
  return store.state.app.constants.TRANSPORTER_ROLES.includes(role);
}

function canReserveTimeSlot(role: string): boolean {
  if (
    role === store.state.app.constants.ROLES.TRANSPORTER_MANAGER &&
    store.getters['module/isTimeSlotModuleActive']
  ) {
    return true;
  }
  return false;
}

function canEditDeliveryDate(role: string): boolean {
  if (
    role === store.state.app.constants.ROLES.TRANSPORTER_MANAGER &&
    store.getters['module/canTransporterSetDeliveryDate']
  ) {
    return true;
  }
  return false;
}

function canEditWaypointsOnFreight(role: string): boolean {
  if (isEditor(role) && store.getters['module/isEditWaypointsOnFreightModuleActive']) {
    return true;
  }
  return false;
}

function canFinalizeFreight(role: string): boolean {
  if (
    (isAdmin(role) || role === store.state.app.constants.ROLES.TRANSPORTER_MANAGER) &&
    store.getters['module/isFinalizeFreightModuleActive']
  ) {
    return true;
  }
  return false;
}

function canEditTruckLicensePlateNumberAndDriverName(role: string): boolean {
  if (isEditor(role) || role === store.state.app.constants.ROLES.TRANSPORTER_MANAGER) {
    return true;
  }
  return false;
}

function canAccessFreightBills(role: string): boolean {
  if (
    !store.getters['module/isBillingModuleActive'] ||
    (!isAdmin(role) && !isLogisticsUser(role))
  ) {
    return false;
  }
  return true;
}

function canAccessCapacityEstimate(role: string): boolean {
  if (
    store.getters['module/isCapacityEstimateModuleActive'] &&
    (isLogisticsUser(role) || isAdmin(role))
  ) {
    return true;
  }
  return false;
}

function canAccessTransporterCapacityEstimate(role: string): boolean {
  if (store.getters['module/isCapacityEstimateModuleActive'] && isTransporterUser(role)) {
    return true;
  }
  return false;
}

function canAccessPickups(role: string): boolean {
  return (
    !store.getters['module/isPickupModuleActive'] ||
    (!isAdmin(role) && !isProductionUser(role) && !isLogisticsUser(role))
  );
}

function getEditableFieldList({
  editableFields,
  modifications,
  entity,
  role
}: {
  editableFields: string[];
  modifications: any;
  entity: 'action' | 'freight' | 'order' | 'orderItem';
  role: string;
}): string[] {
  if (!editableFields) {
    return [];
  }
  let finalEditableList = JSON.parse(JSON.stringify(editableFields));
  let entityKey = entity;
  if (entity === 'orderItem') {
    entityKey = 'order';
  }
  if (!modifications || !modifications[entityKey]) {
    return finalEditableList;
  }
  const extraRules = modifications[entityKey];
  if (extraRules.enable && extraRules.enable[role]) {
    if (entity === 'orderItem') {
      const orderItemRules = extraRules.enable[role].find(
        (field: any) => typeof field === 'object' && Object.keys(field)[0] === 'orderItems'
      );
      if (orderItemRules) {
        finalEditableList.push(...orderItemRules.orderItems);
      }
    } else {
      finalEditableList.push(...extraRules.enable[role]);
    }
  }
  if (extraRules.disable && extraRules.disable[role]) {
    if (entity === 'orderItem') {
      const orderItemRules = extraRules.disable[role].find(
        (field: any) => typeof field === 'object' && Object.keys(field)[0] === 'orderItems'
      );
      if (orderItemRules) {
        finalEditableList = finalEditableList.filter(
          (field: string) => !orderItemRules.orderItems.includes(field)
        );
      }
    } else {
      finalEditableList = finalEditableList.filter(
        (field: string) => !extraRules.disable[role].includes(field)
      );
    }
  }
  return finalEditableList;
}
//#endregion

// #region - action types & subjects
type menuActions =
  | 'accessBackToHomepage'
  | 'accessBaseData'
  | 'accessBasicSettings'
  | 'accessCalendarSettings'
  | 'accessCapacityEstimate'
  | 'accessDepotsAndFactories'
  | 'accessEvents'
  | 'accessFleet'
  | 'accessFreightBills'
  | 'accessFreightFees'
  | 'accessFreightOrganizer'
  | 'accessFreightPlanner'
  | 'accessFreightStatistics'
  | 'accessMap'
  | 'accessModuleSettings'
  | 'accessOffers'
  | 'accessPickups'
  | 'accessProducts'
  | 'accessRoutePlanner'
  | 'accessTestOrders'
  | 'accessTimeSlots'
  | 'accessTransporterCapacityEstimate'
  | 'accessTransporterEvents'
  | 'accessTransporterFleet'
  | 'accessTransporterFreights'
  | 'accessTransporters'
  | 'accessUserList'
  | 'accessVehicles'
  | 'seeAdminLogo'
  | 'seeNewOrders'
  | 'seeManualCombineResults'
  | 'seeOrdersProcessing'
  | 'seeSmsError';

type routeActions =
  | 'accessAdminPages'
  | 'accessCapacityEstimate'
  | 'accessEntranceSupervisorPages'
  | 'accessFleetPages'
  | 'accessFreightBills'
  | 'accessOfferPages'
  | 'accessOrderPage'
  | 'accessPickups'
  | 'accessProductionPages'
  | 'accessProductPages'
  | 'accessSuperAdminPages'
  | 'accessTransporterPages';

type freightActions =
  | 'answerFreightOffer'
  | 'cancel'
  | 'comment'
  | 'downloadAssignment'
  | 'downloadTransportPlan'
  | 'downloadPackingPlan'
  | 'edit'
  | 'editFields'
  | 'editAllFields'
  | 'editDeliveryDate'
  | 'editTruckLicensePlateNumberAndDriverName'
  | 'editWaypoints'
  | 'filterDepot'
  | 'finalizeFreight'
  | 'filterTransporters'
  | 'planTransport'
  | 'reserveTimeSlot'
  | 'seeLinkToFreightBills'
  | 'sendOffer'
  | 'showDeadlineAlert'
  | 'showFreightIncome'
  | 'splitCargo'
  | 'setBillingData'
  | 'uncancel'
  | 'updateFromDeliveryToShippedStatus'
  | 'viewCargoSpaces';

type timeSlotActions = 'seeDepotSelect' | 'seeDockSelect' | 'seeTimeSlotWidthSelect';

type freightBillActions =
  | 'accept'
  | 'editCostCentre'
  | 'decline'
  | 'edit'
  | 'handleMultipleBills'
  | 'undo';

type transporterActions = 'editTransporters';

type generalDataActions =
  | 'editBasicSettings'
  | 'editCalendar'
  | 'editDepots'
  | 'editFactories'
  | 'editFreightCosts'
  | 'editPalletFees'
  | 'editProducts'
  | 'editTransporters'
  | 'editVehicles'
  | 'editWaypointCosts';

type basicSettingsActions =
  | 'editDefaultOpeningHours'
  | 'editFuzzySearch'
  | 'seeShippingNotificationConfig'
  | 'seePackingAlgorithm'
  | 'seeProxySettings'
  | 'viewDefaultOpeningHours';

type depotActions = 'editOpeningHours' | 'viewOpeningHours';

type orderItemActions =
  | 'addOrderItem'
  | 'deleteOrderItem'
  | 'displayOrderItem'
  | 'editOrderItem'
  | 'editOrderItemReleaseDate'
  | 'useActionButtons';

type orderActions =
  | 'addNewOrders'
  | 'addNewOrdersFromExcel'
  | 'addToAutoPlan'
  | 'close'
  | 'delete'
  | 'edit'
  | 'partitionByProducts'
  | 'planFreights'
  | 'reopen'
  | 'seeActionsMenu'
  | 'switchAutoPlan';

type orderEditActions =
  | 'editExternalIds'
  | 'editPreferredTrucks'
  | 'editReleaseDate'
  | 'seeDateError'
  | 'seeAdvancedReleaseDateEdit';

type eventActions = 'logForTransporter';

type externalIdActions = 'editExternalId';

type externalIdNamesActions = 'seeExternalIdNames';

type vehicleActions = 'editVehicleData';

type fleetActions = 'performFleetVehicleActions' | 'editFleetVehicleTransporter';

type capacityEstimateActions =
  | 'viewCapacityEstimateTransporter'
  | 'editCapacityEstimate'
  | 'editCapacityEstimateTransporter';

type pickupActions = 'cancel' | 'edit' | 'splitCargo' | 'uncancel';

type pickupEditActions =
  | 'changeStatusToScheduled'
  | 'changeStatusToShipped'
  | 'editDeliveryDate'
  | 'editExternalIds'
  | 'editNewStatus'
  | 'editPickupTruckLicensePlateNumber'
  | 'editPickupDriverName'
  | 'splitPickup';

type Actions =
  | basicSettingsActions
  | capacityEstimateActions
  | depotActions
  | eventActions
  | externalIdActions
  | externalIdNamesActions
  | fleetActions
  | freightActions
  | freightBillActions
  | generalDataActions
  | menuActions
  | orderActions
  | orderEditActions
  | orderItemActions
  | pickupActions
  | pickupEditActions
  | routeActions
  | timeSlotActions
  | transporterActions
  | vehicleActions;

type Subjects =
  | 'BasicSettings'
  | 'CapacityEstimate'
  | 'Depots'
  | 'Events'
  | 'ExternalId'
  | 'ExternalIdNames'
  | 'Fleet'
  | 'FreightBills'
  | 'Freights'
  | 'GeneralData'
  | 'Menus'
  | 'OrderItems'
  | 'Orders'
  | 'OrderEdit'
  | 'OrderItemEdit'
  | 'Pickups'
  | 'PickupEdit'
  | 'Routes'
  | 'TimeSlot'
  | 'Transporters'
  | 'Vehicles';

// #endregion - action types & subjects

// #region - subject fields

// there's no point adding externalIds as their editing is
// handled other way (admin can set their editablity in basic settings)
const ALL_EDITABLE_ORDER_FIELDS = [
  'comment',
  'customer.name',
  'customer.email',
  'customer.phone',
  'customer.recipientName',
  'customer.recipientPhone',
  'deliveryAddress',
  'deliveryToSite',
  'expectedDeadlineDate',
  'factoryShortName',
  'orderDate',
  'orderDeadlineDate',
  'preferredTrucks',
  'releaseDate'
];

const ALL_EDITABLE_ORDERITEM_FIELDS = [
  'deadlineDate',
  'itemId',
  'name',
  'kg',
  'quantity',
  'palletType',
  'palletsCount',
  'unit'
];

const ALL_EDITABLE_FREIGHT_FIELDS = [
  'deliveryDate',
  'driverName',
  'excludedFromAutomaticProcessing',
  'externalIds',
  'freightCost',
  'freightIncome',
  'status',
  'transporterId',
  'truckLicensePlateNumber',
  'truckType'
];

// #endregion - subject fields

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export function defineRulesFor(role: string) {
  const fieldEditSettings = store.getters['app/fieldEditSettings'];
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);

  //#region abilities related to Menus

  const constants = store.state.app.constants;
  const enums = store.state.app.enums;

  const editableFreightFields: Record<string, string[]> = {
    [constants.ROLES.SUPER_ADMIN]: ALL_EDITABLE_FREIGHT_FIELDS,
    [constants.ROLES.ADMIN]: ALL_EDITABLE_FREIGHT_FIELDS,
    [constants.ROLES.LOGISTICS_MANAGER]: ALL_EDITABLE_FREIGHT_FIELDS,
    [constants.ROLES.TRANSPORTER_MANAGER]: ['driverName', 'truckLicensePlateNumber'],
    [constants.ROLES.PRODUCTION_MANAGER]: ['deliveryDate', 'externalIds', 'status']
  };

  const editableOrderFields: Record<string, any[]> = {
    [constants.ROLES.SUPER_ADMIN]: ALL_EDITABLE_ORDER_FIELDS,
    [constants.ROLES.ADMIN]: ALL_EDITABLE_ORDER_FIELDS,
    [constants.ROLES.LOGISTICS_MANAGER]: ALL_EDITABLE_ORDER_FIELDS,
    [constants.ROLES.PRODUCTION_MANAGER]: ALL_EDITABLE_ORDER_FIELDS,
    [constants.ROLES.LOGISTICS_VIEWER]: []
  };

  const editableOrderItemFields: Record<string, string[]> = {
    [constants.ROLES.SUPER_ADMIN]: ALL_EDITABLE_ORDERITEM_FIELDS,
    [constants.ROLES.ADMIN]: ALL_EDITABLE_ORDERITEM_FIELDS,
    [constants.ROLES.LOGISTICS_MANAGER]: ALL_EDITABLE_ORDERITEM_FIELDS,
    [constants.ROLES.PRODUCTION_MANAGER]: ALL_EDITABLE_ORDERITEM_FIELDS,
    [constants.ROLES.LOGISTICS_VIEWER]: []
  };

  const editableOrderFieldsForRole = getEditableFieldList({
    editableFields: editableOrderFields[role],
    modifications: fieldEditSettings,
    entity: 'order',
    role
  });

  // if role is not editor but from config we allow editing some fields
  // then we have to make an exception for this role to be able to edit
  if (!isEditor(role) && editableOrderFieldsForRole.length > 0) {
    store.dispatch('account/setCanEditByConfigException', true);
  }

  const editableOrderItemFieldsForRole = getEditableFieldList({
    editableFields: editableOrderItemFields[role],
    modifications: fieldEditSettings,
    entity: 'orderItem',
    role
  });

  //FreightPlanner
  can('accessFreightPlanner', 'Menus');
  if (isTransporterUser(role)) {
    cannot('accessFreightPlanner', 'Menus');
  }

  can('accessFreightOrganizer', 'Menus');
  if (isTransporterUser(role)) {
    cannot('accessFreightOrganizer', 'Menus');
  }

  can('accessMap', 'Menus');
  if (isTransporterUser(role)) {
    cannot('accessMap', 'Menus');
  }

  can('accessRoutePlanner', 'Menus');
  if (!store.getters['module/isRoutePlannerModuleActive'] || isTransporterUser(role)) {
    cannot('accessRoutePlanner', 'Menus');
  }

  //Statistics
  can('accessFreightStatistics', 'Menus');
  if (
    !store.getters['module/isStatisticsModuleActive'] ||
    (store.getters['module/isStatisticsModuleActive'] &&
      ((isProductionUser(role) && !store.getters['module/canProductionUserAccessStatistics']) ||
        isTransporterUser(role)))
  ) {
    cannot('accessFreightStatistics', 'Menus');
  }

  //BaseConfig
  can('accessBaseData', 'Menus');
  if (isTransporterUser(role) || isProductionUser(role)) {
    cannot('accessBaseData', 'Menus');
  }

  can('accessBasicSettings', 'Menus');
  if (isTransporterUser(role) || isProductionUser(role)) {
    cannot('accessBasicSettings', 'Menus');
  }

  can('accessProducts', 'Menus');
  if (
    !store.getters['module/isProductCatalogModuleActive'] ||
    isTransporterUser(role) ||
    isProductionUser(role)
  ) {
    cannot('accessProducts', 'Menus');
  }

  can('accessDepotsAndFactories', 'Menus');
  if (isTransporterUser(role) || isProductionUser(role)) {
    cannot('accessDepotsAndFactories', 'Menus');
  }

  can('accessVehicles', 'Menus');
  if (isTransporterUser(role) || isProductionUser(role)) {
    cannot('accessVehicles', 'Menus');
  }

  can('accessTransporters', 'Menus');
  if (isTransporterUser(role) || isProductionUser(role)) {
    cannot('accessTransporters', 'Menus');
  }

  can('accessCalendarSettings', 'Menus');
  if (isTransporterUser(role) || isProductionUser(role)) {
    cannot('accessCalendarSettings', 'Menus');
  }

  can('accessFreightFees', 'Menus');
  if (isTransporterUser(role) || isProductionUser(role)) {
    cannot('accessFreightFees', 'Menus');
  }

  can('accessEvents', 'Menus');
  if (isTransporterUser(role)) {
    cannot('accessEvents', 'Menus');
  }

  cannot('accessFleet', 'Menus');
  if (isAdmin(role)) {
    can('accessFleet', 'Menus');
  }

  cannot('accessTransporterFleet', 'Menus');
  if (isTransporterUser(role)) {
    can('accessTransporterFleet', 'Menus');
  }

  cannot('accessUserList', 'Menus');
  if (isAdmin(role)) {
    can('accessUserList', 'Menus');
  }

  cannot('accessModuleSettings', 'Menus');
  if (role === constants.ROLES.SUPER_ADMIN) {
    can('accessModuleSettings', 'Menus');
  }

  can('accessBackToHomepage', 'Menus');
  if (isTransporterUser(role)) {
    cannot('accessBackToHomepage', 'Menus');
  }

  cannot('accessTestOrders', 'Menus');
  if (isAdmin(role) || role === constants.ROLES.LOGISTICS_MANAGER) {
    can('accessTestOrders', 'Menus');
  }

  cannot('accessOffers', 'Menus');
  if (isTransporterUser(role) && store.getters['module/isFreightOfferModuleActive']) {
    can('accessOffers', 'Menus');
  }

  cannot('accessTransporterFreights', 'Menus');
  if (isTransporterUser(role)) {
    can('accessTransporterFreights', 'Menus');
  }

  cannot('accessTransporterEvents', 'Menus');
  if (isTransporterUser(role)) {
    can('accessTransporterEvents', 'Menus');
  }

  cannot('seeAdminLogo', 'Menus');
  if (isAdmin(role)) {
    can('seeAdminLogo', 'Menus');
  }

  cannot('seeOrdersProcessing', 'Menus');
  if (isAdmin(role) || role === constants.ROLES.LOGISTICS_MANAGER) {
    can('seeOrdersProcessing', 'Menus');
  }

  cannot('seeNewOrders', 'Menus');
  if (isAdmin(role) || role === constants.ROLES.LOGISTICS_MANAGER) {
    can('seeNewOrders', 'Menus');
  }

  can('seeSmsError', 'Menus');
  if (isTransporterUser(role) || role === constants.ROLES.PRODUCTION_MANAGER) {
    cannot('seeSmsError', 'Menus');
  }

  can('accessFreightBills', 'Menus');
  if (!canAccessFreightBills(role)) {
    cannot('accessFreightBills', 'Menus');
  }

  cannot('accessTimeSlots', 'Menus');
  if (store.getters['module/isTimeSlotModuleActive']) {
    can('accessTimeSlots', 'Menus');
  }

  cannot('accessCapacityEstimate', 'Menus');
  if (canAccessCapacityEstimate(role)) {
    can('accessCapacityEstimate', 'Menus');
  }

  cannot('accessTransporterCapacityEstimate', 'Menus');
  if (canAccessTransporterCapacityEstimate(role)) {
    can('accessTransporterCapacityEstimate', 'Menus');
  }

  can('accessPickups', 'Menus');
  if (canAccessPickups(role)) {
    cannot('accessPickups', 'Menus');
  }

  cannot('seeManualCombineResults', 'Menus');
  if (isAdmin(role) || role === constants.ROLES.LOGISTICS_MANAGER) {
    can('seeManualCombineResults', 'Menus');
  }
  //#endregion related to menus

  //#region abilities related to routes

  can('accessOrderPage', 'Routes');
  if (isTransporterUser(role)) {
    cannot('accessOrderPage', 'Routes');
  }

  can('accessOfferPages', 'Routes');
  if (!store.getters['module/isFreightOfferModuleActive'] || !isTransporterUser(role)) {
    cannot('accessOfferPages', 'Routes');
  }

  cannot('accessTransporterPages', 'Routes');
  if (isTransporterUser(role)) {
    can('accessTransporterPages', 'Routes');
  }

  cannot('accessProductionPages', 'Routes');
  if (isProductionUser(role)) {
    can('accessProductionPages', 'Routes');
  }

  cannot('accessSuperAdminPages', 'Routes');
  if (role === constants.ROLES.SUPER_ADMIN) {
    can('accessSuperAdminPages', 'Routes');
  }

  cannot('accessAdminPages', 'Routes');
  if (isAdmin(role)) {
    can('accessAdminPages', 'Routes');
  }

  cannot('accessFleetPages', 'Routes');
  if (isAdmin(role) || isTransporterUser(role)) {
    can('accessFleetPages', 'Routes');
  }

  can('accessProductPages', 'Routes');
  if (
    !store.getters['module/isProductCatalogModuleActive'] ||
    isTransporterUser(role) ||
    isProductionUser(role)
  ) {
    cannot('accessProductPages', 'Routes');
  }

  cannot('accessEntranceSupervisorPages', 'Routes');
  if (role === constants.ROLES.ENTRANCE_SUPERVISOR) {
    can('accessEntranceSupervisorPages', 'Routes');
  }

  can('accessFreightBills', 'Routes');
  if (!canAccessFreightBills(role)) {
    cannot('accessFreightBills', 'Routes');
  }

  cannot('accessCapacityEstimate', 'Routes');
  if (canAccessCapacityEstimate(role) || canAccessTransporterCapacityEstimate(role)) {
    can('accessCapacityEstimate', 'Routes');
  }

  can('accessPickups', 'Routes');
  if (canAccessPickups(role)) {
    cannot('accessPickups', 'Routes');
  }

  //#endregion related to routes

  //#region abilities related to freights

  cannot('cancel', 'Freights');
  if (isAdmin(role) && store.getters['module/isOrderClosureModuleActive']) {
    can('cancel', 'Freights');
  }

  cannot('reserveTimeSlot', 'Freights');
  if (canReserveTimeSlot(role)) {
    can('reserveTimeSlot', 'Freights');
  }

  can('filterTransporters', 'Freights');
  if (constants.TRANSPORTER_ROLES.includes(role)) {
    cannot('filterTransporters', 'Freights');
  }

  can('planTransport', 'Freights');
  if (
    role === constants.ROLES.PRODUCTION_MANAGER ||
    role === constants.ROLES.TRANSPORTER_MANAGER ||
    isViewer(role)
  ) {
    cannot('planTransport', 'Freights');
  }

  can('downloadTransportPlan', 'Freights');
  if (!store.getters['module/isFreightDownloadTransportPlanActive']) {
    cannot('downloadTransportPlan', 'Freights');
  }

  can('downloadPackingPlan', 'Freights');
  if (!store.getters['module/isFreightDownloadPackingPlanActive']) {
    cannot('downloadPackingPlan', 'Freights');
  }

  can('edit', 'Freights');
  if (
    role === constants.ROLES.TRANSPORTER_MANAGER ||
    isViewer(role) ||
    role === constants.ROLES.ENTRANCE_SUPERVISOR
  ) {
    cannot('edit', 'Freights');
  }

  cannot('changeStatusToShipped', 'Freights');
  if (
    isSuperAdmin(role) ||
    (isEditor(role) && !store.getters['module/isOnlyProductionManagerCanPassFreightsActive']) ||
    (role === constants.ROLES.PRODUCTION_MANAGER &&
      (store.getters['module/canProductionManagerPassFreights'] ||
        store.getters['module/isOnlyProductionManagerCanPassFreightsActive']))
  ) {
    can('changeStatusToShipped', 'Freights');
  }

  cannot('editDeliveryDate', 'Freights');
  if (canEditDeliveryDate(role)) {
    can('editDeliveryDate', 'Freights');
  }

  cannot('editWaypoints', 'Freights');
  if (canEditWaypointsOnFreight(role)) {
    can('editWaypoints', 'Freights');
  }

  can('downloadAssignment', 'Freights');
  if (
    role === constants.ROLES.PRODUCTION_MANAGER ||
    role === constants.ROLES.TRANSPORTER_MANAGER ||
    isViewer(role)
  ) {
    cannot('downloadAssignment', 'Freights');
  }

  cannot('sendOffer', 'Freights');
  if (store.getters['module/isFreightOfferModuleActive'] && isEditor(role)) {
    can('sendOffer', 'Freights');
  }

  can('splitCargo', 'Freights');
  if (
    (role === constants.ROLES.PRODUCTION_MANAGER &&
      !store.getters['module/canProductionManagerSplitFreights']) ||
    role === constants.ROLES.TRANSPORTER_MANAGER ||
    isViewer(role)
  ) {
    cannot('splitCargo', 'Freights');
  }

  can('showDeadlineAlert', 'Freights');
  if (isTransporterUser(role)) {
    cannot('showDeadlineAlert', 'Freights');
  }

  can('showFreightIncome', 'Freights');
  if (isTransporterUser(role)) {
    cannot('showFreightIncome', 'Freights');
  }

  cannot('editFields', 'Freights');
  if (isEditor(role) || role === constants.ROLES.PRODUCTION_MANAGER) {
    const editableFieldsForRole = getEditableFieldList({
      editableFields: editableFreightFields[role],
      modifications: fieldEditSettings,
      entity: 'freight',
      role
    });
    if (editableFieldsForRole.length > 0) {
      can('editFields', 'Freights', editableFieldsForRole);
    }
  }

  can('editAllFields', 'Freights');
  if (role === constants.ROLES.PRODUCTION_MANAGER) {
    cannot('editAllFields', 'Freights');
  }

  cannot('answerFreightOffer', 'Freights');
  if (role === constants.ROLES.TRANSPORTER_MANAGER) {
    can('answerFreightOffer', 'Freights');
  }

  cannot('comment', 'Freights');
  if (
    store.getters['module/isFreightCommentActive'] &&
    role === constants.ROLES.TRANSPORTER_MANAGER
  ) {
    can('comment', 'Freights');
  }

  can('viewCargoSpaces', 'Freights');
  if (!store.getters['module/canViewCargoSpacesInFreightMenu']) {
    cannot('viewCargoSpaces', 'Freights');
  }

  cannot('finalizeFreight', 'Freights');
  if (canFinalizeFreight(role)) {
    can('finalizeFreight', 'Freights');
  }

  cannot('editTruckLicensePlateNumberAndDriverName', 'Freights');
  if (canEditTruckLicensePlateNumberAndDriverName(role)) {
    can('editTruckLicensePlateNumberAndDriverName', 'Freights');
  }

  cannot('setBillingData', 'Freights');
  if (
    role === constants.ROLES.TRANSPORTER_MANAGER &&
    store.getters['module/isBillingModuleActive']
  ) {
    can('setBillingData', 'Freights');
  }

  cannot('seeLinkToFreightBills', 'Freights');
  if (isAdmin(role) || (isLogisticsUser(role) && store.getters['module/isBillingModuleActive'])) {
    can('seeLinkToFreightBills', 'Freights');
  }

  cannot('uncancel', 'Freights');
  if (isAdmin(role) && store.getters['module/isOrderClosureModuleActive']) {
    can('uncancel', 'Freights');
  }

  /**
   * Freight can be set from delivery to shipped status only if it is
   * set by superadmin,
   * or set by an editor but neither of
   *    ONLY_TRANSPORTER_CAN_SET_FREIGHTS_TO_SHIPPED and ONLY_PRODUCTION_MANAGER_CAN_PASS_FREIGHTS
   *    configs are active,
   * or set by transporter manager and any of the
   *    DELIVERY_STATUS or ONLY_TRANSPORTER_CAN_SET_FREIGHTS_TO_SHIPPED configs are active,
   * or set by production manager and any of the
   *    PRODUCTION_MANAGER_CAN_PASS_FREIGHTS or ONLY_PRODUCTION_MANAGER_CAN_PASS_FREIGHTS
   *    configs are active.
   */
  cannot('updateFromDeliveryToShippedStatus', 'Freights');
  if (
    isSuperAdmin(role) ||
    (isEditor(role) &&
      !store.getters['module/isOnlyTransporterCanSetFreightsToShippedActive'] &&
      !store.getters['module/isOnlyProductionManagerCanPassFreightsActive']) ||
    (role === constants.ROLES.TRANSPORTER_MANAGER &&
      (store.getters['module/isUpdateFromDeliveryToShippedStatusActive'] ||
        store.getters['module/isOnlyTransporterCanSetFreightsToShippedActive'])) ||
    (role === constants.ROLES.PRODUCTION_MANAGER &&
      !store.getters['module/isOnlyTransporterCanSetFreightsToShippedActive'] &&
      (store.getters['module/canProductionManagerPassFreights'] ||
        store.getters['module/isOnlyProductionManagerCanPassFreightsActive']))
  ) {
    can('updateFromDeliveryToShippedStatus', 'Freights');
  }

  //#endregion abilities related to freights

  //#region abilities related to timeslot

  can('seeDockSelect', 'TimeSlot');
  if (isTransporterUser(role)) {
    cannot('seeDockSelect', 'TimeSlot');
  }

  cannot('seeTimeSlotWidthSelect', 'TimeSlot');
  if (
    (isEditor(role) || role === constants.ROLES.PRODUCTION_MANAGER) &&
    store.getters['module/isTimeSlotWidthActive']
  ) {
    can('seeTimeSlotWidthSelect', 'TimeSlot');
  }

  can('seeDepotSelect', 'TimeSlot');
  if (role === constants.ROLES.ENTRANCE_SUPERVISOR) {
    cannot('seeDepotSelect', 'TimeSlot');
  }

  //#endregion related to timeslot

  //#region abilities related to freightBills

  cannot('edit', 'FreightBills');
  if (
    (store.getters['module/isBillingModuleActive'] && isAdmin(role)) ||
    role === constants.ROLES.LOGISTICS_MANAGER
  ) {
    can('edit', 'FreightBills');
  }

  cannot('accept', 'FreightBills');
  if (
    (store.getters['module/isBillingModuleActive'] && isAdmin(role)) ||
    role === constants.ROLES.LOGISTICS_MANAGER
  ) {
    can('accept', 'FreightBills');
  }

  cannot('decline', 'FreightBills');
  if (
    (store.getters['module/isBillingModuleActive'] && isAdmin(role)) ||
    role === constants.ROLES.LOGISTICS_MANAGER
  ) {
    can('decline', 'FreightBills');
  }

  cannot('undo', 'FreightBills');
  if (
    (store.getters['module/isBillingModuleActive'] && isAdmin(role)) ||
    role === constants.ROLES.LOGISTICS_MANAGER
  ) {
    can('undo', 'FreightBills');
  }

  cannot('editCostCentre', 'FreightBills');
  if (
    (store.getters['module/isBillingModuleActive'] && isAdmin(role)) ||
    role === constants.ROLES.LOGISTICS_MANAGER
  ) {
    can('editCostCentre', 'FreightBills');
  }

  cannot('handleMultipleBills', 'FreightBills');
  if (
    (store.getters['module/isBillingModuleActive'] && isAdmin(role)) ||
    role === constants.ROLES.LOGISTICS_MANAGER
  ) {
    can('handleMultipleBills', 'FreightBills');
  }

  //#endregion abilities related to freightBills

  //#region abilities related to capacityEstimates

  cannot('viewCapacityEstimateTransporter', 'CapacityEstimate');
  if (canAccessCapacityEstimate(role)) {
    can('viewCapacityEstimateTransporter', 'CapacityEstimate');
  }

  cannot('editCapacityEstimate', 'CapacityEstimate');
  if (
    store.getters['module/isCapacityEstimateModuleActive'] &&
    (isEditor(role) || role === constants.ROLES.TRANSPORTER_MANAGER)
  ) {
    can('editCapacityEstimate', 'CapacityEstimate');
  }

  cannot('editCapacityEstimateTransporter', 'CapacityEstimate');
  if (store.getters['module/isCapacityEstimateModuleActive'] && isEditor(role)) {
    can('editCapacityEstimateTransporter', 'CapacityEstimate');
  }
  //#endregion abilities related to capacityEstimates

  // #region abilities related to general data
  // #region abilities related to basic settings
  cannot('editBasicSettings', 'GeneralData');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerModifyBaseConfig'])
  ) {
    can('editBasicSettings', 'GeneralData');
  }

  cannot('seePackingAlgorithm', 'BasicSettings');
  if (store.getters['app/logisticsPlannerType'] === enums.LogisticsPlannerType.LOGISTICS) {
    can('seePackingAlgorithm', 'BasicSettings');
  }

  cannot('seeShippingNotificationConfig', 'BasicSettings');
  if (store.getters['module/isShippingNotificationModuleActive'] && isAdmin(role)) {
    can('seeShippingNotificationConfig', 'BasicSettings');
  }

  cannot('seeProxySettings', 'BasicSettings');
  if (store.getters['module/isShippingNotificationModuleActive'] && isSuperAdmin(role)) {
    can('seeProxySettings', 'BasicSettings');
  }

  cannot('editFuzzySearch', 'BasicSettings');
  if (isSuperAdmin(role)) {
    can('editFuzzySearch', 'BasicSettings');
  }

  // #region abilities related to default opening hours
  cannot('viewDefaultOpeningHours', 'BasicSettings');
  if (store.getters['module/isTimeSlotModuleActive']) {
    can('viewDefaultOpeningHours', 'BasicSettings');
  }

  cannot('editDefaultOpeningHours', 'BasicSettings');
  if (
    store.getters['module/isTimeSlotModuleActive'] &&
    (isAdmin(role) ||
      (role === constants.ROLES.LOGISTICS_MANAGER &&
        store.getters['module/canLogisticsManagerModifyBaseConfig']))
  ) {
    can('editDefaultOpeningHours', 'BasicSettings');
  }
  // #endregion abilities related to default opening hours
  // #endregion abilities related to basic settings

  // #region abilities related to products
  cannot('editProducts', 'GeneralData');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerModifyBaseConfig'])
  ) {
    can('editProducts', 'GeneralData');
  }
  // #endregion abilities related to products

  // #region abilities related to depots and factories
  cannot('editDepots', 'GeneralData');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerModifyBaseConfig'])
  ) {
    can('editDepots', 'GeneralData');
  }

  cannot('editFactories', 'GeneralData');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerModifyBaseConfig'])
  ) {
    can('editFactories', 'GeneralData');
  }
  // #endregion abilities related to depots and factories

  // #region abilities related to vehicles
  cannot('editVehicles', 'GeneralData');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerModifyBaseConfig'])
  ) {
    can('editVehicles', 'GeneralData');
  }
  // #endregion abilities related to vehicles

  // #region abilities related to transporters
  cannot('editTransporters', 'GeneralData');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerModifyBaseConfig'])
  ) {
    can('editTransporters', 'GeneralData');
  }
  // #endregion abilities related to transporters

  // #region abilities related to calendar
  cannot('editCalendar', 'GeneralData');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerModifyBaseConfig'])
  ) {
    can('editCalendar', 'GeneralData');
  }
  // #endregion abilities related to calendar

  // #region abilities related to costs and fees
  cannot('editFreightCosts', 'GeneralData');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerModifyBaseConfig'])
  ) {
    can('editFreightCosts', 'GeneralData');
  }

  cannot('editWaypointCosts', 'GeneralData');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerModifyBaseConfig'])
  ) {
    can('editWaypointCosts', 'GeneralData');
  }

  cannot('editPalletFees', 'GeneralData');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerModifyBaseConfig'])
  ) {
    can('editPalletFees', 'GeneralData');
  }
  // #endregion abilities related to costs and fees
  // #endregion abilities related to general data

  //#region abilities related to depots

  cannot('viewOpeningHours', 'Depots');
  if (store.getters['module/isTimeSlotModuleActive']) {
    can('viewOpeningHours', 'Depots');
  }

  cannot('editOpeningHours', 'Depots');
  if (store.getters['module/isTimeSlotModuleActive'] && isEditor(role)) {
    can('editOpeningHours', 'Depots');
  }

  //#endregion abilities related to depots

  //#region abilities related to orderItems

  can('editOrderItem', 'OrderItems');
  if (role === constants.ROLES.PRODUCTION_MANAGER) {
    cannot('editOrderItem', 'OrderItems');
  }

  can('editOrderItemReleaseDate', 'OrderItems');
  if (
    !store.getters['module/canProductionManagerSetReleaseDates'] &&
    role === constants.ROLES.PRODUCTION_MANAGER
  ) {
    cannot('editOrderItemReleaseDate', 'OrderItems');
  }

  cannot('useActionButtons', 'OrderItems');
  if (
    isAdmin(role) ||
    role === constants.ROLES.LOGISTICS_MANAGER ||
    store.getters['account/authDisabled']
  ) {
    can('useActionButtons', 'OrderItems');
  }

  cannot('displayOrderItem', 'OrderItems');
  if (
    isAdmin(role) ||
    role === constants.ROLES.LOGISTICS_MANAGER ||
    store.getters['account/authDisabled']
  ) {
    can('displayOrderItem', 'OrderItems');
  }

  //#endregion abilities related to orderItems

  //#region abilities related to orders

  cannot('addNewOrders', 'Orders');
  if (store.getters['module/isAddNewOrderManuallyActive'] && isEditor(role)) {
    can('addNewOrders', 'Orders');
  }

  cannot('addNewOrdersFromExcel', 'Orders');
  if (store.getters['module/isOrderExcelImportModuleActive'] && isEditor(role)) {
    can('addNewOrdersFromExcel', 'Orders');
  }

  can('planFreights', 'Orders');
  if (role === constants.ROLES.PRODUCTION_MANAGER) {
    cannot('planFreights', 'Orders');
  }

  cannot('switchAutoPlan', 'Orders');
  if (
    isAdmin(role) ||
    (role === constants.ROLES.LOGISTICS_MANAGER &&
      store.getters['module/canLogisticsManagerSetAutomaticProcessing'])
  ) {
    can('switchAutoPlan', 'Orders');
  }

  can('addToAutoPlan', 'Orders');
  if (!isEditor(role)) {
    cannot('addToAutoPlan', 'Orders');
  }

  cannot('seeActionsMenu', 'Orders');
  if (
    isEditor(role) ||
    role === constants.ROLES.PRODUCTION_MANAGER ||
    editableOrderFieldsForRole.length > 0
  ) {
    can('seeActionsMenu', 'Orders');
  }

  cannot('edit', 'Orders');
  if (
    isEditor(role) ||
    role === constants.ROLES.PRODUCTION_MANAGER ||
    editableOrderFieldsForRole.length > 0
  ) {
    can(
      'edit',
      'Orders',
      Object.values(enums.orderStatus as Record<string, string>).filter(
        (status) =>
          ![enums.orderStatus.waitingForProcessing, enums.orderStatus.processing].includes(status)
      )
    );
  }

  cannot('delete', 'Orders');
  if (isEditor(role) && store.getters['module/isDeleteOrderActive']) {
    can('delete', 'Orders');
  }

  can('partitionByProducts', 'Orders');
  if (!isEditor(role)) {
    cannot('partitionByProducts', 'Orders');
  }

  can('close', 'Orders');
  if (!isEditor(role) || !store.getters['module/isOrderClosureModuleActive']) {
    cannot('close', 'Orders');
  }

  can('reopen', 'Orders');
  if (!isEditor(role) || !store.getters['module/isOrderClosureModuleActive']) {
    cannot('reopen', 'Orders');
  }

  //#endregion abilities related to orders

  //#region abilities related to orderEdit

  cannot('edit', 'OrderEdit');
  if (
    isEditor(role) ||
    (role === constants.ROLES.LOGISTICS_VIEWER && editableOrderFieldsForRole.length > 0)
  ) {
    can('edit', 'OrderEdit', editableOrderFieldsForRole);
  }

  const isAddOrderItemActive = getEditableFieldList({
    editableFields: ['addOrderItem'],
    modifications: fieldEditSettings,
    entity: 'action',
    role
  }).includes('addOrderItem');
  if (isAddOrderItemActive) {
    can('addOrderItem', 'OrderItemEdit');
  } else {
    cannot('addOrderItem', 'OrderItemEdit');
  }

  const isDeleteOrderItemActive = getEditableFieldList({
    editableFields: ['deleteOrderItem'],
    modifications: fieldEditSettings,
    entity: 'action',
    role
  }).includes('deleteOrderItem');
  if (isDeleteOrderItemActive) {
    can('deleteOrderItem', 'OrderItemEdit');
  } else {
    cannot('deleteOrderItem', 'OrderItemEdit');
  }

  cannot('edit', 'OrderItemEdit');
  if (editableOrderItemFieldsForRole.length > 0) {
    can('edit', 'OrderItemEdit', editableOrderItemFieldsForRole);
  }

  can('editPreferredTrucks', 'OrderEdit');
  if (!isEditor(role) || !store.getters['module/isEditPreferredTrucksActive']) {
    cannot('editPreferredTrucks', 'OrderEdit');
  }

  cannot('seeAdvancedReleaseDateEdit', 'OrderEdit');
  if (isEditor(role)) {
    can('seeAdvancedReleaseDateEdit', 'OrderEdit');
  }

  cannot('editReleaseDate', 'OrderEdit');
  if (
    isEditor(role) ||
    (role === constants.ROLES.PRODUCTION_MANAGER &&
      store.getters['module/canProductionManagerSetReleaseDates'])
  ) {
    can('editReleaseDate', 'OrderEdit');
  }

  can('seeDateError', 'OrderEdit');
  if (!isEditor(role)) {
    cannot('seeDateError', 'OrderEdit');
  }

  can('editExternalIds', 'OrderEdit');
  if (!isEditor(role)) {
    cannot('editExternalIds', 'OrderEdit');
  }

  //#endregion abilities related to orderEdit

  //#region abilities related to event log

  can('logForTransporter', 'Events');
  if (!isTransporterUser(role)) {
    cannot('logForTransporter', 'Events');
  }

  //#endregion abilities related to event log

  //#region abilities related to external ids

  cannot('editExternalId', 'ExternalId');
  // condition is needed to be cast as never because can function
  // expects MongoQuery that way
  if (isExtendedEditor(role)) {
    can('editExternalId', 'ExternalId', {
      rolesAllowedToEdit: { $in: [role] }
    } as never);
  }

  //#endregion abilities related to external ids

  //#region abilities related to external id names

  can('seeExternalIdNames', 'ExternalIdNames');
  if (role !== constants.ROLES.SUPER_ADMIN) {
    cannot('seeExternalIdNames', 'ExternalIdNames');
  }

  //#endregion abilities related to external id names

  //#region abilities related to vehicles

  cannot('editVehicleData', 'Vehicles');
  if (isEditor(role) && store.getters['module/isEditVehicleDataActive']) {
    can('editVehicleData', 'Vehicles');
  }

  //#endregion abilities related to vehicles

  //#region abilities related to fleetVehicles

  cannot('performFleetVehicleActions', 'Fleet');
  if (isAdmin(role) || role === constants.ROLES.TRANSPORTER_MANAGER) {
    can('performFleetVehicleActions', 'Fleet');
  }

  cannot('editFleetVehicleTransporter', 'Fleet');
  if (isAdmin(role)) {
    can('editFleetVehicleTransporter', 'Fleet');
  }

  //#endregion abilities related to fleetVehicles

  //#region abilities related to pickups

  cannot('cancel', 'Pickups');
  if (isAdmin(role) && store.getters['module/isOrderClosureModuleActive']) {
    can('cancel', 'Pickups');
  }

  cannot('edit', 'Pickups');
  if (
    store.getters['module/isPickupModuleActive'] &&
    ([constants.ROLES.PRODUCTION_MANAGER, constants.ROLES.SUPER_ADMIN].includes(role) ||
      (isEditor(role) && !store.getters['module/isOnlyProductionManagerCanEditPickupsActive']))
  ) {
    can('edit', 'Pickups');
  }

  /**
   * Pickups' cargo can be split by
   * superadmin,
   * or production manager if any of
   *     PRODUCTION_MANAGER_CAN_SPLIT_FREIGHTS or ONLY_PRODUCTION_MANAGER_CAN_EDIT_PICKUPS
   *     config is active,
   * or an editor if ONLY_PRODUCTION_MANAGER_CAN_EDIT_PICKUPS config is not active.
   */
  cannot('splitCargo', 'Pickups');
  if (
    isSuperAdmin(role) ||
    (role === constants.ROLES.PRODUCTION_MANAGER &&
      (store.getters['module/canProductionManagerSplitFreights'] ||
        store.getters['module/isOnlyProductionManagerCanEditPickupsActive'])) ||
    (isEditor(role) && !store.getters['module/isOnlyProductionManagerCanEditPickupsActive'])
  ) {
    can('splitCargo', 'Pickups');
  }

  cannot('uncancel', 'Pickups');
  if (isAdmin(role) && store.getters['module/isOrderClosureModuleActive']) {
    can('uncancel', 'Pickups');
  }

  //#endregion ablities related to pickups

  //#region abilities related to pickupEdit

  cannot('changeStatusToShipped', 'PickupEdit');
  if (
    isEditor(role) ||
    (role === constants.ROLES.PRODUCTION_MANAGER &&
      store.getters['module/canProductionManagerPassFreights'])
  ) {
    can('changeStatusToShipped', 'PickupEdit');
  }

  cannot('changeStatusToScheduled', 'PickupEdit');
  if (
    isEditor(role) ||
    (role === constants.ROLES.PRODUCTION_MANAGER &&
      store.getters['module/canProductionManagerSchedulePickups'])
  ) {
    can('changeStatusToScheduled', 'PickupEdit');
  }

  cannot('editDeliveryDate', 'PickupEdit');
  if (isEditor(role) || role === constants.ROLES.PRODUCTION_MANAGER) {
    can('editDeliveryDate', 'PickupEdit');
  }

  cannot('editExternalIds', 'PickupEdit');
  if (isEditor(role)) {
    can('editExternalIds', 'PickupEdit');
  }

  cannot('editNewStatus', 'PickupEdit');
  if (isEditor(role) || store.getters['module/canProductionManagerSchedulePickups']) {
    can('editNewStatus', 'PickupEdit');
  }

  cannot('editPickupTruckLicensePlateNumber', 'PickupEdit');
  if (isEditor(role)) {
    can('editPickupTruckLicensePlateNumber', 'PickupEdit');
  }

  cannot('editPickupDriverName', 'PickupEdit');
  if (isEditor(role)) {
    can('editPickupDriverName', 'PickupEdit');
  }

  //#endregion abilities related to pickupEdit
  return rules;
}

export function buildAbilityFor() {
  return new AppAbility();
}
