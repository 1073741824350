/* eslint-disable @typescript-eslint/no-explicit-any */
import { isDate } from 'moment-mini';

export const delay = (ms: number | undefined): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isObject = (item: Record<string, unknown>): boolean => {
  return item && typeof item === 'object' && !Array.isArray(item) && !isDate(item);
};

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const mergeEntitiesByFieldRestrictions = (
  target: Record<string, any>,
  source: Record<string, any>,
  editableFields: Array<string>
): void => {
  if (isObject(target) && isObject(source)) {
    editableFields.forEach((field) => {
      //update only the editable fields of an object
      target[field] = source[field];
    });
  }
};

export async function getEditableFieldsByEnabledModules(
  userRole,
  enabledModuleDependencies,
  fieldEditRestrictions
) {
  //lose reference to original object in fieldEditRestrictions.ts
  let editableFields = fieldEditRestrictions[userRole]
    ? JSON.parse(JSON.stringify(fieldEditRestrictions[userRole]))
    : [];

  for (const moduleDependency of enabledModuleDependencies) {
    //find the object fields (e.g.:{ orderItems: [...editableFields] })
    const objectFields = moduleDependency.editableFields.filter((field) => isObject(field));
    objectFields.forEach((objectField) => {
      //get the key of the nested object's editable fields list (e.g.: 'orderItems')
      const objectFieldKey = Object.keys(objectField)[0];
      const editableObjectField = editableFields.find(
        (editableField) => isObject(editableField) && editableField[objectFieldKey]
      );
      if (editableObjectField) {
        //if the editableFields list already contains the nested object's editableFields list
        //copy the new values to it
        objectField[objectFieldKey].forEach((field) => {
          if (
            !editableFields[editableFields.indexOf(editableObjectField)][objectFieldKey].includes(
              field
            )
          ) {
            editableFields[editableFields.indexOf(editableObjectField)][objectFieldKey].push(field);
          }
        });
      } else {
        //otherwise add the nested object's editable field list to editableFields
        editableFields.push(objectField);
      }
    });
    editableFields = editableFields.concat(
      //since we already handled object fields we simply concat only the remaining string fields
      moduleDependency.editableFields.filter((field) => !isObject(field))
    );
  }
  return editableFields;
}
