/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  EditableFields,
  FieldsEditRestrictionsType,
  ModuleDependencies
} from '../types/fieldEditRestriction';
import constants from './constants';

export class FieldsEditRestriction {
  private static instance: FieldsEditRestriction;
  _config: any;

  private constructor(config: any) {
    this._config = config;
  }

  public static init(config: any): FieldsEditRestriction {
    if (!FieldsEditRestriction.instance) {
      FieldsEditRestriction.instance = new FieldsEditRestriction(config);
    }
    return FieldsEditRestriction.instance;
  }

  private addEditableFields(
    originalEditableFields: EditableFields,
    addition: EditableFields
  ): EditableFields {
    const cloneOfOriginal = JSON.parse(JSON.stringify(originalEditableFields));
    for (const field of addition) {
      if (typeof field === 'string') {
        if (!cloneOfOriginal.includes(field)) {
          cloneOfOriginal.push(field);
        }
      } else {
        const key = Object.keys(field)[0];
        const index = cloneOfOriginal.findIndex(
          (field: any) => typeof field === 'object' && Object.keys(field)[0] === key
        );
        if (index > -1) {
          cloneOfOriginal[index][key] = this.addEditableFields(
            cloneOfOriginal[index][key],
            field[key]
          );
        } else {
          cloneOfOriginal.push(field);
        }
      }
    }
    return cloneOfOriginal;
  }

  private removeEditableFields(
    originalEditableFields: EditableFields,
    removal: EditableFields
  ): EditableFields {
    const cloneOfOriginal = JSON.parse(JSON.stringify(originalEditableFields));
    for (const field of removal) {
      if (typeof field === 'string') {
        const index = cloneOfOriginal.findIndex(
          (_field: any) => typeof _field === 'string' && _field === field
        );
        if (index > -1) {
          cloneOfOriginal.splice(index, 1);
        }
      } else {
        const key = Object.keys(field)[0];
        const index = cloneOfOriginal.findIndex(
          (_field: any) => typeof _field === 'object' && Object.keys(_field)[0] === key
        );
        if (index > -1) {
          cloneOfOriginal[index][key] = this.removeEditableFields(
            cloneOfOriginal[index][key],
            field[key]
          );
        }
      }
    }
    return cloneOfOriginal;
  }

  private changeRestrictionsByConfig(
    originalRestrictions: EditableFields,
    entity: 'order' | 'freight',
    role: string
  ) {
    let restrictions = JSON.parse(JSON.stringify(originalRestrictions));
    if (!this._config.fieldEditSettings || !this._config.fieldEditSettings[entity]) {
      return restrictions;
    }
    const extraRules = this._config.fieldEditSettings[entity];
    const enableList = extraRules.enable ? extraRules.enable[role] : undefined;
    if (enableList) {
      restrictions = this.addEditableFields(restrictions, enableList);
    }
    const disableList = extraRules.disable ? extraRules.disable[role] : undefined;
    if (disableList) {
      restrictions = this.removeEditableFields(restrictions, disableList);
    }
    return restrictions;
  }

  // #region all editable fields
  /**
   * Lists below contain the widest lists of ALL FIELDS of an object
   * that a highest level user (superadmin) can EDIT from client
   * with ALL MODULES TURNED OFF.
   */
  ALL_EDITABLE_FREIGHT_FIELDS: EditableFields = [
    'deliveryDate',
    'driverName',
    'excludedFromAutomaticProcessing',
    'externalIds',
    'freightCost',
    'freightIncome',
    'status',
    'transporterId',
    'truckLicensePlateNumber',
    'truckType'
  ];

  ALL_EDITABLE_PICKUP_FIELDS: EditableFields = [
    'deliveryDate',
    'driverName',
    'externalIds',
    'status',
    'truckLicensePlateNumber'
  ];

  ALL_EDITABLE_ORDER_FIELDS: EditableFields = [
    { orderItems: ['id', 'releaseDate', 'deadlineDate'] },
    'releaseDate',
    'expectedDeadlineDate',
    'externalIds',
    'comment'
  ];

  ALL_EDITABLE_BASE_CONFIG_FIELDS: EditableFields = [
    'deliveryInterval',
    'deliveryIntervalType',
    'freightDeadlineWarningInterval',
    'id',
    'packingAlgorithm',
    'routingMethod',
    'transporterReplyDeadline',
    'unloadTimePerPallet',
    'fuzzySearch'
  ];
  // #endregion

  // #region editable fields for users
  /**
   * This section specifies the fields that a user-type can edit
   * with ALL MODULES TURNED OFF.
   * Empty array or missing role means that this user can not edit anything on that object.
   */
  get FREIGHT_FIELDS_EDIT_RESTRICTIONS(): FieldsEditRestrictionsType {
    return {
      [constants.ROLES.ADMIN]: this.ALL_EDITABLE_FREIGHT_FIELDS,
      [constants.ROLES.SUPER_ADMIN]: this.ALL_EDITABLE_FREIGHT_FIELDS,
      [constants.ROLES.LOGISTICS_MANAGER]: this.ALL_EDITABLE_FREIGHT_FIELDS,
      [constants.ROLES.TRANSPORTER_MANAGER]: ['driverName', 'truckLicensePlateNumber'],
      [constants.ROLES.PRODUCTION_MANAGER]: this.changeRestrictionsByConfig(
        ['deliveryDate', 'driverName', 'externalIds', 'status', 'truckLicensePlateNumber'],
        'freight',
        constants.ROLES.PRODUCTION_MANAGER
      )
    };
  }

  get PICKUP_FIELDS_EDIT_RESTRICTIONS(): FieldsEditRestrictionsType {
    return {
      [constants.ROLES.ADMIN]: this.ALL_EDITABLE_PICKUP_FIELDS,
      [constants.ROLES.SUPER_ADMIN]: this.ALL_EDITABLE_PICKUP_FIELDS,
      [constants.ROLES.LOGISTICS_MANAGER]: this.ALL_EDITABLE_PICKUP_FIELDS,
      [constants.ROLES.TRANSPORTER_MANAGER]: [],
      [constants.ROLES.PRODUCTION_MANAGER]: ['externalIds']
    };
  }

  get ORDER_FIELDS_EDIT_RESTRICTIONS(): FieldsEditRestrictionsType {
    return {
      [constants.ROLES.ADMIN]: this.ALL_EDITABLE_ORDER_FIELDS,
      [constants.ROLES.SUPER_ADMIN]: this.ALL_EDITABLE_ORDER_FIELDS,
      [constants.ROLES.LOGISTICS_MANAGER]: this.ALL_EDITABLE_ORDER_FIELDS,
      [constants.ROLES.TRANSPORTER_MANAGER]: [],
      [constants.ROLES.PRODUCTION_MANAGER]: ['comment'],
      [constants.ROLES.LOGISTICS_VIEWER]: []
    };
  }

  get BASE_CONFIG_EDIT_RESTRICTIONS(): FieldsEditRestrictionsType {
    return {
      [constants.ROLES.ADMIN]: [
        'deliveryInterval',
        'deliveryIntervalType',
        'freightDeadlineWarningInterval',
        'id',
        'packingAlgorithm',
        'routingMethod',
        'transporterReplyDeadline',
        'unloadTimePerPallet'
      ],
      [constants.ROLES.SUPER_ADMIN]: this.ALL_EDITABLE_BASE_CONFIG_FIELDS,
      [constants.ROLES.LOGISTICS_MANAGER]: [
        'deliveryInterval',
        'deliveryIntervalType',
        'freightDeadlineWarningInterval',
        'id',
        'packingAlgorithm',
        'routingMethod',
        'transporterReplyDeadline',
        'unloadTimePerPallet'
      ],
      [constants.ROLES.TRANSPORTER_MANAGER]: [],
      [constants.ROLES.PRODUCTION_MANAGER]: []
    };
  }
  // #endregion

  // #region fields with module dependencies
  /**
   * This section specifies the extra, ADDED EDITABLE FIELDS for each user-type
   * when the given MODULE/CONFIG IS TURNED ON.
   * The listed fields will be added to the list of restrictions with turned off modules.
   * Not listed roles or modules will not add anything to or affect the editable field-list.
   */

  get FREIGHT_EDIT_MODULE_DEPENDENCIES(): ModuleDependencies {
    return {
      [constants.ROLES.ADMIN]: [
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['timeSlot']
        }
      ],
      [constants.ROLES.SUPER_ADMIN]: [
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['timeSlot']
        }
      ],
      [constants.ROLES.LOGISTICS_MANAGER]: [
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['timeSlot']
        }
      ],
      [constants.ROLES.TRANSPORTER_MANAGER]: [
        {
          moduleKey: constants.MODULE_KEYS.FREIGHT_OFFER,
          configKey: constants.FREIGHT_OFFER_MODULE_KEYS.TRANSPORTER_CAN_SET_DELIVERY_DATE,
          editableFields: ['deliveryDate', 'driverName']
        },
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['timeSlot', 'driverName']
        },
        {
          moduleKey: constants.MODULE_KEYS.FINALIZE_FREIGHT,
          configKey: undefined,
          editableFields: ['status']
        }
      ],
      [constants.ROLES.PRODUCTION_MANAGER]: [
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['timeSlot']
        }
      ]
    };
  }

  get PICKUP_EDIT_MODULE_DEPENDENCIES(): ModuleDependencies {
    return {
      [constants.ROLES.ADMIN]: [
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['timeSlot']
        }
      ],
      [constants.ROLES.SUPER_ADMIN]: [
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['timeSlot']
        }
      ],
      [constants.ROLES.LOGISTICS_MANAGER]: [
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['timeSlot']
        }
      ],
      [constants.ROLES.PRODUCTION_MANAGER]: [
        {
          moduleKey: constants.MODULE_KEYS.SITE,
          configKey: constants.SITE_MODULE_CONFIG_KEYS.PRODUCTION_MANAGER_CAN_PASS_FREIGHTS,
          editableFields: ['status']
        },
        {
          moduleKey: constants.MODULE_KEYS.SITE,
          configKey: constants.SITE_MODULE_CONFIG_KEYS.ONLY_PRODUCTION_MANAGER_CAN_EDIT_PICKUPS,
          editableFields: this.ALL_EDITABLE_PICKUP_FIELDS
        },
        {
          moduleKey: constants.MODULE_KEYS.SITE,
          configKey: constants.SITE_MODULE_CONFIG_KEYS.PRODUCTION_MANAGER_CAN_SCHEDULE_PICKUPS,
          editableFields: ['status']
        },
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['status', 'timeSlot']
        }
      ]
    };
  }

  get EDIT_ORDER_MANUALLY_FIELDS(): EditableFields {
    return [
      {
        orderItems: [
          'itemId',
          'name',
          'kg',
          'quantity',
          'palletType',
          'palletsCount',
          'unit',
          'externalIds'
        ]
      },
      'customer',
      'deliveryAddress',
      'deliveryToSite',
      'factoryShortName'
    ];
  }

  get ORDER_EDIT_MODULE_DEPENDENCIES(): ModuleDependencies {
    return {
      [constants.ROLES.SUPER_ADMIN]: [
        {
          moduleKey: constants.MODULE_KEYS.BASE,
          configKey: constants.BASE_MODULE_CONFIG_KEYS.EDIT_ORDER_MANUALLY,
          editableFields: this.EDIT_ORDER_MANUALLY_FIELDS
        },
        {
          moduleKey: constants.MODULE_KEYS.BASE,
          configKey: constants.BASE_MODULE_CONFIG_KEYS.EDIT_ORDER_PREFERRED_TRUCKS,
          editableFields: ['preferredTrucks']
        }
      ],
      [constants.ROLES.ADMIN]: [
        {
          moduleKey: constants.MODULE_KEYS.BASE,
          configKey: constants.BASE_MODULE_CONFIG_KEYS.EDIT_ORDER_MANUALLY,
          editableFields: this.EDIT_ORDER_MANUALLY_FIELDS
        },
        {
          moduleKey: constants.MODULE_KEYS.BASE,
          configKey: constants.BASE_MODULE_CONFIG_KEYS.EDIT_ORDER_PREFERRED_TRUCKS,
          editableFields: ['preferredTrucks']
        }
      ],
      [constants.ROLES.LOGISTICS_MANAGER]: [
        {
          moduleKey: constants.MODULE_KEYS.BASE,
          configKey: constants.BASE_MODULE_CONFIG_KEYS.EDIT_ORDER_MANUALLY,
          editableFields: this.changeRestrictionsByConfig(
            this.EDIT_ORDER_MANUALLY_FIELDS,
            'order',
            constants.ROLES.LOGISTICS_MANAGER
          )
        },
        {
          moduleKey: constants.MODULE_KEYS.BASE,
          configKey: constants.BASE_MODULE_CONFIG_KEYS.EDIT_ORDER_PREFERRED_TRUCKS,
          editableFields: ['preferredTrucks']
        }
      ],
      [constants.ROLES.LOGISTICS_VIEWER]: [
        {
          moduleKey: constants.MODULE_KEYS.BASE,
          configKey: constants.BASE_MODULE_CONFIG_KEYS.EDIT_ORDER_MANUALLY,
          editableFields: this.changeRestrictionsByConfig(
            [],
            'order',
            constants.ROLES.LOGISTICS_VIEWER
          )
        }
      ],
      [constants.ROLES.PRODUCTION_MANAGER]: [
        {
          moduleKey: constants.MODULE_KEYS.SITE,
          configKey: constants.SITE_MODULE_CONFIG_KEYS.PRODUCTION_MANAGER_CAN_SET_RELEASE_DATES,
          editableFields: ['releaseDate', { orderItems: ['id', 'releaseDate'] }]
        }
      ]
    };
  }

  get BASE_CONFIG_MODULE_DEPENDENCIES(): ModuleDependencies {
    return {
      [constants.ROLES.SUPER_ADMIN]: [
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['lengthOfTimeSlot', 'openingHours']
        },
        {
          moduleKey: constants.MODULE_KEYS.SHIPPING_NOTIFICATION,
          configKey: undefined,
          editableFields: ['shippingNotificationConfig', 'proxyConfig']
        }
      ],
      [constants.ROLES.ADMIN]: [
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['lengthOfTimeSlot', 'openingHours']
        },
        {
          moduleKey: constants.MODULE_KEYS.SHIPPING_NOTIFICATION,
          configKey: undefined,
          editableFields: ['shippingNotificationConfig']
        }
      ],
      [constants.ROLES.LOGISTICS_MANAGER]: [
        {
          moduleKey: constants.MODULE_KEYS.TIME_SLOT,
          configKey: undefined,
          editableFields: ['lengthOfTimeSlot', 'openingHours']
        }
      ]
    };
  }
}
