// eslint-disable-next-line import/namespace
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

Vue.use({
  install(Vue) {
    /** Translates the value
     * @param {Object} value - Object that contains localized strings, e.g. {en:'dog',hu:'kutya'}
     * @returns {string}
     */
    Vue.prototype.$tv = function (value: Record<string, string>) {
      return value && (value[this.$i18n.locale] || value.hu || value);
    };
  }
});

function loadLocaleMessages() {
  const locales = [
    require.context('../../../lrpm/src/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i),
    require.context('../../../server/src/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  ];

  const messages: any = {};
  locales.forEach((locale) => {
    locale.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const lang = matched[1];
        if (!messages[lang]) {
          messages[lang] = {};
        }
        messages[lang] = { ...messages[lang], ...locale(key) };
      }
    });
  });

  return messages;
}

const i18n = new VueI18n({
  locale: 'hu',
  fallbackLocale: 'hu',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true
});

export default i18n;
