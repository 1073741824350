import ApiService from '../services/ApiService';
import { ActionTree, GetterTree, MutationTree, Module } from 'vuex';
import { RootState } from '.';

type ConfigType = {
  key: string;
  name: string;
  description: string;
  value: any;
  type: { type: string };
};

type ModuleType = {
  id: string;
  key: string;
  name: string;
  description: string;
  active: boolean;
  config: ConfigType[];
};

type State = {
  modules: ModuleType[];
  editedModule?: ModuleType;
};

const state: State = {
  modules: [],
  editedModule: undefined
};

const actions: ActionTree<State, RootState> = {
  async getAll({ commit }) {
    const { data: modules } = await ApiService.getModules();
    commit('setModules', modules);
  },

  edit({ commit }, moduleId) {
    const module = state.modules.find((module) => moduleId === module.id);
    commit('edit', JSON.parse(JSON.stringify(module)));
  },

  async save({ commit, dispatch }) {
    await ApiService.saveModule(state.editedModule);
    await dispatch('getAll');
    await dispatch('module/getActiveModules', undefined, { root: true });
    commit('cancel');
  },

  cancel({ commit }) {
    commit('cancel');
  }
};

const mutations: MutationTree<State> = {
  setModules(state, payload) {
    state.modules = payload;
  },

  edit(state, payload) {
    state.editedModule = { ...payload };
  },

  cancel(state) {
    state.editedModule = undefined;
  }
};

const getters: GetterTree<State, RootState> = {
  modules: (state) => state.modules,
  editedModule: (state) => state.editedModule
};

export const moduleSettings: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
