/**
 *
 * @param congruent
 * @param modulus
 * @returns the remainder of the congruent % modulus operation
 *
 * This function is needed because in some cases the '%' operation gives wrong results.
 * Cause of this misfunctionality is that sometimes numbers are represented in the way like below:
 *      1228.032 % 102.336 = 12 (real result)
 *      1228.032 % 102.336 = 102.33599999999994 (typeScript result)
 *      it is because 1228.032 / 102.336 not represented as 12 but 11.99999 etc.
 *
 * See more: https://stackoverflow.com/questions/37662162/javascript-modulo-behaving-strange
 */

export function modulus(congruent: number, modulus: number): number {
  if (congruent % 1 === 0 && modulus % 1 === 0) {
    return congruent % modulus;
  } else {
    const congruentMultiplicator = getMultiplicatorByFloatingDigits(congruent);
    const modulusMultiplicator = getMultiplicatorByFloatingDigits(modulus);
    const maximumMultiplicator = Math.max(congruentMultiplicator, modulusMultiplicator);
    return divide(
      (congruent * maximumMultiplicator) % (modulus * maximumMultiplicator),
      maximumMultiplicator
    );
  }
}

/**
 * Multiplies two numbers and returns the result
 * It's a workaround for the JS floating point multiplication problem
 */
export function multiply(xNum: number, yNum: number): number {
  const xMultiplicator = getMultiplicatorByFloatingDigits(xNum);
  const yMultiplicator = getMultiplicatorByFloatingDigits(yNum);
  // we can safely use round here because we are sure that the result will be an integer
  const multipliedX = Math.round(xNum * xMultiplicator);
  const multipliedY = Math.round(yNum * yMultiplicator);
  return (multipliedX * multipliedY) / (xMultiplicator * yMultiplicator);
}

/**
 * Divides two numbers and returns the result
 * It's a workaround for the JS floating point division problem
 */
export function divide(xNum: number, yNum: number): number {
  const xMultiplicator = getMultiplicatorByFloatingDigits(xNum);
  const yMultiplicator = getMultiplicatorByFloatingDigits(yNum);
  const maxMultiplicator = Math.max(xMultiplicator, yMultiplicator);
  // we can safely use round here because we are sure that the result will be an integer
  const dividedX = Math.round(xNum * maxMultiplicator);
  const dividedY = Math.round(yNum * maxMultiplicator);
  return dividedX / dividedY;
}

/**
 * Add numbers and returns the result
 * It's a workaround for the JS floating point addition problem
 */
export function add(...numbers: number[]): number {
  let sum = 0;
  let maxMultiplicator = 0;
  numbers.forEach((number) => {
    const currentMultiplicator = getMultiplicatorByFloatingDigits(number);
    if (currentMultiplicator > maxMultiplicator) {
      maxMultiplicator = getMultiplicatorByFloatingDigits(number);
    }
  });
  numbers.forEach((number) => {
    sum += Math.round(number * maxMultiplicator);
  });
  return divide(sum, maxMultiplicator);
}

/**
 * Subtract numbers and returns the result
 * It's a workaround for the JS floating point subtraction problem
 */
export function subtract(xNum: number, yNum: number): number {
  const xMultiplicator = getMultiplicatorByFloatingDigits(xNum);
  const yMultiplicator = getMultiplicatorByFloatingDigits(yNum);
  const maxMultiplicator = Math.max(xMultiplicator, yMultiplicator);

  const x = Math.round(xNum * maxMultiplicator);
  const y = Math.round(yNum * maxMultiplicator);
  const difference = x - y;
  return divide(difference, maxMultiplicator);
}

/**
 * Returns the integer value that is needed to multiply
 * the number `num` to get an integer
 */
function getMultiplicatorByFloatingDigits(num: number) {
  if (num % 1 === 0) {
    return 1;
  }
  return Math.pow(10, num.toString().split('.')[1].length);
}
