<template>
  <v-menu
    v-model="showTimePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <gli-text-field
        ref="input"
        :disabled="disabled"
        :label="label"
        append-icon="mdi-clock-time-four-outline"
        :hint="'Formátum: 8:00'"
        v-model="valueTime"
        :rules="[...(rules || [])]"
      >
        <template v-slot:append v-if="!disabled">
          <v-icon v-on="on">mdi-clock-time-four-outline</v-icon>
        </template>
      </gli-text-field>
    </template>
    <v-time-picker
      no-title
      close
      v-model="valueTime"
      format="24hr"
      :max="maxTime"
      :min="minTime"
      @click:minute="showTimePicker = false"
    >
    </v-time-picker>
  </v-menu>
</template>

<style scoped>
.v-chip.v-size--default {
  font-size: 12px;
}
</style>

<script>
export default {
  name: 'TimeField',
  props: {
    value: String,
    label: String,
    maxTime: String,
    minTime: String,
    rules: Array,
    disabled: Boolean
  },

  data() {
    return {
      showTimePicker: false,
      valueTime: this.value
    };
  },

  mounted() {},

  computed: {},

  methods: {},

  watch: {
    valueTime() {
      this.$emit('input', this.valueTime);
    }
  }
};
</script>
