import { ActionTree, Module, MutationTree } from 'vuex';
import { RootState } from '.';

type State = {
  editedOrderId?: string;
  editedFreightId?: string;
};

const state: State = {
  editedOrderId: undefined,
  editedFreightId: undefined
};

const actions: ActionTree<State, RootState> = {
  setEditedOrderId({ commit }, orderId: string) {
    commit('setEditedOrderId', orderId);
  },
  setEditedFreightId({ commit }, freightId: string) {
    commit('setEditedFreightId', freightId);
  }
};

const mutations: MutationTree<State> = {
  setEditedOrderId(state, orderId: string) {
    state.editedOrderId = orderId;
  },
  setEditedFreightId(state, freightId: string) {
    state.editedFreightId = freightId;
  }
};

export const freightDetails: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
