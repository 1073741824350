<template>
  <v-card class="card" color="#757575">
    <v-row class="pa-0 ma-0">
      <v-col cols="2" class="pa-0">
        <v-icon x-large class="pa-3 justify-center white--text">mdi-truck-delivery-outline</v-icon>
      </v-col>
      <v-col cols="10" class="pa-0 pl-2">
        <v-row
          v-if="manualCombineResultCardMessage"
          class="mt-2 mr-3 px-0 white--text font-weight-bold justify-end"
        >
          {{ manualCombineResultCardMessage }}
        </v-row>
        <v-row class="mb-2 mr-2 justify-end">
          <router-link
            v-if="lastProcessingFreightIds"
            tag="button"
            :to="{ name: 'Freights', query: { freightId: lastProcessingFreightIds.join(', ') } }"
            ><v-btn light>Szűrés</v-btn></router-link
          >
          <v-btn class="ml-2" color="primary" @click="closeCard">OK</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ManualCombineResultCard',
  data() {
    return {};
  },
  methods: {
    ...mapActions('app', ['hideManualCombineResultCard']),

    closeCard() {
      this.hideManualCombineResultCard();
    }
  },
  computed: { ...mapGetters('app', ['lastProcessingFreightIds', 'manualCombineResultCardMessage']) }
};
</script>
