import { store } from '../store';

export function authHeader() {
  if (store.state.account.authDisabled) {
    return {};
  }

  // return authorization header with jwt token
  let token = window.$cookies.get('token');
  if (token) {
    return { Authorization: 'Bearer ' + token };
  } else {
    return {};
  }
}
