/* eslint-disable max-len */
const DUMMY_PRODUCT = {
  priority: 0,
  itemId: 'T-000000',
  name: 'Dummy termék',
  unit: 'kg',
  kgPerUnit: 1,
  kgPerPallet: 1,
  unitPerPallet: 1,
  palletType: 'EURO'
};

const EVENT_OBJECTS = {
  AUTO_PROCESSING: 'auto_processing',
  BASE_CONFIG: 'base_config',
  CALENDAR_SETTINGS: 'calendar_settings',
  CAPACITY_ESTIMATE: 'capacity_estimate',
  DEPOT: 'depot',
  DOCK: 'dock',
  EXTERNAL_IDS: 'external_ids',
  FACTORY: 'factory',
  FLEET: 'fleet',
  FREIGHT: 'freight',
  FREIGHT_COST: 'freight_cost',
  ORDER: 'order',
  PALLET_FEE: 'pallet_fee',
  PICKUP: 'pickup',
  PRODUCTS: 'products',
  TRANSPORTER: 'transporter',
  USER: 'user',
  VEHICLE: 'vehicle',
  WAYPOINT_COST: 'waypoint_cost'
};

const EVENT_ACTIONS = {
  CREATED: 'created',
  CHANGED: 'changed',
  DELETED: 'deleted',
  FREIGHT_FINALIZED: 'freight_finalized',
  FREIGHT_FINALIZATION_REVOKED: 'freight_finalization_revoked',
  OFFER_REQUEST_REJECTED: 'offer_request_rejected',
  OFFER_REQUEST_SENT: 'offer_request_sent',
  OFFER_SENT: 'offer_sent'
};

const EVENT_FIELDS = {
  ADDRESS: 'address',
  AVAILABLE_VEHICLES: 'available_vehicles',
  INACTIVE_VEHICLES: 'inactive_vehicles',
  BILLING_DATA: 'billing_data',
  CAPACITY: 'capacity',
  COMMENT: 'comment',
  COMPANY_NAME: 'company_name',
  CONTACT_PERSON_EMAIL: 'contact_person_email',
  CONTACT_PERSON_NAME: 'contact_person_name',
  CONTACT_PERSON_PHONE: 'contact_person_phone',
  COST_CENTRE: 'cost_centre',
  CUSTOMER_EMAIL: 'customer_email',
  CUSTOMER_NAME: 'customer_name',
  CUSTOMER_PHONE: 'customer_phone',
  DELIVERY_ADDRESS: 'delivery_address',
  ZIP: 'zip',
  DELIVERY_DATE: 'delivery_date',
  DELIVERY_INTERVAL: 'delivery_interval',
  DELIVERY_INTERVAL_TYPE: 'delivery_interval_type',
  DELIVERY_TO_SITE: 'delivery_to_site',
  DEPOT: 'depot',
  DEPOTS: 'depots',
  DOCK: 'dock',
  DRIVER_NAME: 'driver_name',
  EMAIL: 'email',
  EXTERNAL_IDS: 'external_ids',
  EXCLUDED_FROM_AUTOMATIC_PROCESSING: 'excluded_from_automatic_processing',
  EXPECTED_DEADLINE_DATE: 'expected_deadline_date',
  FACTORIES: 'factories',
  FACTORY_SHORTNAME: 'factory_shortname',
  FIRST_NAME: 'first_name',
  FIRST_RELEASE_DATE_ESTIMATION: 'first_release_date_estimation',
  FREIGHT_CARGO_SPLIT: 'freight_cargo_split',
  FREIGHT_COST: 'freight_cost',
  FREIGHT_INCOME: 'freight_income',
  FREIGHT_DEADLINE_WARNING_INTERVAL: 'freight_deadline_warning_interval',
  FUZZY_SEARCH: 'fuzzy_search',
  LAST_NAME: 'last_name',
  LENGTH_OF_TIME_SLOT: 'length_of_time_slot',
  NAME: 'name',
  OPENING_HOURS: 'opening_hours',
  ORDER_DELETED_FROM_FREIGHT: 'order_deleted_from_freight',
  ORDER_ITEMS: 'order_items',
  PACKING_ALGORITHM: 'packing_algorithm',
  PASSWORD: 'password',
  PREFERRED_TRUCKS: 'preferred_trucks',
  PICKUP_CARGO_SPLIT: 'pickup_cargo_split',
  PICKUP_COST: 'pickup_cost',
  PICKUP_INCOME: 'pickup_income',
  PRODUCT_RELEASE_DATE: 'product_release_date',
  PROXY_CONFIG: 'proxy',
  RELEASE_DATE: 'release_date',
  ROLE: 'role',
  ROUTING_METHOD: 'routing_method',
  SHIPPING_NOTIFICATION_CONFIG: 'shipping_notification_config',
  SHORT_NAME: 'short_name',
  STATUS: 'status',
  TIME_SLOT: 'time_slot',
  TIME_SLOT_RESERVATION_LIMIT: 'time_slot_reservation_limit',
  TIME_SLOT_WIDTH: 'time_slot_width',
  TRANSPORTER: 'transporter',
  TRUCK_LICENSE_PLATE_NUMBER: 'truck_license_plate_number',
  TRANSPORTER_REPLY_DEADLINE: 'transporter_reply_deadline',
  UNLOAD_TIME_PER_PALLET: 'unload_time_per_pallet',
  USERNAME: 'username'
};

const ROLES = {
  SUPER_ADMIN: 'superadmin',
  ADMIN: 'admin',
  LOGISTICS_MANAGER: 'logistics manager',
  LOGISTICS_VIEWER: 'logistics viewer',
  PRODUCTION_MANAGER: 'production manager',
  PRODUCTION_VIEWER: 'production viewer',
  TRANSPORTER_MANAGER: 'transporter manager',
  TRANSPORTER_VIEWER: 'transporter viewer',
  ENTRANCE_SUPERVISOR: 'entrance supervisor'
};

const ALL_ROLES = Object.values(ROLES);

const ADMIN_ROLES = [ROLES.SUPER_ADMIN, ROLES.ADMIN];

const MANAGER_ROLES = [
  ROLES.LOGISTICS_MANAGER,
  ROLES.PRODUCTION_MANAGER,
  ROLES.TRANSPORTER_MANAGER
];

const EDITOR_ROLES = [...ADMIN_ROLES, ROLES.LOGISTICS_MANAGER];
const EXTENDED_EDITOR_ROLES = [
  ...EDITOR_ROLES,
  ROLES.PRODUCTION_MANAGER,
  ROLES.TRANSPORTER_MANAGER
];

const VIEWER_ROLES = [ROLES.LOGISTICS_VIEWER, ROLES.PRODUCTION_VIEWER, ROLES.TRANSPORTER_VIEWER];

const LOGISTICS_ROLES = [ROLES.LOGISTICS_MANAGER, ROLES.LOGISTICS_VIEWER];

const PRODUCTION_ROLES = [ROLES.PRODUCTION_MANAGER, ROLES.PRODUCTION_VIEWER];

const TRANSPORTER_ROLES = [ROLES.TRANSPORTER_MANAGER, ROLES.TRANSPORTER_VIEWER];

const MODULE_KEYS = {
  BASE: 'base',
  BILLING: 'billing',
  CAPACITY_ESTIMATE: 'capacity_estimate',
  CUSTOMER_NOTIFICATION: 'customer_notification',
  DUMMY: 'dummy',
  EXTENDED_VISUALIZATION: 'extended_visualization',
  FINALIZE_FREIGHT: 'finalize_freight',
  FREIGHT_OFFER: 'freight_offer',
  FREIGHT_WAYPOINTS_EDIT: 'freight_waypoints_edit',
  ORDER_CLOSURE: 'order_closure',
  ORDER_EXCEL_IMPORT: 'order_excel_import',
  PICKUP: 'pickup',
  PRODUCT_CATALOG: 'product_catalog',
  ROUTE_PLANNER: 'route_planner',
  SHIPPING_NOTIFICATION: 'shipping_notification',
  SITE: 'site',
  STATISTICS: 'statistics',
  TIME_SLOT: 'time_slot',
  TRANSPORTER: 'transporter'
};

const BASE_MODULE_CONFIG_KEYS = {
  ADD_NEW_ORDER_MANUALLY: 'add_new_order_manually',
  DELETE_ORDER: 'delete_order',
  EDIT_ORDER_MANUALLY: 'edit_order_manually',
  EDIT_ORDER_PREFERRED_TRUCKS: 'edit_order_preferred_trucks',
  EDIT_VEHICLE_DATA: 'edit_vehicle_data',
  FREIGHT_COST_BASED_ON_CARGO_WEIGHT: 'freight_cost_based_on_cargo_weight',
  FREIGHT_COST_CALCULATED_FOR_DEFAULT_TRUCKS_ONLY: 'freight_cost_calculated_for_default_truck_only',
  FREIGHT_COST_CALCULATED_FOR_DELIVERY_TO_SITE: 'freight_cost_calculated_for_delivery_to_site',
  FREIGHT_COST_USE_PALLET_FEES: 'freight_cost_use_pallet_fees',
  FREIGHT_COST_USE_WAYPOINT_COSTS: 'freight_cost_use_waypoint_costs',
  FREIGHT_DOWNLOAD_PACKING_PLAN: 'freight_download_packing_plan',
  FREIGHT_DOWNLOAD_TRANSPORT_PLAN: 'freight_download_transport_plan',
  LOGISTICS_MANAGER_CAN_MODIFY_BASE_CONFIG: 'logistics_manager_can_modify_base_config',
  LOGISTICS_MANAGER_CAN_SET_AUTOMATIC_PROCESSING: 'logistics_manager_can_set_automatic_processing',
  ORDER_GROSS_TOTAL_WEIGHT: 'order_gross_total_weight',
  PREFER_REQUESTED_DATE: 'prefer_requested_date',
  PRODUCT_PRIORITY: 'product_priority',
  SAVE_WAYPOINT_RESTRICTIONS: 'save_waypoint_restrictions',
  ORDER_ITEMS_RELEASE_DATE_FOLLOWS_ORDER_RELEASE_DATE:
    'order_items_release_date_follows_order_release_date',
  AUTOMATIC_PLANNING_ON_MODIFY_INACTIVE_ORDERS: 'automatic_planning_on_modify_inactive_orders'
};

const CUSTOMER_NOTIFICATION_MODULE_CONFIG_KEYS = {
  SEND_NOTIFICATION_WITH_PAST_DELIVERY_DATE: 'send_notification_with_past_delivery_date'
};

const FREIGHT_OFFER_MODULE_KEYS = {
  DELIVERY_DATE_IN_OFFER: 'delivery_date_in_offer',
  TRANSPORTER_CAN_SET_DELIVERY_DATE: 'transporter_can_set_delivery_date',
  TRANSPORTER_TENDER: 'transporter_tender',
  AUTOMATIC_FREIGHT_ACCEPTANCE: 'automatic_freight_acceptance',
  TRANSPORTER_CAN_GIVE_COUNTEROFFER: 'transporter_can_give_counteroffer',
  LOGISTICS_MANAGER_COMMENT_IN_OFFER: 'logistics_manager_comment_in_offer'
};

const FINALIZE_FREIGHT_MODULE_KEYS = {
  SET_PLATE_AND_DRIVER_AT_FINALIZED_STATUS: 'set_plate_and_driver_at_finalized_status'
};

const SITE_MODULE_CONFIG_KEYS = {
  ONLY_PRODUCTION_MANAGER_CAN_EDIT_PICKUPS: 'only_production_manager_can_edit_pickups',
  ONLY_PRODUCTION_MANAGER_CAN_PASS_FREIGHTS: 'only_production_manager_can_pass_freights',
  PRODUCTION_MANAGER_CAN_SET_RELEASE_DATES: 'production_manager_can_set_release_dates',
  PRODUCTION_MANAGER_CAN_PASS_FREIGHTS: 'production_manager_can_pass_freights',
  PRODUCTION_MANAGER_CAN_SPLIT_FREIGHTS: 'production_manager_can_split_freights',
  PRODUCTION_MANAGER_CAN_SCHEDULE_FREIGHTS: 'production_manager_can_schedule_freights',
  PRODUCTION_MANAGER_CAN_SCHEDULE_PICKUPS: 'production_manager_can_schedule_pickups',
  PRODUCTION_USER_CAN_ACCESS_STATISTICS: 'production_user_can_access_statistics'
};

const EXTENDED_VISUALIZATION_MODULE_CONFIG_KEYS = {
  INCLUDE_PLAN_IN_PDF: 'include_plan_in_pdf',
  SHOW_PLAN_IN_FREIGHT_VIEW: 'show_plan_in_freight_view'
};

const ORDER_CLOSURE_MODULE_CONFIG_KEYS = {
  ORDER_CLOSURE_API: 'order_closure_api'
};

const PICKUP_MODULE_CONFIG_KEYS = {
  AUTOMATIC_PICKUP_PROCESSING: 'automatic_pickup_processing'
};

const ROUTE_PLANNER_MODULE_CONFIG_KEYS = {
  HANDLE_WEIGHT_RESTRICTED_ZONES: 'handle_weight_restricted_zones'
};

const STATISTICS_MODULE_CONFIG_KEYS = {
  TABLES: 'tables',
  CHARTS: 'charts',
  MAPS: 'maps',
  WIENERBERGER_TABLES: 'wienerberger_tables' // TODO WB
};

const TIME_SLOT_MODULE_CONFIG_KEYS = {
  TIME_SLOT_WIDTH: 'time_slot_width',
  TIME_SLOT_TRANSPORTER_MANAGER_FREE_BOOKING: 'time_slot_transporter_manager_free_booking'
};

const TRANSPORTER_MODULE_CONFIG_KEYS = {
  DELIVERY_STATUS: 'delivery_status',
  FREIGHT_COMMENT: 'freight_comment',
  ONLY_TRANSPORTER_CAN_SET_FREIGHTS_TO_SHIPPED: 'only_transporter_can_set_freights_to_shipped'
};

const SYSTEM_USER = 'system';

const JOB_ID_PREFIXES = {
  PROCESSING: 'process-orders',
  LOAD_PRODUCTS: 'load-products'
};

export default {
  SYSTEM_USER,
  EVENT_ACTIONS,
  EVENT_OBJECTS,
  EVENT_FIELDS,
  ROLES,
  ALL_ROLES,
  ADMIN_ROLES,
  EDITOR_ROLES,
  EXTENDED_EDITOR_ROLES,
  MANAGER_ROLES,
  LOGISTICS_ROLES,
  PRODUCTION_ROLES,
  TRANSPORTER_ROLES,
  VIEWER_ROLES,

  MODULE_KEYS,
  BASE_MODULE_CONFIG_KEYS,
  CUSTOMER_NOTIFICATION_MODULE_CONFIG_KEYS,
  FINALIZE_FREIGHT_MODULE_KEYS,
  FREIGHT_OFFER_MODULE_KEYS,
  SITE_MODULE_CONFIG_KEYS,
  EXTENDED_VISUALIZATION_MODULE_CONFIG_KEYS,
  ORDER_CLOSURE_MODULE_CONFIG_KEYS,
  PICKUP_MODULE_CONFIG_KEYS,
  ROUTE_PLANNER_MODULE_CONFIG_KEYS,
  STATISTICS_MODULE_CONFIG_KEYS,
  TIME_SLOT_MODULE_CONFIG_KEYS,
  TRANSPORTER_MODULE_CONFIG_KEYS,

  CONFIG_MODES: {
    BARABAS: 'barabas',
    LOGISTICS: 'logistics',
    MARKA: 'marka',
    WIENERBERGER: 'wienerberger'
  },

  DUMMY_PRODUCT,

  EVENTS: {
    DEPOT: {
      CREATED: `${EVENT_OBJECTS.DEPOT}.${EVENT_ACTIONS.CREATED}`,
      ADDRESS_CHANGED: `${EVENT_OBJECTS.DEPOT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.ADDRESS}`,
      CAPACITY_CHANGED: `${EVENT_OBJECTS.DEPOT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.CAPACITY}`,
      DOCKS_CHANGED: `${EVENT_OBJECTS.DEPOT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DOCK}`,
      LENGTH_OF_TIME_SLOT_CHANGED: `${EVENT_OBJECTS.DEPOT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.LENGTH_OF_TIME_SLOT}`,
      NAME_CHANGED: `${EVENT_OBJECTS.DEPOT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.NAME}`,
      OPENING_HOURS_CHANGED: `${EVENT_OBJECTS.DEPOT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.OPENING_HOURS}`,
      STATUS_CHANGED: `${EVENT_OBJECTS.DEPOT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.STATUS}`,
      TIME_SLOT_RESERVATION_LIMIT_CHANGED: `${EVENT_OBJECTS.DEPOT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.TIME_SLOT_RESERVATION_LIMIT}`
    },
    DOCK: {
      CREATED: `${EVENT_OBJECTS.DOCK}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.DOCK}.${EVENT_ACTIONS.DELETED}`,
      NAME_CHANGED: `${EVENT_OBJECTS.DOCK}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.NAME}`
    },
    EXTERNAL_IDS: {
      CHANGED: `${EVENT_OBJECTS.EXTERNAL_IDS}.${EVENT_ACTIONS.CHANGED}`
    },
    FACTORY: {
      CREATED: `${EVENT_OBJECTS.FACTORY}.${EVENT_ACTIONS.CREATED}`,
      ADDRESS_CHANGED: `${EVENT_OBJECTS.FACTORY}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.ADDRESS}`,
      DEPOT_CHANGED: `${EVENT_OBJECTS.FACTORY}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DEPOT}`,
      NAME_CHANGED: `${EVENT_OBJECTS.FACTORY}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.NAME}`,
      STATUS_CHANGED: `${EVENT_OBJECTS.FACTORY}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.STATUS}`
    },
    FREIGHT: {
      CREATED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.DELETED}`,
      BILLING_DATA_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.BILLING_DATA}`,
      CARGO_HAS_SPLIT: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.FREIGHT_CARGO_SPLIT}`,
      COMMENT_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.COMMENT}`,
      COST_CENTRE_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.COST_CENTRE}`,
      DELIVERY_DATE_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DELIVERY_DATE}`,
      DOCK: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DOCK}`,
      DRIVER_NAME_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DRIVER_NAME}`,
      EXCLUDED_FROM_AUTOMATIC_PROCESSING_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.EXCLUDED_FROM_AUTOMATIC_PROCESSING}`,
      EXTERNAL_IDS_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.EXTERNAL_IDS}`,
      FREIGHT_COST_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.FREIGHT_COST}`,
      FREIGHT_INCOME_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.FREIGHT_INCOME}`,
      FREIGHT_OFFER_STATUS_FINALIZATION_REVOKED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.FREIGHT_FINALIZATION_REVOKED}`,
      FREIGHT_OFFER_STATUS_FINALIZED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.FREIGHT_FINALIZED}`,
      OFFER_REQUEST_REJECTED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.OFFER_REQUEST_REJECTED}`,
      OFFER_REQUEST_SENT: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.OFFER_REQUEST_SENT}`,
      OFFER_SENT: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.OFFER_SENT}`,
      ORDER_DELETED_FROM_FREIGHT: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.ORDER_DELETED_FROM_FREIGHT}`,
      STATUS_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.STATUS}`,
      TIME_SLOT: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.TIME_SLOT}`,
      TIME_SLOT_WIDTH: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.TIME_SLOT_WIDTH}`,
      TRANSPORTER_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.TRANSPORTER}`,
      TRUCK_LICENSE_PLATE_NUMBER_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.TRUCK_LICENSE_PLATE_NUMBER}`,
      TRUCK_TYPE_CHANGED: `${EVENT_OBJECTS.FREIGHT}.${EVENT_ACTIONS.CHANGED}.truck_type`
    },
    PICKUP: {
      CREATED: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.DELETED}`,
      CARGO_HAS_SPLIT: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.PICKUP_CARGO_SPLIT}`,
      DELIVERY_DATE_CHANGED: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DELIVERY_DATE}`,
      DOCK: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DOCK}`,
      DRIVER_NAME_CHANGED: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DRIVER_NAME}`,
      EXTERNAL_IDS_CHANGED: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.EXTERNAL_IDS}`,
      PICKUP_COST_CHANGED: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.PICKUP_COST}`,
      PICKUP_INCOME_CHANGED: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.PICKUP_INCOME}`,
      STATUS_CHANGED: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.STATUS}`,
      TIME_SLOT: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.TIME_SLOT}`,
      TIME_SLOT_WIDTH: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.TIME_SLOT_WIDTH}`,
      TRUCK_LICENSE_PLATE_NUMBER_CHANGED: `${EVENT_OBJECTS.PICKUP}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.TRUCK_LICENSE_PLATE_NUMBER}`
    },
    ORDER: {
      CREATED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.DELETED}`,
      COMMENT_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.COMMENT}`,
      CUSTOMER_EMAIL_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.CUSTOMER_EMAIL}`,
      CUSTOMER_NAME_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.CUSTOMER_NAME}`,
      CUSTOMER_PHONE_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.CUSTOMER_PHONE}`,
      DELIVERY_ADDRESS_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DELIVERY_ADDRESS}`,
      ZIP_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.ZIP}`,
      DELIVERY_TO_SITE_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DELIVERY_TO_SITE}`,
      EXCLUDED_FROM_AUTOMATIC_PROCESSING_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.EXCLUDED_FROM_AUTOMATIC_PROCESSING}`,
      EXPECTED_DEADLINE_DATE_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.EXPECTED_DEADLINE_DATE}`,
      EXTERNAL_IDS_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.EXTERNAL_IDS}`,
      FACTORY_SHORTNAME_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.FACTORY_SHORTNAME}`,
      FIRST_RELEASE_DATE_ESTIMATION: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.FIRST_RELEASE_DATE_ESTIMATION}`,
      ORDER_ITEMS_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.ORDER_ITEMS}`,
      PREFERRED_TRUCKS_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.PREFERRED_TRUCKS}`,
      PRODUCT_RELEASE_DATE_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.PRODUCT_RELEASE_DATE}`,
      RELEASE_DATE_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.RELEASE_DATE}`,
      STATUS_CHANGED: `${EVENT_OBJECTS.ORDER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.STATUS}`
    },
    USER: {
      CREATED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.DELETED}`,
      DEPOTS_CHNAGED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DEPOTS}`,
      EMAIL_CHANGED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.EMAIL}`,
      FACTORIES_CHANGED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.FACTORIES}`,
      FIRST_NAME_CHANGED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.FIRST_NAME}`,
      LAST_NAME_CHANGED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.LAST_NAME}`,
      PASSWORD_CHANGED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.PASSWORD}`,
      ROLE_CHANGED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.ROLE}`,
      TRANSPORTER_CHANGED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.TRANSPORTER}`,
      USERNAME_CHANGED: `${EVENT_OBJECTS.USER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.USERNAME}`
    },
    BASE_CONFIG: {
      DELIVERY_INTERVAL_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DELIVERY_INTERVAL}`,
      DELIVERY_INTERVAL_TYPE_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.DELIVERY_INTERVAL_TYPE}`,
      FREIGHT_DEADLINE_WARNING_INTERVAL_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.FREIGHT_DEADLINE_WARNING_INTERVAL}`,
      FUZZY_SEARCH_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.FUZZY_SEARCH}`,
      LENGTH_OF_TIME_SLOT_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.LENGTH_OF_TIME_SLOT}`,
      OPENING_HOURS_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.OPENING_HOURS}`,
      PACKING_ALGORITHM_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.PACKING_ALGORITHM}`,
      ROUTING_METHOD_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.ROUTING_METHOD}`,
      PROXY_CONFIG_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.PROXY_CONFIG}`,
      SHIPPING_NOTIFICATION_CONFIG_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.SHIPPING_NOTIFICATION_CONFIG}`,
      TRANSPORTER_REPLY_DEADLINE_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.TRANSPORTER_REPLY_DEADLINE}`,
      UNLOAD_TIME_PER_PALLET_CHANGED: `${EVENT_OBJECTS.BASE_CONFIG}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.UNLOAD_TIME_PER_PALLET}`
    },
    PRODUCTS: {
      UPLOADED: `${EVENT_OBJECTS.PRODUCTS}.${EVENT_ACTIONS.CHANGED}`
    },
    TRANSPORTER: {
      CREATED: `${EVENT_OBJECTS.TRANSPORTER}.${EVENT_ACTIONS.CREATED}`,
      AVAILABLE_VEHICLES_CHANGED: `${EVENT_OBJECTS.TRANSPORTER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.AVAILABLE_VEHICLES}`,
      INACTIVE_VEHICLES_CHANGED: `${EVENT_OBJECTS.TRANSPORTER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.INACTIVE_VEHICLES}`,
      CONTACT_PERSON_EMAIL_CHANGED: `${EVENT_OBJECTS.TRANSPORTER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.CONTACT_PERSON_EMAIL}`,
      CONTACT_PERSON_NAME_CHANGED: `${EVENT_OBJECTS.TRANSPORTER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.CONTACT_PERSON_NAME}`,
      CONTACT_PERSON_PHONE_CHANGED: `${EVENT_OBJECTS.TRANSPORTER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.CONTACT_PERSON_PHONE}`,
      COMPANY_NAME_CHANGED: `${EVENT_OBJECTS.TRANSPORTER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.COMPANY_NAME}`,
      SHORT_NAME_CHANGED: `${EVENT_OBJECTS.TRANSPORTER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.SHORT_NAME}`,
      STATUS_CHANGED: `${EVENT_OBJECTS.TRANSPORTER}.${EVENT_ACTIONS.CHANGED}.${EVENT_FIELDS.STATUS}`
    },
    VEHICLE: {
      CREATED: `${EVENT_OBJECTS.VEHICLE}.${EVENT_ACTIONS.CREATED}`,
      CHANGED: `${EVENT_OBJECTS.VEHICLE}.${EVENT_ACTIONS.CHANGED}`
    },
    FREIGHT_COST: {
      CHANGED: `${EVENT_OBJECTS.FREIGHT_COST}.${EVENT_ACTIONS.CHANGED}`,
      CREATED: `${EVENT_OBJECTS.FREIGHT_COST}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.FREIGHT_COST}.${EVENT_ACTIONS.DELETED}`
    },
    WAYPOINT_COST: {
      CHANGED: `${EVENT_OBJECTS.WAYPOINT_COST}.${EVENT_ACTIONS.CHANGED}`,
      CREATED: `${EVENT_OBJECTS.WAYPOINT_COST}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.WAYPOINT_COST}.${EVENT_ACTIONS.DELETED}`
    },
    PALLET_FEE: {
      CHANGED: `${EVENT_OBJECTS.PALLET_FEE}.${EVENT_ACTIONS.CHANGED}`,
      CREATED: `${EVENT_OBJECTS.PALLET_FEE}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.PALLET_FEE}.${EVENT_ACTIONS.DELETED}`
    },
    CALENDAR_SETTINGS: {
      CHANGED: `${EVENT_OBJECTS.CALENDAR_SETTINGS}.${EVENT_ACTIONS.CHANGED}`,
      CREATED: `${EVENT_OBJECTS.CALENDAR_SETTINGS}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.CALENDAR_SETTINGS}.${EVENT_ACTIONS.DELETED}`
    },
    FLEET: {
      CHANGED: `${EVENT_OBJECTS.FLEET}.${EVENT_ACTIONS.CHANGED}`,
      CREATED: `${EVENT_OBJECTS.FLEET}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.FLEET}.${EVENT_ACTIONS.DELETED}`
    },
    CAPACITY_ESTIMATE: {
      CHANGED: `${EVENT_OBJECTS.CAPACITY_ESTIMATE}.${EVENT_ACTIONS.CHANGED}`,
      CREATED: `${EVENT_OBJECTS.CAPACITY_ESTIMATE}.${EVENT_ACTIONS.CREATED}`,
      DELETED: `${EVENT_OBJECTS.CAPACITY_ESTIMATE}.${EVENT_ACTIONS.DELETED}`
    },
    AUTO_PROCESSING: {
      CHANGED: `${EVENT_OBJECTS.AUTO_PROCESSING}.${EVENT_ACTIONS.CHANGED}`
    }
  },

  DEFAULT_ERROR_MESSAGES: {
    FORBIDDEN: {
      message: 'Nem engedélyezett művelet'
    },
    NOT_FOUND: {
      message: 'Nem található'
    },
    BAD_REQUEST: {
      message: 'Hibás kérés'
    },
    UNAUTHORIZED: {
      message: 'Azonosítás sikertelen'
    },
    SERVER_ERROR: {
      message: 'Belső szerverhiba történt'
    }
  },

  INVALID_ORDER_ERROR_MESSAGES: {
    CANNOT_BE_GEOCODED: 'A szállítási cím nem geokódolható.',
    PRODUCTS_NOT_FOUND: 'A következő cikkszámú termékek nem találhatóak: ',
    DELIVERY_ADDRESS_REQUIRED: `A(z) deliveryAddress mező kötelező.`,
    WRONG_DATES:
      'Egyik visszaigazolt dátum sem lehet későbbi mint a megfelelő szállítási határidők.', // TODO WB
    INVALID_TYPE: `A 'type' mező értéke nem megfelelő.`
  },

  TEST_MESSAGES: {
    PLANNER_RESPONSE: 'The logistics planner should respond in time!',
    PRODUCTS_LOAD: 'Products should be processed in time!'
  },

  PALLET_TYPES: { CARDBOARD_BOX: 'Kartondoboz' },

  ALL_FACTORIES: 'Összes gyár',

  EXPORT: {
    ORDERS: 'orders',
    FREIGHTS: 'freights',
    FREIGHTBILLS: 'freightbills',
    PICKUPS: 'pickups',
    STATS: 'statistics'
  },

  RESTRICTION_COLORS: {
    DEFAULT: `#404040`,
    VIOLATES: `#b50000`
  },

  JOB_ID_PREFIXES
};
