// weird but important
// (https://www.qualdesk.com/blog/2021/type-guard-for-string-union-types-typescript/)
export const PACKING_ALGORITHMS = ['rows-no-stacking', 'palletstack', 'twodimensional'] as const;
export type PackingAlgorithm = typeof PACKING_ALGORITHMS[number];
export const packingAlgorithmNames: {
  text: string;
  value: PackingAlgorithm;
  disabled?: boolean;
}[] = [
  {
    value: PACKING_ALGORITHMS[0],
    text: 'Egyszerű sorok'
  },
  {
    value: PACKING_ALGORITHMS[1],
    text: 'Rétegzett sorok'
  },
  {
    value: PACKING_ALGORITHMS[2],
    text: 'Sík felület lefedése'
  }
];
