import confirm from '../helpers/confirm';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import AuthService from '../services/AuthService';
import { RootState } from '.';

type User = {
  // required fields:
  id?: String;
  username?: String;
  password?: String;
  firstName?: String;
  lastName?: String;
  email?: String;
  createdDate?: Date;
  role?: String;
  eventsQueriedAt?: Date;

  // only for transporter users:
  transporter?: String;

  // only for production users:
  depotShortNames?: String[];
  factoryShortNames?: String[];
};

type State = {
  all: User[];
  showPwd?: boolean;
  isNew: boolean;
  currentUser?: User;
};

const state: State = {
  all: [],
  showPwd: false,
  isNew: false,
  currentUser: undefined
};

const actions: ActionTree<State, RootState> = {
  async getAll({ commit }) {
    const users = await AuthService.getAll();
    commit('getAll', users);
  },

  addNew({ commit }) {
    commit('addNew');
  },

  edit({ commit }, user: User) {
    commit('edit', user);
  },

  async save({ commit, dispatch, state }) {
    if (state.isNew) {
      await AuthService.register(state.currentUser);
      dispatch('alert/success', 'A regisztráció sikeresen megtörtént.', { root: true });
    } else {
      await AuthService.update(state.currentUser);
      dispatch('alert/success', 'A módosítás sikeresen megtörtént.', { root: true });
    }

    dispatch('getAll');
    commit('save');
  },

  async delete({ commit, dispatch }, payload) {
    const answer = await confirm('Biztosan törölni szeretné a felhasználót?', {
      title: 'Felhasználó törlése',
      width: 375,
      buttonTrueText: 'Igen',
      buttonFalseText: 'Nem'
    });

    if (answer) {
      await AuthService.delete(payload.id);
      dispatch('getAll');
      commit('delete');
    }
  },

  async loadRoleBasedData({ dispatch, rootGetters }) {
    const isTransporterModuleActive = rootGetters['module/isTransporterModuleActive'];
    const isSiteModuleModuleActive = rootGetters['module/isSiteModuleActive'];

    if (isTransporterModuleActive) {
      await dispatch('transporters/getAll', null, { root: true });
    }
    if (isSiteModuleModuleActive) {
      await dispatch('depots/get', null, { root: true });
      await dispatch('factories/get', null, { root: true });
    }
  }
};

const mutations: MutationTree<State> = {
  updateField,

  getAll(state, users) {
    state.all = users;
  },

  addNew(state) {
    state.isNew = true;
    state.currentUser = {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      role: 'logistics viewer'
    };
  },

  edit(state, payload) {
    state.currentUser = { ...payload, password: '' };
  },

  cancel(state) {
    state.isNew = false;
    state.currentUser = undefined;
  },

  save(state) {
    state.isNew = false;
    state.currentUser = undefined;
  },

  delete() {
    state.currentUser = undefined;
  }
};

const getters: GetterTree<State, RootState> = {
  getField,
  currentUser: (state) => state.currentUser
};

export const users = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
