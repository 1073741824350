/* eslint-disable no-unused-vars */
import { BaseExternalIds } from './baseExternalIds';
import { Freight, Pickup } from './commissionedUnit';

export enum OrderType {
  Pickup = 'pickup',
  Freight = 'freight'
}

export type Customer = {
  name?: string;
  phone?: string;
  email?: string;
  customerCode?: string;
  recipientName?: string;
  recipientPhone?: string;
};

export type OrderItem = BaseExternalIds & {
  id?: string;
  itemId?: string;
  name?: string;
  palletsCount?: number;
  palletType?: string;
  palletName?: string;
  kg?: number;
  quantity?: number;
  releaseDate?: Date;
  deadlineDate?: Date;
  shipped?: number;
  unit?: string;
};

export type Order = BaseExternalIds & {
  id?: string;
  orderId?: string;
  comment?: string;
  preferredTrucks?: string[];
  customer: Customer;
  deliveryAddress?: string;
  deliveryToSite?: boolean;
  orderDate?: Date;
  orderDeadlineDate?: Date;
  factoryShortName?: string;
  orderItems: OrderItem[];
  releaseDate?: Date;
  expectedDeadlineDate?: Date;
  deliveryCoords?: number[];
  errorMessage?: string;
  status?: string;
  type: OrderType;
  freights?: Freight[];
  pickups?: Pickup[];
};
