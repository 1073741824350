<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-checkbox
          v-model="showEmpty"
          @click="$emit('checkBoxClicked')"
          hide-details
          class="ma-0 pa-0"
        >
        </v-checkbox>
      </span>
    </template>
    <span>Szűrés az üres értékre</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ShowEmptyCheckbox',
  props: {
    value: Boolean
  },
  data() {
    return {
      showEmpty: this.value
    };
  },
  watch: {
    showEmpty() {
      this.$emit('input', this.showEmpty);
    },
    value() {
      this.showEmpty = this.value;
    }
  }
};
</script>
