import ApiService from '../services/ApiService';
import { ActionTree, GetterTree, MutationTree, Module } from 'vuex';
import { RootState } from '.';

type State = {
  events: any[];
};

const state: State = {
  events: []
};

const actions: ActionTree<State, RootState> = {
  async getEvents({ commit }, filter) {
    const { data } = await ApiService.getEvents(filter);
    commit('getEvents', data);
  }
};

const mutations: MutationTree<State> = {
  getEvents(state, payload) {
    state.events = payload;
  }
};

const getters: GetterTree<State, RootState> = {
  events: (state) => state.events
};

export const events: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
