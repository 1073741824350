import scenario_1 from './scenario_1.json';
import scenario_2 from './scenario_2.json';
import scenario_3 from './scenario_3.json';
import scenario_4 from './scenario_4.json';
import scenario_5 from './scenario_5.json';
import addresses from './address.json';

export default {
  scenarios: [scenario_1, scenario_2, scenario_3, scenario_4, scenario_5],
  addresses
};
