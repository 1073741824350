import { ActionTree, MutationTree } from 'vuex';
import { RootState } from './';

type State = {
  type?: string;
  message?: string;
};

const state = {
  type: null,
  message: null
};

const actions: ActionTree<State, RootState> = {
  success({ commit }, message) {
    commit('success', message);
  },
  error({ commit }, message) {
    commit('error', message);
  },
  clear({ commit }, message) {
    commit('success', message);
  }
};

const mutations: MutationTree<State> = {
  success(state, message) {
    state.type = 'alert-success';
    state.message = message;
  },
  error(state, message) {
    state.type = 'alert-danger';
    state.message = message;
  },
  clear(state) {
    state.type = undefined;
    state.message = undefined;
  }
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
};
