<template>
  <v-dialog v-model="showDialog" width="1100" class="overflow-y-auto" eager>
    <v-card v-if="configMode === constants.CONFIG_MODES.LOGISTICS">
      <v-card-title class="headline grey lighten-2"> Teszt forgatókönyvek </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item v-for="(item, i) in scenarios" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="item.description.join('<br/>')"></v-list-item-subtitle>
              Gyár: {{ item.orders[0].factoryShortName }}, Rendelések: {{ item.orders.length }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn color="grey lighten-1" @click="sendScenario(i)" :disabled="loading > -1">
                Beküldés
                <v-progress-circular
                  v-if="loading == i"
                  class="ml-2"
                  indeterminate
                  :size="22"
                  color="info"
                >
                </v-progress-circular>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="headline grey lighten-2"> Rendelések generálása </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                Időszak: az elmúlt
                <input
                  type="number"
                  v-model="days"
                  min="1"
                  max="180"
                  size="4"
                  :disabled="loading > -1"
                />
                napra
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn color="grey lighten-1" @click="sendOrders()" :disabled="loading > -1">
                Generálás
                <v-progress-circular
                  v-if="loading == 99"
                  class="ml-2"
                  :rotate="-90"
                  :value="progress"
                  :width="2"
                  color="info"
                >
                  {{ progress }}%
                </v-progress-circular>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.scroll {
  overflow-y: auto;
  height: 300px !important;
}
</style>

<script>
import moment from 'moment-mini';
import { mapActions, mapGetters, mapState } from 'vuex';
import { getRandomPostfix } from '@/helpers';
import demoScenarios from '../demoScenarios/index';
import ApiService from '../services/ApiService';
import { delay } from '../../../shared/utils/index';

const deliveryIntervalDays = 14;

export default {
  data() {
    return {
      scenarios: demoScenarios.scenarios,
      addresses: demoScenarios.addresses,
      products: [],
      loading: -1,
      days: 30,
      progress: 0
    };
  },
  props: {
    value: Boolean
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    ...mapGetters('app', ['configMode']),
    ...mapState('app', ['config', 'constants']),
    ...mapState('basicSettings', ['deliveryInterval'])
  },
  methods: {
    ...mapActions('alert', ['success']),
    ...mapActions('basicSettings', { getBasicSettings: 'get' }),
    async sendOrders() {
      this.loading = 99;
      this.progress = 0;

      // TODO WB - Wienerberger has addresses in specific format, COS does not fit that format
      /*let places = [
        'Nyíregyháza',
        'Nagykáló',
        'Hajdúhadház',
        'Debrecen',
        'Tiszaújváros',
        'Karcag',
        'Mezőkövesd',
        'Szolnok',
        'Gyöngyös',
        'Hatvan',
        'Eger',
        'Miskolc',
        'Ibrány',
        'Kisvárda',
        'Mátészalka',
        'Tiszalök',
        'Sárospatak',
        'Ózd',
        'Eger',
        'Salgótarján',
        'Cegléd',
        'Putnok',
        'Edelény',
        'Székesfehérvár',
        'Siófok',
        'Enying',
        'Sárbogárd',
        'Veszprém',
        'Várpalota',
        'Zirc',
        'Csákvár',
        'Mór',
        'Oroszlány',
        'Ercsi',
        'Ócsa',
        'Bicske',
        'Érd',
        'Tata',
        'Tatabánya',
        'Győr',
        'Pápa',
        'Tapolca',
        'Dorog',
        'Esztergom',
        'Gödöllő',
        'Ajka',
        'Kaposvár',
        'Pécs',
        'Nagykanizsa',
        'Sopron',
        'Szombathely',
        'Körmend',
        'Makó',
        'Békéscsaba',
        'Orosháza',
        'Csongrád',
        'Szentes',
        'Szarvas',
        'Mezőtúr',
        'Berettyóújfalu',
        'Abony',
        'Kunhegyes',
        'Tiszafüred',
        'Szeged',
        'Baja',
        'Kalocsa',
        'Kiskőrös',
        'Szolnok',
        'Karcag',
        'Mezőkovácsháza',
        'Nagykáta',
        'Dabas',
        'Dunaföldvár',
        'Bácsalmás'
      ];*/

      try {
        await ApiService.autoOrdersProcessing(false);
        await this.getBasicSettings();
        {
          let { data } = await ApiService.getFactories();
          this.factoryShortNames = data.map((factory) => factory.shortName);
        }
        {
          let { data } = await ApiService.getProducts();
          this.products = data;
        }

        let today = new moment();
        let days = this.days;
        let dateFrom = new moment().subtract(1 + Number(days), 'days');
        let orderIds = [];
        let factoryShortNames = [...this.factoryShortNames];
        let processingDays = days;

        while (dateFrom < today) {
          dateFrom.add(1, 'day');

          this.progress = Math.floor((100 * (processingDays - days)) / processingDays);
          days -= 1;
          let day = new moment(dateFrom).day();
          if (day === 0 || day === 6) {
            // is Sunday or Saturday
            continue;
          }
          let orderCount = 1 + Math.random() * 5;
          for (let i = 0; i < orderCount; i++) {
            if (factoryShortNames.length === 0) {
              factoryShortNames = [...this.factoryShortNames];
            }
            let idx = Math.floor(Math.random() * factoryShortNames.length);
            let factoryShortName = factoryShortNames.splice(idx, 1).toString();
            let addresses = [...this.addresses]; // TODO WB - [...places, ...this.addresses]
            let address = addresses[Math.floor(Math.random() * addresses.length)];
            // TODO WB
            const generatedIds = (
              await ApiService.generateUniqueIds({
                ids: ['orderId', 'externalIds.orderNr'],
                factoryShortName
              })
            ).data;
            let order = {
              orderId: generatedIds.orderId,
              customer: {
                name:
                  address
                    .match(/\b(\w)/g)
                    .join('')
                    .replace(/[0-9]/, '')
                    .toUpperCase() +
                  [' Kft.', ' Bt.', ' Zrt.', ' Nyrt.'][Math.floor(Math.random() * 4)],
                phone: '55-55-555-5555',
                email: 'teszt@gli-solutions.com',
                customerCode: '123456'
              },
              deliveryAddress: address,
              deliveryToSite: true,
              orderDate: new moment(dateFrom).subtract(7, 'days').startOf('day'),
              orderDeadlineDate: new moment(dateFrom).add(7, 'days').startOf('day'),
              releaseDate: new moment(dateFrom).startOf('day'),
              expectedDeadlineDate: new moment(dateFrom)
                .add(this.deliveryInterval, 'days')
                .startOf('day'),
              factoryShortName,
              preferredTrucks: [],
              orderItems: [],
              // TODO WB
              productCategory: this.config.productCategory[Math.floor(Math.random() * 3)],
              // TODO WB
              externalIds: {
                orderNr: generatedIds.orderNr,
                incoterm: address.includes('Budapest') ? 'X' : 'Z',
                deliverySpec: ''
              }
            };
            const itemCount = Math.floor(1 + Math.random() * 5);
            while (order.orderItems.length < itemCount) {
              const product = this.products[Math.floor(Math.random() * this.products.length)];
              const productCount = Math.floor(1 + Math.random() * 20);

              // TODO WB
              const previousProductCount = new Set(
                order.orderItems.map((orderItem) => orderItem.itemId)
              ).size;
              const previousCurrentProductCount = order.orderItems.filter(
                (orderItem) => orderItem.itemId === product.itemId
              ).length;

              // TODO WB
              let ssz = previousProductCount + 1;
              let ossz = 1;
              if (previousCurrentProductCount > 0) {
                const sameProductItemNrs = order.orderItems
                  .filter((orderItem) => orderItem.itemId === product.itemId)
                  .map((orderItem) => orderItem.externalIds.itemNr);
                const sameProductSsz = sameProductItemNrs[0].split('-')[2];
                const sameProductOsszMax = sameProductItemNrs
                  .map((itemNr) => itemNr.split('-')[3])
                  .sort((ossz1, ossz2) => parseInt(ossz2) - parseInt(ossz1))[0];
                ssz = sameProductSsz;
                ossz = +sameProductOsszMax + 1;
              }

              const orderItem = {
                itemId: product.itemId,
                name: product.name,
                quantity: product.unitPerPallet * productCount,
                unit: product.unit,
                kg: product.kgPerPallet * productCount,
                palletType: product.palletType,
                palletsCount: productCount,
                // TODO WB
                externalIds: {
                  itemNr: order.externalIds.orderNr + '-' + ssz + '-' + ossz
                }
              };
              order.orderItems.push(orderItem);
            }
            await ApiService.saveOrder(order, { params: { force: true } });
            orderIds.push(order.orderId);
          }
        }
        let { isProcessing } = await ApiService.checkOrdersProcessing();
        while (isProcessing) {
          await delay(5000);
          let { data } = await ApiService.checkOrdersProcessing();
          isProcessing = data.isProcessing;
        }
        // start calc
        await ApiService.calculateNewFreights({
          orderIds,
          options: { manual: true, forceDate: true }
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.progress = 100;
        this.loading = -1;
      }
    },
    async sendScenario(i) {
      this.loading = i;
      let orderIds = {};
      try {
        let { isProcessing } = await ApiService.checkOrdersProcessing();
        while (isProcessing) {
          await delay(5000);
          let { data } = await ApiService.checkOrdersProcessing();
          isProcessing = data.isProcessing;
        }
        await ApiService.autoOrdersProcessing(false);
        const scenario = this.scenarios[i];
        for (const _order of scenario.orders) {
          let order = JSON.parse(JSON.stringify(_order));
          let endDate = new moment().startOf('day');
          endDate.add('days', deliveryIntervalDays);
          order.orderDeadlineDate = endDate;
          order.releaseDate = new moment().startOf('day');
          let day = new moment(order.releaseDate).day();
          let diff = 1; // returns yesterday
          if (day === 0 || day === 1) {
            // is Sunday or Monday
            diff = day + 2; // returns Friday
          }
          order.orderDate = new moment(order.releaseDate).startOf('day').subtract(diff, 'days');
          order.expectedDeadlineDate = order.orderDeadlineDate;

          const oid = order.orderId;
          order.orderId += getRandomPostfix(new moment());
          orderIds[oid] = order.orderId;
          await ApiService.saveOrder(order);
        }
        for (const _freight of scenario.freights) {
          let postData = { ..._freight };
          postData.orderIds = postData.orderIds.map((oid) => orderIds[oid]);
          await ApiService.calculateNewFreights(postData);
          let isProcessing = true;
          while (isProcessing) {
            await delay(5000);
            let { data } = await ApiService.checkOrdersProcessing();
            isProcessing = data.isProcessing;
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        // no-op
      } finally {
        this.loading = -1;
        this.success('Új megrendelés azonosítók: <br/>' + Object.values(orderIds).join(', '));
      }
    }
  },
  mounted() {}
};
</script>
