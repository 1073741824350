import { getField, updateField } from 'vuex-map-fields';
import ApiService from '../services/ApiService';
import { ActionTree, GetterTree, MutationTree, Module } from 'vuex';
import { RootState } from './';

export type Calendar = {
  id: string;
  date: string;
  workday: boolean;
};

type State = {
  calendar: Calendar[];
  isNew?: boolean;
  edited?: {};
  loaded: boolean;
};

const state: State = {
  calendar: [],
  isNew: false,
  edited: undefined,
  loaded: false
};

const actions: ActionTree<State, RootState> = {
  async refresh({ commit }) {
    const { data } = await ApiService.getCalendar();
    commit('refresh', data);
  },

  addNew({ commit }) {
    commit('addNew');
  },

  edit({ commit }, id: string) {
    commit('edit', id);
  },

  async remove({ dispatch }, id: string) {
    await ApiService.deleteCalendar(id);
    dispatch('refresh');
  },

  cancel({ commit }) {
    commit('cancel');
  },

  async save({ dispatch }) {
    await ApiService.saveCalendar(state.edited);
    dispatch('refresh');
  }
};

const mutations: MutationTree<State> = {
  refresh(state, calendar: Calendar[]) {
    state.calendar = calendar;
    state.edited = undefined;
    state.loaded = true;
  },
  addNew(state) {
    state.isNew = true;
    state.edited = {};
  },
  edit(state, id: string) {
    state.isNew = false;
    state.edited = { ...state.calendar.filter((calendar) => calendar.id === id)[0] };
  },
  cancel(state) {
    state.edited = undefined;
  },
  updateField
};

const getters: GetterTree<State, RootState> = {
  calendar: (state) => state.calendar,
  isNew: (state) => state.isNew,
  edited: (state) => state.edited,
  loaded: (state) => state.loaded,
  getField
};

export const calendar: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
