import { Addressparts } from './types';

export function normalizeAddressString(
  inputValue: string,
  addressProperties: { address: string; addressparts: Addressparts }
): string {
  /**
   * Check zip code
   * If input string starts with zip code, add zip code to result address.
   */
  const addressMatch = new RegExp('^[0-9]{4}[ ].*', 'g').test(inputValue);
  const address = addressMatch
    ? addressProperties.addressparts.zip + ' ' + addressProperties.address
    : addressProperties.address;

  return address;
}
