/**
 * On modern browsers you can use the built in Intl.NumberFormat to detect
 * the browser's number formatting and normalize the input to match.
 */
export function parseNumber(value: string | number, locales = navigator.languages): number {
  if (typeof value === 'number') {
    return value;
  }
  const example = Intl.NumberFormat(locales as string[]).format(1.1);
  const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g');
  const cleaned = value.replace(cleanPattern, '');
  const normalized = cleaned.replace(example.charAt(1), '.');

  return parseFloat(normalized);
}
