import Vue from 'vue';
import Vuex from 'vuex';

import { routePlanner } from '../../../lrpm/src/routePlanner.module';
import { account } from './account.module';
import { alert } from './alert.module';
import { app, State as AppState } from './app.module';
import { basicSettings } from './basicSettings.module';
import { calendar } from './calendar.module';
import { capacityEstimate } from './capacityEstimate.module';
import {
  commissionedUnitOrganizer,
  State as CommissionedUnitOrganizerState
} from './commissionedUnitOrganizer.module';
import { depots } from './depots.module';
import { events } from './events.module';
import { externalIdNames, State as ExternalIdNamesState } from './externalIdNames.module';
import { factories } from './factories.module';
import { fleet } from './fleet.module';
import { freight, State as FreightState } from './freight.module';
import { freightBills } from './freightBills.module';
import { freightFees } from './freightFees.module';
import { freightWaypoints } from './freightWaypoints.module';
import { module } from './module.module';
import { moduleSettings } from './moduleSettings.module';
import { orderEdit } from './orderEdit.module';
import { ordersImport } from './ordersImport.module';
import { pickups, State as PickupState } from './pickups.module';
import { products } from './product.module';
import { splitCargo, State as SplitCargoState } from './splitCargo.module';
import { timeSlotView, State as TimeSlotViewState } from './timeSlotView.module';
import { transporters } from './transporters.module';
import { users } from './users.module';
import { vehicles } from './vehicles.module';
import { freightDetails } from './freightDetails.module';

Vue.use(Vuex);

export type RootState = {
  app: AppState;
  commissionedUnitOrganizer: CommissionedUnitOrganizerState;
  freight: FreightState;
  pickups: PickupState;
  splitCargo: SplitCargoState;
  timeSlotView: TimeSlotViewState;
  externalIdNames: ExternalIdNamesState;
};

export const store: any = new Vuex.Store<RootState>({
  modules: {
    account,
    alert,
    app,
    basicSettings,
    calendar,
    capacityEstimate,
    commissionedUnitOrganizer,
    depots,
    events,
    externalIdNames,
    factories,
    fleet,
    freight,
    freightBills,
    freightDetails,
    freightFees,
    freightWaypoints,
    module,
    moduleSettings,
    orderEdit,
    ordersImport,
    products,
    pickups,
    routePlanner,
    splitCargo,
    timeSlotView,
    transporters,
    users,
    vehicles
  }
});
