import Vue from 'vue';

export default async (message, options) => {
  return new Vue().$confirm(message, {
    buttonTrueText: 'Igen',
    buttonFalseText: 'Nem',
    color: 'error',
    icon: 'warning',
    title: 'Figyelem',
    width: 450,
    ...options
  });
};
