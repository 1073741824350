/* eslint-disable no-unused-vars */

import { store } from '../../store';
import { BaseExternalIds } from './baseExternalIds';
import { PointGeometry } from './geometry';
import { Customer, OrderItem } from './order';
import { Pallet } from './pallet';
import { TimeSlotOnClient } from './timeSlot';

type WaypointRestrictions = {
  destinationTraffic: boolean;
  licenseNeeded: boolean;
  weightLimit: number;
};

export type Remain = {
  kg: number;
  pallets: number;
  cargoSpace: number[];
};

type Alternative = {
  type?: string;
  remain?: Remain;
  full?: Boolean;
};

export type CostCentre = {
  orderId: string;
  valueOfCargoMainExternalId?: string;
  description?: string;
};

export type BatchBillDto = {
  billNumber?: string;
  billDate?: Date;
};

type Bill = {
  actualCost?: number;
  billNumber?: string;
  billDate?: Date;
  reason?: string;
  costCentre?: CostCentre[];
};

export enum CommissionedUnitType {
  Freight = 'freight',
  Pickup = 'pickup'
}

export type CommissionedUnitDetails = {
  orderId: string;
  orderItems: Array<OrderItem>;
};

export type CommissionedUnit = BaseExternalIds & {
  id?: string;
  status?: string;
  statusHu?: string;
  cargo?: Array<Pallet>;
  releaseDate?: string;
  expectedDeadlineDate?: string;
  deliveryDate?: string;
  deliveryDateChangedAt?: string;
  depotShortName?: string;
  timeSlot?: TimeSlotOnClient;
  driverName?: string;
  truckLicensePlateNumber?: string;
  factoryShortNames?: Array<string>;
  __v?: number;
  orders?: {
    orderId?: string;
  }[];
  details?: CommissionedUnitDetails;
  type?: CommissionedUnitType;
};

export type FreightStatus =
  (typeof store.state.app.enums.freightStatus)[keyof typeof store.state.app.enums.freightStatus];
export type PickupStatus =
  (typeof store.state.app.enums.pickupStatus)[keyof typeof store.state.app.enums.pickupStatus];

export type Freight = CommissionedUnit & {
  status?: FreightStatus;
  full?: boolean;
  remain?: Remain;
  route?: {
    distanceMeter: number;
    durationSecond: number;
    paidPrice: number;
    waypoints?: { city?: string }[];
  };
  alternatives?: Alternative[];
  truckType?: string;
  freightId?: string;
  freightCost?: number;
  freightIncome?: number;
  transporterId?: string;
  bill?: Bill;
  offerSentToTransporterIds?: string[];
  comment?: string;
};

export type Pickup = CommissionedUnit & {
  status?: PickupStatus;
  cargo?: Array<Pallet>;
  pickupId?: string;
  pickupIdSortable?: string;
};

export enum CheckChangesType {
  freight = 'freight',
  pickup = 'pickup',
  freightBills = 'freightBills',
  timeSlot = 'timeSlot'
}

export type Waypoint = {
  address: string;
  city?: string;
  county?: string;
  customer?: Customer;
  customerName?: string;
  color?: string;
  estimatedArrival: number;
  orderId: string;
  point: PointGeometry;
  restrictions: WaypointRestrictions;
  zip?: string;
};
