import moment from 'moment-mini';

export const formatDate = 'YYYY.MM.DD';
export const formatTime = 'HH:mm';
export const formatDateTime = formatDate + ' ' + formatTime;
export const formatTimestamp = formatDate + ' ' + formatTime + ':ss';

/* eslint-disable max-len */
export const datePartialMatchingRegExp =
  /(^\d$|^\d{2}$|^\d{3}$|^\d{4}$|^\d{4}.$|^\d{4}.\d$|^\d{4}.\d{2}$|^\d{4}.\d{2}.$|^\d{4}.\d{2}.\d$|^\d{4}.\d{2}.\d{2}$)/g;

/**
 * Checks if given value can be turned into a valid date or not
 */
export function isValidDate(date: string | number | Date): boolean {
  return !isNaN(new Date(date).getTime());
}

export function isValidISODate(date: Date | string): boolean {
  // eslint-disable-next-line import/no-named-as-default-member
  return moment(date, moment.ISO_8601).isValid();
}

/**
 * Checks if given date is today or in the future
 */
export function isPresentOrFutureDate(date: string | number | Date): boolean {
  // if date is invalid no need to check if it's in the past or not
  return !isValidDate(date) || moment(new Date(date)).isSameOrAfter(moment().format('YYYY-MM-DD'));
}

export function getFormatDate(date: Date | string): string {
  if (!date) {
    return '';
  }
  return moment(date).format(formatDate);
}

export function getDisplayFormatDateTime(date: Date | string): string {
  if (!date) {
    return '';
  }
  return moment(date).format(formatDateTime);
}

export function getDisplayFormatTimestamp(date: Date | string): string {
  if (!date) {
    return '';
  }
  return moment(date).format(formatTimestamp);
}

/**
 * Compares the 2 given dates and
 * returns if they are the same (equal) timestamp or not.
 * Dates can be compared
 *   - as full timestamps or
 *   - as dates without time (default)
 */
export function isSameDate(
  date1: Date | string | undefined,
  date2: Date | string | undefined,
  onlyDate = true
): boolean {
  if (!date1 || !date2) {
    return false;
  }
  if (typeof date1 === 'string') {
    date1 = new Date(date1);
  }
  if (typeof date2 === 'string') {
    date2 = new Date(date2);
  }
  if (onlyDate) {
    date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
    date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
  }
  return new Date(date1).getTime() === new Date(date2).getTime();
}

/**
 * Returns the given date with setting the hours, minutes, seconds and milliseconds to zero
 */
export function getStartOfDay(date: Date | string): Date | undefined {
  if (!date) {
    return undefined;
  }
  return moment(date).startOf('day').toDate();
}

/**
 * Returns the given date with setting the hours, minutes, seconds and milliseconds to zero
 */
export function setTimeToZero(date?: Date | undefined | null): Date | undefined {
  if (!date) {
    return undefined;
  }
  const normalizedDateTimeString = `${date.getFullYear()}-${preZero(date.getMonth() + 1)}-${preZero(
    date.getDate()
  )}T00:00:00.000Z`;
  return new Date(normalizedDateTimeString);
}

/**
 * Returns the given date with setting the hours, minutes, seconds and milliseconds to the end of the day
 */
export function setTimeToEndOfTheDay(date?: Date | undefined | null): Date | undefined {
  if (!date) {
    return undefined;
  }
  const normalizedDateTimeString = `${date.getFullYear()}-${preZero(date.getMonth() + 1)}-${preZero(
    date.getDate()
  )}T23:59:59.999Z`;
  return new Date(normalizedDateTimeString);
}

/**
 * Adds a zero as prefix if the given number is less than 10
 */
export function preZero(num: number | string): string {
  return String(num).length === 1 ? `0${num}` : `${num}`;
}
