import { FleetVehicle, Transporter } from '../helpers/types/index';
import ApiService from '../services/ApiService';
import { ActionTree, GetterTree, MutationTree, Module } from 'vuex';
import { RootState } from '.';
import { Vehicle } from './vehicles.module';

type State = {
  fleet?: FleetVehicle[];
  edited?: FleetVehicle;
  transporters?: Transporter[];
  truckTypes?: string[];
};

const state: State = {
  fleet: [],
  edited: undefined,
  transporters: [],
  truckTypes: []
};

const actions: ActionTree<State, RootState> = {
  editFleetVehicle({ commit }, fleetVehicle: FleetVehicle): void {
    const fleetVehicleToMutate: FleetVehicle = fleetVehicle;
    //have to check if it is exists because sometimes we pass undefined fleetVehicle
    if (fleetVehicleToMutate && !fleetVehicleToMutate.contact) {
      fleetVehicleToMutate.contact = '';
    }
    commit('edit', fleetVehicleToMutate);
  },
  async saveFleetVehicle({ state }) {
    await ApiService.createFleetVehicle(state.edited);
  },
  async getFleet({ commit }, transporterId?: string) {
    const { data }: { data: FleetVehicle[] } = await ApiService.getFleet(transporterId);

    commit('setFleet', data);
  },
  async getTransporters({ commit, rootGetters }) {
    if (rootGetters['account/isTransporter']) {
      return;
    }

    const { data }: { data: Transporter[] } = await ApiService.getTransporters();

    commit('setTransporters', data);
  },
  async getTruckTypes({ commit }) {
    const { data }: { data: Vehicle[] } = await ApiService.getVehicles();

    commit(
      'setTruckTypes',
      data.map((vehicle: Vehicle) => vehicle.vehicleId)
    );
  },
  async deleteFleetVehicle({ dispatch }, fleetVehicleId: string) {
    await ApiService.deleteFleetVehicle(fleetVehicleId);

    dispatch('getFleet');
  },
  async updateFleetVehicle({ dispatch, state }) {
    await ApiService.updateFleetVehicle(state.edited);

    dispatch('getFleet');
  },
  setTransporterId({ commit }, transporter: Transporter) {
    commit('setTransporterId', transporter.id);
  },
  setTruckType({ commit }, truckType: string) {
    commit('setTruckType', truckType);
  },
  setTruckLicensePlateNumber({ commit }, truckLicensePlateNumber: string) {
    commit('setTruckLicensePlateNumber', truckLicensePlateNumber);
  },
  setContact({ commit }, contact: string) {
    commit('setContact', contact);
  }
};

const mutations: MutationTree<State> = {
  edit(state: State, fleetVehicle: FleetVehicle) {
    state.edited = fleetVehicle;
  },
  setTransporterId(state: State, transporterId: string) {
    if (!state.edited) {
      return;
    }

    state.edited.transporterId = transporterId;
  },
  setTruckType(state: State, truckType: string) {
    if (!state.edited) {
      return;
    }

    state.edited.truckType = truckType;
  },
  setTruckLicensePlateNumber(state: State, truckLicensePlateNumber: string) {
    if (!state.edited) {
      return;
    }

    state.edited.truckLicensePlateNumber = truckLicensePlateNumber;
  },
  setContact(state: State, contact: string) {
    if (!state.edited) {
      return;
    }

    state.edited.contact = contact;
  },
  setFleet(state: State, fleet: FleetVehicle[]) {
    state.fleet = fleet;
  },
  setTransporters(state: State, transporters: Transporter[]) {
    state.transporters = transporters;
  },
  setTruckTypes(state: State, truckTypes: string[]) {
    state.truckTypes = truckTypes;
  }
};

const getters: GetterTree<State, RootState> = {};

export const fleet: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
