<template>
  <gli-btn v-bind="$attrs" v-on="$listeners" v-if="canEdit">
    <template>
      <slot name="default" />
    </template>
  </gli-btn>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'GliEditorBtn',

  computed: {
    ...mapGetters('account', ['canEdit'])
  },

  methods: {}
};
</script>
