import { checkCalendar } from './rules';
import { DELIVERY_INTERVAL_TYPES } from './types';

export const calculateExpectedDeadlineDate = (
  releaseDate: Date,
  deliveryIntervalType: DELIVERY_INTERVAL_TYPES,
  deliveryInterval: number
) => {
  const newDate = new Date(releaseDate);
  if (deliveryIntervalType === DELIVERY_INTERVAL_TYPES.CALENDAR_DAY) {
    newDate.setDate(newDate.getDate() + deliveryInterval);
    while (checkCalendar(newDate) !== true) {
      newDate.setDate(newDate.getDate() - 1);
    }
  } else {
    let daysToAdd = deliveryInterval;
    while (daysToAdd !== 0) {
      do {
        newDate.setDate(newDate.getDate() + 1);
      } while (checkCalendar(newDate) !== true);
      daysToAdd -= 1;
    }
  }

  return newDate;
};
