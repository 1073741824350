<template>
  <v-app>
    <v-navigation-drawer
      color="sidepanel"
      app
      clipped
      mini-variant
      expand-on-hover
      v-model="drawer"
      v-if="(isUserLoggedIn || isAuthDisabled) && $route.name !== 'Home' && !isEntranceSupervisor"
    >
      <v-list dense>
        <v-list-group
          v-if="$can('accessFreightPlanner', 'Menus')"
          prepend-icon="mdi-table"
          group="/orders|/freights|/pickups"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Fuvartervező</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link to="/orders" class="background">
            <v-list-item-action>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Rendelések</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/freights" class="background">
            <v-list-item-action>
              <v-icon>mdi-truck-delivery-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Fuvarok</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="$can('accessPickups', 'Menus')" link to="/pickups" class="background">
            <v-list-item-action>
              <v-icon>mdi-dolly</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Gyári átvétek</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item v-if="$can('accessFreightOrganizer', 'Menus')" link to="/freight-organizer">
          <v-list-item-action>
            <v-icon>mdi-calendar</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Fuvarszervező</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can('accessFreightBills', 'Menus')" link to="/freight-bills">
          <v-list-item-action>
            <v-icon>mdi-book-open-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Fuvarszámlák</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can('accessCapacityEstimate', 'Menus')" link to="/capacity-estimate">
          <v-list-item-action>
            <v-icon>mdi-truck-flatbed</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Kapacitás előrejelzés</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can('accessTimeSlots', 'Menus')" link to="/timeslots">
          <v-list-item-action>
            <v-icon>mdi-clock-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Időkapu</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can('accessMap', 'Menus')" link to="/map">
          <v-list-item-action>
            <v-icon>mdi-map</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Térképes nézet</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can('accessRoutePlanner', 'Menus')" link to="/route-planner">
          <v-list-item-action>
            <v-icon>mdi-map-marker-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Útvonaltervező</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="$can('accessFreightStatistics', 'Menus')"
          prepend-icon="mdi-chart-line"
          group="/order-statistics|/freight-statistics"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Statisztikák</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- TODO
          <v-list-item link to="/order-statistics" class="background">
            <v-list-item-action>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Rendelések</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item link to="/freight-statistics" class="background">
            <v-list-item-action>
              <v-icon>mdi-truck</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Fuvarok</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group
          v-if="$can('accessBaseData', 'Menus')"
          prepend-icon="mdi-archive"
          group="/basic-settings|/products|/sites|/vehicles|/transporters|
          |/calendar-settings|/freight-fees"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> Törzsadatok </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link to="/basic-settings" class="background">
            <v-list-item-action>
              <v-icon>mdi-tune</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Általános beállítások</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$can('accessProducts', 'Menus')"
            link
            to="/products"
            class="background"
          >
            <v-list-item-action>
              <v-icon>mdi-view-list</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Árukatalógus</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/sites" class="background">
            <v-list-item-action>
              <v-icon>mdi-factory</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Depók/Gyárak</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/vehicles" class="background">
            <v-list-item-action>
              <v-icon>mdi-truck</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Járművek</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/transporters" class="background">
            <v-list-item-action>
              <v-icon>mdi-truck-fast</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Fuvarozók</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/calendar-settings" class="background">
            <v-list-item-action>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Naptár beállítások</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link to="/freight-fees" class="background">
            <v-list-item-action>
              <v-icon>mdi-cash-multiple</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Fuvardíjak</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="$can('accessFleet', 'Menus')" link to="/fleet" class="background">
            <v-list-item-action>
              <v-icon>mdi-garage-open-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Flotta</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-if="$can('accessEvents', 'Menus')" link to="/events">
          <v-list-item-action>
            <v-icon>mdi-text-box-search-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Eseménynapló</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$can('accessUserList', 'Menus')" link to="/userlist">
          <v-list-item-action>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Felhasználók</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$can('accessModuleSettings', 'Menus')" link to="/module-settings">
          <v-list-item-action>
            <v-icon>mdi-toy-brick</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Modul beállítások</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$can('accessBackToHomepage', 'Menus')" link to="/">
          <v-list-item-action>
            <v-icon>mdi-select-group</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Vissza a nyitólapra</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="testOrdersJson && $can('accessTestOrders', 'Menus')"
          @click.stop="showTestOrderDialog = true"
        >
          <v-list-item-action>
            <v-icon color="accent"> mdi-projector-screen</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="accent--text">Teszt megrendelések</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Menu items only for transporters -->
        <v-list-item v-if="$can('accessOffers', 'Menus')" link to="/offers">
          <v-list-item-action>
            <v-icon>mdi-offer</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ freightOffersMenuText }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('accessTransporterFreights', 'Menus')"
          link
          to="/freights"
          class="background"
        >
          <v-list-item-action>
            <v-icon>mdi-truck-delivery-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Fuvarok</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="$can('accessTransporterCapacityEstimate', 'Menus')"
          link
          to="/capacity-estimate"
        >
          <v-list-item-action>
            <v-icon>mdi-truck-flatbed</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Kapacitás előrejelzés</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can('accessTransporterFleet', 'Menus')" link to="/fleet">
          <v-list-item-action>
            <v-icon>mdi-garage-open-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Flotta</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$can('accessTransporterEvents', 'Menus')" link to="/events">
          <v-list-item-action>
            <v-icon>mdi-text-box-search-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Eseménynapló</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <DemoScenariosDialog v-model="showTestOrderDialog"></DemoScenariosDialog>

    <v-app-bar app clipped-left color="primary" dark>
      <v-app-bar-nav-icon
        v-if="(isUserLoggedIn || isAuthDisabled) && $route.name !== 'Home' && !isEntranceSupervisor"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <img width="45" v-if="viewConfig.logo" v-bind:src="imagesBasePath + viewConfig.logo" />
      <img
        width="110"
        class="mr-3"
        v-if="viewConfig.largeLogo"
        v-bind:src="imagesBasePath + viewConfig.largeLogo"
      />
      <v-toolbar-title class="ml-4"> {{ viewConfig.title }}</v-toolbar-title>
      <v-spacer />
      <v-menu offset-y v-if="isUserLoggedIn && !isAuthDisabled">
        <template v-slot:activator="{ on }">
          <gli-btn dark icon v-on="on">
            <v-icon v-if="$can('seeAdminLogo', 'Menus')">mdi-account-star</v-icon>
            <v-icon v-else>account_circle</v-icon>
          </gli-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>{{ displayName }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Kilépés</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer app dark color="primary">
      <manual-combine-result-card
        v-if="showManualCombineResultCard && $can('seeManualCombineResults', 'Menus')"
      ></manual-combine-result-card>
      <span
        v-if="ordersProcessing.hasUserActiveProcessingJob && $can('seeOrdersProcessing', 'Menus')"
        class="d-flex align-center"
      >
        <span v-if="cancelOrdersProcessing">Tervezés megszakítása folyamatban...</span>
        <span v-else>
          {{
            ordersProcessing.jobsInFrontOfThisOne
              ? `Tervezési feladat várakozik, még ${ordersProcessing.jobsInFrontOfThisOne}` +
                ` másik feladat van folyamatban...`
              : 'Tervezés folyamatban...'
          }}
        </span>
        <v-progress-circular
          class="ml-2"
          indeterminate
          :size="22"
          :color="cancelOrdersProcessing ? 'error' : 'info'"
        ></v-progress-circular>
      </span>
      <v-spacer />
      <v-tooltip
        top
        v-if="
          (ordersProcessing.newOrdersCount || ordersProcessing.newPartialOrdersCount) &&
          $can('seeNewOrders', 'Menus')
        "
      >
        <template v-slot:activator="{ on }">
          <span v-on="on" class="mr-8">
            <v-icon :color="ordersProcessing.newOrdersCount ? 'error' : 'warning'"
              >mdi-alert</v-icon
            >
            {{
              ordersProcessing.newOrdersCount
                ? ordersProcessing.newOrdersCount + ' új megrendelés'
                : ordersProcessing.newPartialOrdersCount + ' új törtfuvar'
            }}
          </span>
        </template>
        <!--// TODO WB kiadható -->
        <span v-show="ordersProcessing.newOrdersCount"
          >{{ ordersProcessing.newOrdersCount }} új megrendelés várakozik feldolgozásra.<br />
          Állítsa be a rendeléseken a visszaigazolt dátumot és engedélyezze az automatikus
          <br />feldolgozást vagy küldje be a megfelelő rendeléseket tervezésre.</span
        >
        <span v-show="!ordersProcessing.newOrdersCount"
          >{{ ordersProcessing.newPartialOrdersCount }} új törtfuvar keletkezett.<br />
          Engedélyezze az automatikus feldolgozást az automatikus fuvarkombináláshoz vagy <br />
          (állítson össze rendeléseket kombinálásra, majd) rögzítse a megfelelő fuvarokat.</span
        >
      </v-tooltip>
      <v-tooltip
        top
        v-if="
          ordersProcessing.lastSmsResultCode !== smsOkResponseCode && $can('seeSmsError', 'Menus')
        "
      >
        <template v-slot:activator="{ on }">
          <span v-on="on" class="mr-8">
            <v-icon color="error">mdi-alert</v-icon>
            {{
              smsNotEnoughCreditResponseCodes.includes(ordersProcessing.lastSmsResultCode)
                ? 'SeeMe sms küldő egyenlege túl alacsony'
                : 'Sms küldési hiba.'
            }}
          </span>
        </template>
        <span v-show="smsNotEnoughCreditResponseCodes.includes(ordersProcessing.lastSmsResultCode)">
          SeeMe előfizetéséhez tartozó egyenleg túl alacsony.<br />
          A további sms-ek kiküldéséhez töltse fel egyenlegét!</span
        >
        <span
          v-show="!smsNotEnoughCreditResponseCodes.includes(ordersProcessing.lastSmsResultCode)"
        >
          Sms küldés közben ismeretlen hiba történt.<br />
          hibakód: {{ ordersProcessing.lastSmsResultCode }}</span
        >
      </v-tooltip>
      <span class="subtitle-2">{{
        version ? `Alkalmazás verzió: ${version.buildVersion}` : ''
      }}</span>
    </v-footer>
  </v-app>
</template>

<style lang="sass">
@import "./App.scss"
</style>

<style scoped>
.card {
  position: absolute;
  left: 64px;
  width: 430px;
  height: auto;
  bottom: 40px;
}
a.v-list-item {
  padding-right: 0 !important;
}
</style>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import DemoScenariosDialog from './components/DemoScenariosDialog';
import ManualCombineResultCard from './components/ManualCombineResultCard';
import config from './helpers/configProvider';
import { defineRulesFor } from './services/ability';
import { store } from './store';

export default {
  name: 'app',

  data() {
    return {
      drawer: null,
      env: process.env.NODE_ENV,
      showTestOrderDialog: false,
      checkProcessing: undefined,
      smsOkResponseCode: 0,
      smsNotEnoughCreditResponseCodes: [7, 11],
      imagesBasePath:
        (config.getEnv(config.enum.VUE_APP_API_SERVICE_URL) || `http://localhost:8081`) + '/images/'
    };
  },

  async beforeDestroy() {
    if (this.checkProcessing) {
      this.checkProcessing = clearInterval(this.checkProcessing);
    }
  },

  created() {
    this.$ability.update(
      defineRulesFor(store.getters['account/user'] ? store.getters['account/role'] : 'anyone')
    );
  },

  async mounted() {
    if (this.isAuthDisabled || this.isUserLoggedIn) {
      this.fetchVersion();
    }
    document.title = this.viewConfig.title;
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = this.imagesBasePath + this.viewConfig.favicon;

    this.checkProcessing = setInterval(() => {
      if ((this.isAuthDisabled || this.isUserLoggedIn) && !this.isTransporter) {
        try {
          this.fetchOrdersProcessing();
        } catch {
          // no-op
        }
      }
    }, 5000);
  },

  computed: {
    ...mapState({
      alert: (state) => state.alert,
      version: (state) => state.app.version,
      isAuthDisabled: (state) => state.account.authDisabled
    }),
    ...mapGetters('account', ['isUserLoggedIn', 'isTransporter', 'displayName']),
    ...mapGetters('app', [
      'viewConfig',
      'ordersProcessing',
      'cancelOrdersProcessing',
      'testOrdersJson',
      'lastProcessingFreightIds',
      'showManualCombineResultCard'
    ]),
    ...mapGetters('account', ['isEntranceSupervisor']),
    ...mapGetters('module', ['isTransporterTenderActive']),
    freightOffersMenuText() {
      return this.isTransporterTenderActive ? 'Ajánlatok' : 'Elfogadásra váró megbízások';
    }
  },

  methods: {
    ...mapActions('app', ['fetchVersion', 'fetchOrdersProcessing']),
    ...mapActions({
      clearAlert: 'alert/clear',
      logout: 'account/logout'
    })
  },
  watch: {
    alert: {
      handler(val) {
        if (val.message) {
          this.$swal({
            title: val.message.toString(),
            confirmButtonColor: this.viewConfig.theme.primary
          });
          this.clearAlert();
        }
      },
      deep: true
    },
    isUserLoggedIn: {
      async handler(val) {
        if (val) {
          await this.fetchVersion();
        }
      }
    }
  },
  components: {
    DemoScenariosDialog,
    ManualCombineResultCard
  }
};
</script>
