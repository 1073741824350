import ApiService from '../services/ApiService';
import { ActionTree, MutationTree, GetterTree } from 'vuex';
import { RootState } from '.';
import { BatchBillDto, CheckChangesType, CostCentre, Freight } from '../helpers/types/index';
import { Order } from '../helpers/types/order';
import enums from '../../../shared/utils/enums';

type State = {
  freights: (Freight & { isSelectable?: boolean })[];
  orders: Order[];
  freightsFilteredCount?: number;
  freightBillStatuses: string[];
  lastRefresh: {
    date?: Date;
    count?: number;
    changedSince: boolean;
  };
  editedCostCentre?: {
    freightId: string;
    costCentre: CostCentre;
  };
  costCentreDescriptions: string[];
  batchBill?: BatchBillDto;
};

const state: State = {
  freights: [],
  orders: [],
  freightsFilteredCount: undefined,
  freightBillStatuses: [],
  lastRefresh: {
    date: undefined,
    count: undefined,
    changedSince: false
  },
  editedCostCentre: undefined,
  costCentreDescriptions: [],
  batchBill: undefined
};

const actions: ActionTree<State, RootState> = {
  async getData({ dispatch }, { pagination, filter }: { pagination: object; filter: object }) {
    await dispatch('getFreights', { pagination, filter });

    //collect orderIds of freights
    const orderIds: string[] = [];
    for (const freight of state.freights) {
      if (freight.cargo) {
        for (const cargoItem of freight.cargo) {
          const orderId = (cargoItem as any).orderId;
          if (orderId && !orderIds.includes(orderId)) {
            orderIds.push(orderId);
          }
        }
      }
    }

    await dispatch('getOrders', orderIds);
  },

  async getFreights(
    { commit },
    { pagination, filter }: { pagination: object; filter: Record<string, any> }
  ) {
    //FreightBills.vue should only display freights with status: billed

    const { data } = await ApiService.getFreights({
      pagination,
      filter,
      statuses: filter.status
    });

    commit('setFreightsFilteredCount', data.filteredCount);
    data.result.forEach((freight: Freight & { isSelectable?: boolean }) => {
      freight.isSelectable = freight.status === enums.freightStatus.billed;
    });
    commit('setFreights', data.result);
    commit('setLastRefresh', { date: new Date(), count: data.totalCount, changedSince: false });
  },

  async setFreightBillStatuses({ commit }) {
    const { data }: { data: Array<string> } = await ApiService.getFreightBillStatuses();

    commit('setFreightBillStatuses', data);
  },

  async getOrders({ commit }, orderIds: string[]) {
    const { data } = await ApiService.getOrdersByOrderIds({ orderIds });

    commit('setOrders', data);
  },

  async checkChanges({ commit }, params) {
    const { data } = await ApiService.checkFreightChanges(CheckChangesType.freightBills, {
      date: params.date,
      count: params.count,
      statuses: params.statuses
    });

    commit('setHasChanges', data.hasChanges);
  },
  // eslint-disable-next-line no-empty-pattern
  async acceptBill({}, freightId: string) {
    await ApiService.acceptBill(freightId);
  },

  // eslint-disable-next-line no-empty-pattern
  async declineBill({}, freightId: string) {
    await ApiService.declineBill(freightId);
  },

  // eslint-disable-next-line no-empty-pattern
  async acceptMultipleBills({}, freightIds: string[]) {
    await ApiService.acceptMultipleBills(freightIds);
  },

  // eslint-disable-next-line no-empty-pattern
  async declineMultipleBills({}, freightIds: string[]) {
    await ApiService.declineMultipleBills(freightIds);
  },

  editFreightCostCentre({ commit }, { freightId, costCentre }) {
    commit('setEditedCostCentre', { freightId, costCentre });
  },

  async setCostCentre({ state }) {
    await ApiService.setCostCentre(
      state.editedCostCentre?.freightId,
      state.editedCostCentre?.costCentre
    );
  },

  unsetEditedCostCentre({ commit }) {
    commit('unsetEditedCostCentre');
  },

  async fetchCostCentreDescriptions({ commit, rootState }) {
    const { data } = await ApiService.getCostCentreDescriptions(
      rootState.app.enums.freightStatus.finished
    );
    commit('setCostCentreDescriptions', data);
  },

  async saveBatchBill({ commit, state }, { freightIds }: { freightIds: string[] }) {
    await ApiService.saveBatchBill(state.batchBill, freightIds);
    commit('setBatchBill', undefined);
  },

  setBatchBill({ commit }, batchBill: BatchBillDto | undefined) {
    commit('setBatchBill', batchBill);
  }
};

const mutations: MutationTree<State> = {
  setFreightBillStatuses(state, freightBillStatuses: Array<string>) {
    state.freightBillStatuses = freightBillStatuses;
  },

  setFreights(state, freights: Freight[]) {
    state.freights = freights;
  },

  setOrders(state, orders: Order[]) {
    state.orders = orders;
  },

  setLastRefresh(
    state,
    lastRefresh: {
      date?: Date;
      count?: number;
      changedSince: boolean;
    }
  ) {
    state.lastRefresh = lastRefresh;
  },

  setHasChanges(state, hasChanges: boolean) {
    state.lastRefresh.changedSince = hasChanges;
  },

  setFreightsFilteredCount(state, freightsFilteredCount: number) {
    state.freightsFilteredCount = freightsFilteredCount;
  },

  setEditedCostCentre(state, { freightId, costCentre }) {
    state.editedCostCentre = { freightId, costCentre };
  },

  unsetEditedCostCentre(state) {
    state.editedCostCentre = undefined;
  },

  setCostCentreDescriptions(state, costCentreDescriptions: string[]) {
    state.costCentreDescriptions = costCentreDescriptions;
  },

  setBatchBill(state, batchBill) {
    state.batchBill = batchBill;
  }
};

const getters: GetterTree<State, RootState> = {
  freights: (state) => state.freights,
  orders: (state) => state.orders,
  freightsFilteredCount: (state) => state.freightsFilteredCount,
  lastRefresh: (state) => state.lastRefresh,
  costCentreDescriptions: (state) => state.costCentreDescriptions
};

export const freightBills = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
