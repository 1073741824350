import { ActionTree, GetterTree, MutationTree } from 'vuex';

import ApiService from '../services/ApiService';
import { RootState } from './';

export interface Transporter {
  id: string;
  status: string;
  companyName: string;
  contactPersonName: string;
  contactPersonPhone: string;
  contactPersonEmail: string;
  availableVehicles: string[];
  shortName?: string;
}

type State = {
  transporters: Transporter[];
};

const state: State = {
  transporters: []
};

const actions: ActionTree<State, RootState> = {
  async getAll({ commit }) {
    const { data } = await ApiService.getTransporters();
    commit('getAll', data);
  }
};

const mutations: MutationTree<State> = {
  getAll(state, transporters: Transporter[]) {
    state.transporters = transporters;
  }
};

const getters: GetterTree<State, RootState> = {
  transporters: (state) => state.transporters,
  transportersCompanyNames: (state) =>
    state.transporters.map((transporter) => transporter.companyName),
  transportersCompanyNamesWithShortNames: (state) => {
    return state.transporters.map((transporter) => {
      return {
        text: `${transporter.companyName} (${transporter.shortName})`,
        value: transporter.shortName
      };
    });
  },
  getTransporterCompanyNameById: (state) => (id: string) =>
    state.transporters.find((transporter) => transporter.id === id)?.companyName
};

export const transporters = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
