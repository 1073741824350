import { ActionTree, GetterTree, MutationTree } from 'vuex';

import ApiService from '../services/ApiService';
import { RootState } from './';

type Factory = {
  id?: string;
  shortName?: string;
  name?: string;
  address?: string;
  useDefaultFreightCostsSettings?: boolean;
};

type State = {
  factories?: Factory[];
  isNew?: boolean;
  edited?: Factory;
};

const state: State = {
  factories: undefined,
  isNew: undefined,
  edited: undefined
};

const actionTypes = {
  EDIT: 'edit'
};

const actions: ActionTree<State, RootState> = {
  async get({ commit }) {
    const { data } = await ApiService.getFactories();
    data.forEach((factory: any) => {
      if (factory.depot.shortName) {
        factory.depotShortName = factory.depot.shortName;
        delete factory['depot'];
      }
    });
    commit('refresh', data);
  },

  addNew({ commit }) {
    commit('addNewFactory');
  },
  cancel({ commit }) {
    commit('cancel');
  },
  async save({ commit, dispatch }) {
    await ApiService.saveFactory(state.edited);
    dispatch('get');
    commit('cancel');
  },
  edit({ commit }, id) {
    commit('setEdited', id);
  },
  selectAction({ dispatch }, { actionType, item }) {
    switch (actionType) {
      case actionTypes.EDIT: {
        dispatch('edit', item.id);
        break;
      }
    }
  },
  updateAddress({ commit }, value) {
    if (value) {
      commit('setEditedAddress', value);
    }
  }
};

const mutations: MutationTree<State> = {
  refresh(state, payload) {
    state.factories = payload;
  },
  addNewFactory(state) {
    state.isNew = true;
    state.edited = { useDefaultFreightCostsSettings: true };
  },
  cancel(state) {
    state.isNew = undefined;
    state.edited = undefined;
  },
  setEdited(state, id) {
    state.isNew = false;
    state.edited = { ...state.factories?.filter((factory) => factory.id === id)[0] };
  },
  setEditedAddress(state, payload) {
    if (state.edited) {
      state.edited.address = payload.address;
    }
  }
};

const getters: GetterTree<State, RootState> = {
  factories: (state) => state.factories || [],
  isNew: (state) => state.isNew,
  edited: (state) => state.edited,
  factoryShortNames: (state) => state.factories?.map((factory) => factory.shortName)
};

export const factories = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
