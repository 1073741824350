import { modulus } from '../../../shared/utils/calculate';

/**
 * This function is designed to round a numeric value according to a specified "magic limit."
 * It takes two parameters: num representing the number to be rounded
 * (which can be number, undefined, or null),
 * and limit representing the magic limit for the rounding operation.
 * If limit is not provided, the function returns the original value of num without rounding.
 * When the partial part of num is less than limit and the num is higher than 1,
 * the function rounds down using Math.floor.
 * In all other cases, the function rounds up using Math.ceil.
 */
export function roundByMagicLimit(num: number | undefined | null, limit: number): number {
  if (!num) {
    return 0;
  } else if (!limit) {
    return num;
  } else if (modulus(num, 1) < limit && num > 1) {
    return Math.floor(num);
  } else {
    return Math.ceil(num);
  }
}
