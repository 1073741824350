import Vue from 'vue';
import Router from 'vue-router';

import config from '../helpers/configProvider';
import { buildAbilityFor, defineRulesFor } from '../services/ability';
import { store } from '../store';

import { RoutingMethod } from '../../../lrpm/src/types';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  base: config.getEnv(config.enum.VUE_APP_BASE_PATH) || '/',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home')
    },
    {
      path: '/products',
      name: 'Products',
      component: () => import('@/views/Products')
    },
    {
      path: '/sites',
      name: 'Sites',
      component: () => import('@/views/Sites')
    },
    {
      path: '/vehicles',
      name: 'Vehicles',
      component: () => import('@/views/Vehicles')
    },
    {
      path: '/transporters',
      name: 'Transporters',
      component: () => import('@/views/Transporters')
    },
    {
      path: '/basic-settings',
      name: 'basicSettings',
      component: () => import('@/views/BasicSettings')
    },
    {
      path: '/calendar-settings',
      name: 'calendarSettings',
      component: () => import('@/views/CalendarSettings')
    },
    {
      path: '/freight-fees',
      name: 'freightFees',
      component: () => import('@/views/FreightFees')
    },
    {
      path: '/fleet',
      name: 'fleet',
      component: () => import('@/views/Fleet')
    },
    {
      path: '/offers',
      name: 'Offers',
      component: () => import('@/modules/freight-offer/views/Offers')
    },
    {
      path: '/orders',
      name: 'Orders',
      component: () => import('@/views/Orders')
    },
    {
      path: '/freights',
      name: 'Freights',
      component: () => import('@/views/Freights')
    },
    {
      path: '/freight-organizer',
      name: 'freightOrganizer',
      component: () => import('@/views/FreightOrganizer')
    },
    {
      path: '/freight-bills',
      name: 'freightBills',
      component: () => import('@/views/FreightBills')
    },
    {
      path: '/timeslots',
      name: 'timeslots',
      component: () => import('@/views/TimeSlots')
    },
    {
      path: '/freight-statistics',
      name: 'Freight statistics',
      component: () => import('@/modules/statistics/views/FreightStatistics')
    },
    {
      path: '/map',
      name: 'Map view',
      component: () => import('@/views/MapView')
    },
    {
      path: '/route-planner',
      name: 'Route planner',
      props: {
        methodsToHide: [RoutingMethod.DIRECT, RoutingMethod.PRIORITY],
        getTruckEuroCategoryTypes: () => store.state.app.enums.truckEuroCategoryTypes,
        getDepots: () => store.getters['depots/depots'],
        getIsSaveWaypointRestrictionsActive: () =>
          store.getters['module/isSaveWaypointRestrictionsActive'],
        getIsHandleWeightRestrictionZonesActive: () =>
          store.getters['module/isHandleWeightRestrictedZonesActive'],
        getColors: () => store.getters['app/colors']
      },
      component: () => import('../../../lrpm/src/RoutePlanner')
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login')
    },
    {
      path: '/userlist',
      name: 'Users',
      component: () => import('@/views/Users')
    },
    {
      path: '/module-settings',
      name: 'ModuleSettings',
      component: () => import('@/views/ModuleSettings')
    },
    {
      path: '/events',
      name: 'Events',
      component: () => import('@/views/Events')
    },
    {
      path: '/pickups',
      name: 'Pickups',
      component: () => import('@/views/Pickups')
    },
    {
      path: '/capacity-estimate',
      name: 'Capacity estimate',
      component: () => import('@/views/CapacityEstimate')
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const toPath = to.path.toLocaleLowerCase();
  if (store.state.account.authDisabled) {
    // redirect to root if auth is disabled and trying to access auth related pages
    const authPages = ['/users', '/login'];
    if (authPages.includes(toPath)) {
      return next('/');
    }

    next();
  } else {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(toPath);
    const loggedIn = window.$cookies.get('user');

    // if logged in then can not navigate to login page
    if (toPath === '/login' && loggedIn) {
      return next('/');
    }

    if (authRequired && !loggedIn) {
      return next('/login');
    }

    const offersPages = ['/offers'];
    const fleetPages = ['/fleet'];
    const capacityEstimatePages = ['/capacity-estimate'];
    const productPages = ['/products'];
    const transporterPages = [
      ...offersPages,
      '/freights',
      '/events',
      '/timeslots',
      ...fleetPages,
      ...capacityEstimatePages
    ];
    const productionPages = [
      '/',
      '/orders',
      '/freights',
      '/pickups',
      '/freight-organizer',
      '/map',
      '/events',
      '/timeslots',
      '/route-planner'
    ];

    const entranceSuperVisorPages = ['/timeslots'];

    const ability = buildAbilityFor();

    ability.update(
      defineRulesFor(store.getters['account/user'] ? store.getters['account/role'] : 'anyone')
    );

    if (productPages.includes(toPath) && ability.cannot('accessProductPages', 'Routes')) {
      return next('/');
    }

    if (fleetPages.includes(toPath) && ability.cannot('accessFleetPages', 'Routes')) {
      return next('/');
    }

    if (offersPages.includes(toPath) && ability.cannot('accessOfferPages', 'Routes')) {
      return next('/');
    }

    if (!transporterPages.includes(toPath) && ability.can('accessTransporterPages', 'Routes')) {
      return next('/freights');
    }

    if (
      store.getters['module/isStatisticsModuleActive'] &&
      store.getters['module/canProductionUserAccessStatistics']
    ) {
      productionPages.push('/freight-statistics');
    }
    if (!productionPages.includes(toPath) && ability.can('accessProductionPages', 'Routes')) {
      return next('/');
    }

    //entrance supervisors can only access /timeslots
    if (
      !entranceSuperVisorPages.includes(toPath) &&
      ability.can('accessEntranceSupervisorPages', 'Routes')
    ) {
      return next('/timeslots');
    }

    // only superadmin can route to /modules path
    const superAdminPages = ['/module-settings'];
    if (superAdminPages.includes(toPath) && ability.cannot('accessSuperAdminPages', 'Routes')) {
      return next('/');
    }

    // only admins & superadmins can route to /userlist path
    const adminPages = ['/userlist'];
    if (adminPages.includes(toPath) && ability.cannot('accessAdminPages', 'Routes')) {
      return next('/');
    }

    // route only to active modules
    const statisticsModulePages = ['/freight-statistics'];
    if (
      statisticsModulePages.includes(toPath) &&
      !store.getters['module/isStatisticsModuleActive']
    ) {
      return next('/');
    }

    const routePlannerModulePages = ['/route-planner'];
    if (routePlannerModulePages.includes(toPath)) {
      if (!store.getters['module/isRoutePlannerModuleActive']) {
        return next('/');
      } else {
        await store.dispatch('depots/get');
      }
    }

    const billingModulePages = ['/freight-bills'];
    if (billingModulePages.includes(toPath) && ability.cannot('accessFreightBills', 'Routes')) {
      return next('/');
    }

    if (
      capacityEstimatePages.includes(toPath) &&
      ability.cannot('accessCapacityEstimate', 'Routes')
    ) {
      return next('/');
    }

    const pickupModulePages = ['/pickups'];
    if (pickupModulePages.includes(toPath) && ability.cannot('accessPickups', 'Routes')) {
      return next('/');
    }

    // confirm popup: unsaved changes in ExternalIdNames.vue
    if (process.env.NODE_ENV !== 'development' && store.getters['externalIdNames/dirty']) {
      if (
        confirm(
          'Mentetlen külső azonosító módosítások! Biztosan el szeretne navigálni az oldalról?'
        )
      ) {
        next();
        store.dispatch('externalIdNames/loadExternalIds');
      } else {
        return next(false);
      }
    }

    //hide manual combine result card when routing
    store.dispatch('app/hideManualCombineResultCard');

    next();
  }
});

router.onError((error) => {
  // eslint-disable-next-line no-console
  console.error(error);
  if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
});
